import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Alert, Upload, message, Card, Spin, Popconfirm, Empty } from 'antd';
import { getPhotos, uploadPhoto, setCover, deletePhoto } from '../../services/photos';
import Preview from '../../component/preview/preview';
import constant from '../../common/constant';
import { optimizeImage } from '../../common/utils';
import './photos.less';

export default class Photos extends React.Component {
  state = {
    photos: [],
    previewId: null,
    loading: false,
  };
  async componentDidMount() {
    this.setState({ photos: await getPhotos() });
  }
  // 设置封面
  async setToCover(photo) {
    await setCover(photo.id);
    message.success('设置封面成功');
    this.setState({ photos: await getPhotos() });
  }
  // 删除照片
  async delete(photo) {
    await deletePhoto(photo.id);
    message.success('已删除');
    this.setState({ photos: await getPhotos() });
  }
  // 处理上传进度
  handleChange(info) {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      this.setState({ loading: false });
    }
  }
  render() {
    const props = {
      accept: constant.imgAccept,
      name: 'file',
      multiple: true,
      showUploadList: false,
      onChange: this.handleChange.bind(this),
      customRequest: options => {
        uploadPhoto(options.file)
          .then(
            async () => {
              message.success('上传成功');
              this.setState({ photos: await getPhotos() });
            },
            () => {
              message.error('上传失败');
            }
          )
          .finally(() => {
            this.setState({ loading: false });
          });
      },
    };
    const { photos, previewId, loading } = this.state;
    return (
      <div className="photos-container">
        <Alert
          message="照片优先选取海外学习、生活、旅行以及和学生互动等非职业照片，请保证照片质量清晰。"
          type="info"
          showIcon
        />
        <div className="upload-box">
          <Spin spinning={loading}>
            <Upload.Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">拖放文件到此处或者点击上传</p>
              <p className="ant-upload-hint">请选择图片文件</p>
            </Upload.Dragger>
          </Spin>
        </div>
        <section className="photos-box-container">
          {photos.map(photo => (
            <Card
              key={`2_${photo.id}`}
              className="photo-item"
              actions={[
                <div key="1" className="action-btn" onClick={this.setToCover.bind(this, photo)}>
                  设为封面
                </div>,
                <Popconfirm
                  key="2"
                  title="你确定要删除这张图片吗?"
                  onConfirm={this.delete.bind(this, photo)}
                  okText="删除"
                  cancelText="取消"
                >
                  <div className="action-btn">删除</div>
                </Popconfirm>,
              ]}
            >
              <Preview
                key={`1_${photo.id}`}
                src={photo.imageUrl}
                onHide={() => {
                  this.setState({ previewId: null });
                }}
                show={photo.id === previewId}
              />
              <div
                className="image-cover"
                style={{ backgroundImage: `url(${optimizeImage(photo.imageUrl, 200, 200)})` }}
                onClick={() => this.setState({ previewId: photo.id })}
              >
                {photo.isCover ? <div className="cover-mark">封面</div> : null}
              </div>
            </Card>
          ))}
          {!photos || photos.length === 0 ? <Empty description="暂无个人形象照片" /> : null}
        </section>
      </div>
    );
  }
}
