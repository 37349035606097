import React from 'react';

export default ({ content }) => {
  return (
    <>
      <link rel="stylesheet" href="/tinymce/content.css" />
      <div className="article-content" dangerouslySetInnerHTML={{ __html: content }} />
    </>
  );
};
