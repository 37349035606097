import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { createDropdown, addListToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import Model from '@ckeditor/ckeditor5-ui/src/model';
// import { Base64 } from 'js-base64';
// import XxdObjectSelect from './xxd-object-select';
import { xxdObjectType } from './util';
import icon from './theme/icons/xxd-object-icon.svg';

const types = xxdObjectType.map(item => item.model);

export default class XxdObjectUI extends Plugin {
  init() {
    const editor = this.editor;
    const t = editor.t;
    editor.ui.componentFactory.add('xxdObject', locale => {
      const command = editor.commands.get('insertXxdObject');
      const options = this._getLocalizedOptions();
      const dropdownView = createDropdown(locale);
      addListToDropdown(dropdownView, _prepareListOptions(options, command));

      dropdownView.buttonView.set({
        label: t('插入选校帝对象'),
        icon,
        tooltip: true,
      });
      dropdownView.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');
      // this.listenTo(dropdownView, 'execute', evt => {
      //   const type = evt.source.commandParam;
      //   XxdObjectSelect({ type }).then(res => {
      //     editor.execute(evt.source.commandName, { id: res.id, type, data: Base64.encode(JSON.stringify(res.data)) });
      //   });
      // });

      return dropdownView;
    });
  }

  _getLocalizedOptions() {
    const editor = this.editor;
    const t = editor.t;

    const localizedTitles = {};

    xxdObjectType.forEach(item => {
      if (!localizedTitles[item.title]) {
        localizedTitles[item.title] = t(item.title);
      }
    });

    const options = normalizeOptions(types);
    return options.map(option => {
      const title = localizedTitles[option.title];

      if (title && title !== option.title) {
        option = { ...option, title };
      }

      return option;
    });
  }
}

function _prepareListOptions(options, command) {
  const itemDefinitions = new Collection();

  for (const option of options) {
    const def = {
      type: 'button',
      model: new Model({
        commandName: 'insertXxdObject',
        commandParam: option.model,
        label: option.title,
        withText: true,
      }),
    };

    def.model.bind('isOn').to(command, 'value', value => value === option.model);
    itemDefinitions.add(def);
  }

  return itemDefinitions;
}

function normalizeOptions(configuredOptions) {
  return configuredOptions.map(getOptionDefinition).filter(option => !!option);
}

const namedPresets = {};

xxdObjectType.forEach(item => {
  if (!namedPresets[item.model])
    namedPresets[item.model] = {
      ...item,
      view: {
        name: 'span',
      },
    };
});

function getOptionDefinition(option) {
  if (namedPresets[option]) {
    return namedPresets[option];
  }
}
