// Polyfill Promise
if (!window.Promise || !window.Promise.finally) {
  window.Promise = require('promise-polyfill').default;
}

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

// Support for...of (a commonly used syntax feature that requires Symbols)
// require('core-js/es6/symbol');
// Support iterable spread (...Set, ...Map)
// require('core-js/fn/array/from');

// It will automatically create `Object.values` and `Object.entries`
// — but only if they're not already defined.
require('es7-object-polyfill');

// It will automatically create `String.prototype.startsWith`
// — but only if they're not already defined.
if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(searchString, position) {
    position = position || 0;
    return this.substr(position, searchString.length) === searchString;
  };
}

// canvas-toBlob.js implements the standard HTML5 canvas.toBlob()
// and canvas.toBlobHD() methods in browsers that do not natively support it.
// canvas-toBlob.js requires Blob support to function, which is not present
// in all browsers. Blob.js is a cross-browser Blob implementation
// that solves this.
if (!window.Blob) {
  require('./polyfills/Blob');
}
if (!('toBlob' in HTMLCanvasElement.prototype)) {
  require('./polyfills/canvas-toBlob');
}
