import React from 'react';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Preview from '../preview/preview';
import { getImgBase64 } from '../../common/utils';
import './form-upload.less';

export default class FormUpload extends React.Component {
  state = { previewList: [], previewUrl: null };
  constructor(props) {
    super(props);
    this.formInput = React.createRef();
    this.fileBase64Map = new Map();
  }
  static getDerivedStateFromProps(props, state) {
    if (
      props.value &&
      ((Array.isArray(props.value) && props.value.filter(Boolean).length > 0) || !Array.isArray(props.value)) &&
      state.previewList.length === 0
    ) {
      return {
        ...state,
        previewList: Array.isArray(props.value) ? props.value : [props.value],
      };
    }
    if (!props.value) {
      return {
        ...state,
        previewList: [],
      };
    }
    return state;
  }
  // 点击上传文件
  handleUploadFile() {
    this.formInput.current.click();
  }
  // 选择文件后获取文件
  async handleInputChange(e) {
    const { onChange = _ => _ } = this.props; // for form field
    const { previewList } = this.state;
    const file = (e.target.files || [])[0];
    e.target.value = null;
    if (file) {
      const url = await getImgBase64(file);
      this.fileBase64Map.set(file.lastModified, url);
      const newPreviewList = previewList.concat([file]);
      this.setState({
        previewList: newPreviewList,
      });
      onChange(newPreviewList);
    }
  }
  // 预览图片
  previewImg(url, e) {
    e.stopPropagation();
    this.setState({ previewUrl: url });
  }
  // 删除预览图片
  deletePreviewFile(index, e) {
    e.stopPropagation();
    const { onChange = _ => _ } = this.props; // for form field
    const previewList = [...this.state.previewList];
    previewList.splice(index, 1);
    this.setState({ previewList });
    onChange(previewList);
  }
  render() {
    const {
      uploadButton, // 自定义上传按钮
      maxCount, // 最大文件数量
      disabled,
      value,
      onChange,
      ...rest
    } = this.props;
    const { previewList, previewUrl } = this.state;
    return (
      <div className="form-upload-component">
        <Preview
          src={previewUrl}
          show={previewUrl}
          onHide={() => {
            this.setState({ previewUrl: null });
          }}
        />
        {previewList.filter(Boolean).map((img, index) => {
          let imgUrl = img;
          if (img instanceof File) {
            imgUrl = this.fileBase64Map.get(img.lastModified);
          }
          return (
            <div
              key={imgUrl}
              className="preview-img-item"
              style={{ backgroundImage: `url(${imgUrl})` }}
              onClick={this.previewImg.bind(this, imgUrl)}
            >
              {disabled ? null : (
                <CloseCircleOutlined className="close-icon" onClick={this.deletePreviewFile.bind(this, index)} />
              )}
            </div>
          );
        })}
        {previewList.length < maxCount ? (
          <div className={`upload-box ${disabled ? 'disabled' : ''}`} onClick={this.handleUploadFile.bind(this)}>
            <input
              {...rest}
              type="file"
              disabled={disabled}
              ref={this.formInput}
              onChange={this.handleInputChange.bind(this)}
            />
            {uploadButton ? uploadButton : <PlusOutlined className="plus-icon" />}
          </div>
        ) : null}
      </div>
    );
  }
}
