import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import anime from 'animejs';
import { Alert, Card, DatePicker, Empty, Input, Select, Table, message, Skeleton } from 'antd';
import { getMineList, getOverall } from '../../services/subscribe';
import constant from '../../common/constant';
import { throttle } from '../../common/utils';

import './subscribes.less';

const { RangePicker } = DatePicker;

const NULL_VALUE = 'null';

export default class Subscribe extends React.Component {
  state = {
    subscribes: [],
    loading: false,
    page: 1,
    pagination: {},
    options: {},
    startTime: null,
    endTime: null,
    status: NULL_VALUE,
    overall: {},
    keywords: '',
    noPermission: false,
  };

  async componentWillMount() {
    this.getSubscribes();
    const nextState = {};
    await getOverall({ throw: true })
      .then(overall => {
        nextState.overall = overall;
      })
      .catch(err => {
        if (err.code === 'SCHOOL_2001') {
          nextState.noPermission = true;
        } else {
          message.error(err.msg);
        }
      });
    this.setState(nextState, () => {
      if (this.mounted) {
        const animeKey = ['totalWaiting', 'todayWaiting', 'totalCount'];
        const { overall } = this.state;
        animeKey.forEach(key => {
          const target = window.document.querySelector(`#${key}`);
          anime({
            targets: target,
            innerHTML: [0, overall[key] || 0],
            round: 1,
            easing: 'easeInOutExpo',
          });
        });
      }
    });
  }

  componentDidMount() {
    this.mounted = true;
  }

  // 获取列表数据
  getSubscribes() {
    const { startTime, endTime, status, page, keywords } = this.state;
    const options = { page };
    startTime && (options.startTime = startTime);
    endTime && (options.endTime = endTime);
    status !== NULL_VALUE && (options.status = status);
    keywords && (options.keywords = keywords);
    this.setState({ loading: true }, async () => {
      try {
        const res = await getMineList(options, { throw: true });
        if (res) {
          this.setState({
            subscribes: res.data,
            pagination: res.pagination,
            options: res.options,
          });
        }
        this.setState({ loading: false });
      } catch (err) {
        const noPermission = err.code === 'SCHOOL_2001';
        if (noPermission) {
          this.setState({ loading: false, noPermission: true });
        } else {
          message.error(err.msg);
          this.setState({ loading: false });
        }
      }
    });
  }
  // 翻页
  handlePageChange = page => {
    this.setState({ page }, () => {
      this.getSubscribes();
    });
  };
  // 筛选
  filterSetting(type) {
    return {
      value: this.state[type],
      onChange: e => {
        const value = e.target ? e.target.value : e;
        this.setState({ [type]: value, page: 1 }, () => {
          throttle(() => this.getSubscribes());
        });
      },
    };
  }

  // 筛选范围选择
  filterSettingForRangePicker(start, end) {
    return {
      value: [
        (this.state[start] && moment(this.state[start])) || null,
        (this.state[end] && moment(this.state[end])) || null,
      ],
      onChange: e => {
        const value = e.target ? e.target.value : e;
        this.setState(
          {
            [start]: value[0] && value[0].toDate(),
            [end]: value[1] && value[1].toDate(),
            page: 1,
          },
          () => {
            this.getSubscribes();
          }
        );
      },
    };
  }

  // 卡片快捷搜索
  quickSearch(type) {
    switch (type) {
      case 'totalWaiting':
        this.setState(
          {
            status: constant.appointmentStatus.WAITING,
            startTime: null,
            endTime: null,
            keywords: '',
            page: 1,
          },
          () => {
            this.getSubscribes();
          }
        );
        break;
      case 'todayWaiting':
        this.setState(
          {
            status: constant.appointmentStatus.WAITING,
            startTime: new Date(),
            endTime: new Date(),
            keywords: '',
            page: 1,
          },
          () => {
            this.getSubscribes();
          }
        );
        break;
      case 'all':
        this.setState(
          {
            status: NULL_VALUE,
            startTime: null,
            endTime: null,
            keywords: '',
            page: 1,
          },
          () => {
            this.getSubscribes();
          }
        );
        break;
      default:
        break;
    }
  }

  render() {
    const {
      subscribes,
      loading,
      pagination,
      overall,
      options: { status },
      noPermission,
    } = this.state;
    if (loading) {
      return (
        <div className="skeleton-box">
          <Skeleton active loading />
          <Skeleton active loading />
          <Skeleton active loading />
        </div>
      );
    }
    if (noPermission) {
      return <div className="subscribes-container no-permission">未开启预约板块权限</div>;
    }
    const columns = [
      {
        title: '学生/家长姓名',
        dataIndex: 'contactName',
        key: 'contactName',
      },
      {
        title: '预约日期',
        dataIndex: 'appointmentTimeTextDateOnly',
        key: 'appointmentTimeTextDateOnly',
      },
      {
        title: '预约时间',
        dataIndex: 'appointmentTimeTextTimeOnly',
        key: 'appointmentTimeTextTimeOnly',
      },
      {
        title: '学生/家长电话',
        dataIndex: 'contactPhone',
        key: 'contactPhone',
      },
      {
        title: '意向国家',
        dataIndex: 'targetRegions',
        key: 'targetRegions',
        render: text => (text || []).join('，'),
      },
      {
        title: '申请学位',
        dataIndex: 'targetDegree',
        key: 'targetDegree',
      },
      {
        title: '跟进情况',
        dataIndex: 'status',
        key: 'status',
        render: text => (
          <span className={`status ${constant.appointmentStatusString[text]}`}>
            {constant.appointmentStatusText[text] || ''}
          </span>
        ),
      },
      {
        title: '学生评价',
        dataIndex: 'reviewed',
        key: 'reviewed',
        render: text => (
          <span className={`status ${text ? 'reviewed' : 'not-reviewed'}`}>{text ? '已评价' : '未评价'}</span>
        ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: id => (
          <Link type="primary" to={`/subscribe/${id}`}>
            详情
          </Link>
        ),
      },
    ];
    return (
      <div className="subscribes-container">
        <Alert
          showIcon
          closable
          type="info"
          message="学生评价的数量和分数决定了顾问的曝光量，请在电话咨询结束时积极邀请学生对你的服务进行评价"
        />
        <header>
          <Card hoverable className="card" onClick={this.quickSearch.bind(this, 'all')}>
            <Card.Meta
              title="累计被预约次数"
              description={
                <div className="total-stat-num big-num" id="totalCount">
                  {overall.totalCount || 0}
                </div>
              }
            />
          </Card>
          <Card hoverable className="card" onClick={this.quickSearch.bind(this, 'totalWaiting')}>
            <Card.Meta
              title="全部待联系"
              description={
                <div className="big-num" id="totalWaiting">
                  {overall.totalWaiting || 0}
                </div>
              }
            />
          </Card>
          <Card hoverable className="card" onClick={this.quickSearch.bind(this, 'todayWaiting')}>
            <Card.Meta
              title="今日待联系"
              description={
                <div className="big-num" id="todayWaiting">
                  {overall.todayWaiting || 0}
                </div>
              }
            />
          </Card>
          <div className="empty-box" />
        </header>
        <div className="filter-box">
          <Input.Search
            {...this.filterSetting('keywords')}
            placeholder="按学生姓名/手机号搜索"
            onSearch={() => {
              this.setState({ page: 1 }, () => {
                this.getSubscribes();
              });
            }}
          />
          <RangePicker {...this.filterSettingForRangePicker('startTime', 'endTime')} />
          <Select {...this.filterSetting('status')}>
            <Select.Option value={NULL_VALUE}>跟进情况（不限）</Select.Option>
            {(status || []).map(s => (
              <Select.Option key={s.id} value={s.id}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          dataSource={subscribes || []}
          pagination={{
            current: pagination.page,
            pageSize: pagination.pageSize,
            total: pagination.count,
            showTotal: total => `总计${total}条数据`,
            onChange: this.handlePageChange,
          }}
          locale={{
            emptyText: (
              <Empty
                description={
                  <span>
                    <span className="display-block">暂无预约</span>
                    <span className="tip display-block">
                      完善个人资料、上传更多个人形象照片、丰富你的案例库、写更多真实优质的案例故事，可有效提升预约率~
                    </span>
                  </span>
                }
              />
            ),
          }}
        />
      </div>
    );
  }
}
