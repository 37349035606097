import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import { List, Button, Empty, Alert } from 'antd';
import { getStories } from '../../services/stories';
import IconText from '../../component/icon-text/icon-text';
import constant from '../../common/constant';
import './stories.less';

export default class Stories extends React.Component {
  state = {
    stories: [],
    pagination: {
      page: 1,
      pageSize: 20,
    },
    page: 1,
  };
  componentDidMount() {
    this.getStories();
  }
  // 获取故事列表（暂为(2019-02-19)：服务器全部获取，前端分页）
  async getStories() {
    const res = await getStories(this.state.page);
    console.log('res: ', res);
    if (res) {
      this.setState({ stories: res.data, pagination: res.pagination });
    }
  }
  // 添加故事
  addStory() {
    const { history } = this.props;
    history.push('/stories/edit');
  }
  // 更新故事
  updateStory(story) {
    const { history } = this.props;
    history.push(`/stories/edit?id=${story.id}`);
  }
  // 翻页
  handlePageChange = page => {
    this.setState({ page }, () => {
      this.getStories();
    });
  };
  render() {
    const { stories, pagination } = this.state;
    console.log('pagination: ', pagination);
    return (
      <div className="stories-container">
        <div className="stories-box">
          <div className="btns-box">
            <Alert showIcon type="info" message="优质、真实的案例故事，将有效提升预约率" />
            <div className="button-box">
              <Button type="primary" icon={<PlusOutlined />} onClick={this.addStory.bind(this)}>
                新增故事
              </Button>
            </div>
          </div>
          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              current: pagination.page,
              pageSize: pagination.pageSize,
              total: pagination.count,
              showTotal: total => `总计${total}条数据`,
              onChange: this.handlePageChange,
            }}
            dataSource={stories || []}
            footer={null}
            locale={{
              emptyText: (
                <Empty
                  description={
                    <span>
                      暂无故事，
                      <a onClick={this.addStory.bind(this)}>点击新增</a>{' '}
                    </span>
                  }
                />
              ),
            }}
            renderItem={item => (
              <List.Item key={item.title} actions={[<IconText type="eye-o" text={`浏览 ${item.visitCount}`} />]}>
                <List.Item.Meta
                  title={<Link to={`/stories/${item.id}`}>{item.title}</Link>}
                  description={
                    <span className={`item-desc ${constant.statusString[item.status]}`}>
                      {item.reviewTime ? `${dayjs(item.reviewTime).format('YYYY年MM月DD日')} ` : ''}
                      {constant.statusText[item.status]}
                      {item.reviewRejectReason ? `，原因及修改建议：${item.reviewRejectReason}` : ''}
                    </span>
                  }
                />
                {item.content}
              </List.Item>
            )}
          />
        </div>
      </div>
    );
  }
}
