import React from 'react';
import './preview.less';

export default function Preview({ src, show, onHide }) {
  const imgRef = React.createRef();
  const [isShow, setIsShow] = React.useState(show);

  React.useEffect(() => {
    setIsShow(show);
    if (show === true) {
      const keydownEventHandler = event => {
        if (event.keyCode === 27 /** ESC */) {
          onHide instanceof Function && onHide();
          setIsShow(false);
        }
      };
      window.document.addEventListener('keydown', keydownEventHandler, false);
      return () => {
        window.document.removeEventListener('keydown', keydownEventHandler);
      };
    }
  }, [show]);

  // hide preview
  function onHidden(e) {
    if (!e.target.isEqualNode(imgRef.current)) {
      onHide instanceof Function && onHide();
      setIsShow(false);
    }
  }

  if (!isShow) {
    return null;
  }

  return (
    <div className="component-image-viewer">
      <div className="outer-mask" onClick={onHidden}>
        <div className="image-box">
          <img src={src} alt="图片" ref={imgRef} />
        </div>
      </div>
    </div>
  );
}
