import axios from '../common/axios';

// 教师端获取我的案例详情
export const getCaseList = ({ page, pageSize } = {}) => axios.get('/offer/v2/list/mine', { page, pageSize });

// 获取案例详情所需选项
export const getOfferItemOptions = () => axios.get(`/offer/v2/item/options`);

// 教师端获取我的案例详情
export const getCaseItem = ({ id } = {}) => axios.get('/offer/v2/item/mine', { id });

// 上传 case
export const createOffer = data => axios.post('/offer/v2/item/mine', { ...data }, { isMultipart: true });

// 编辑 case
export const editOffer = data => axios.put('/offer/v2/item/mine', { ...data }, { isMultipart: true });

// 删除 case
export const deleteOffer = ({ id } = {}) => axios.delete(`/offer/v2/item/mine?id=${id}`);

// 获项目选择框的选项数据
export const getMajorListOptions = ({ schoolId }) => axios.get('/majors/v2/list/select', { schoolId });
