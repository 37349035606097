import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import debounce from 'lodash/debounce';
import { searchPlanList } from '../services/util';

/**
 * @param {object} props
 * @param {number} props.width 设置宽度，默认100%
 * @param {object|number} props.value 方案的相关值，只给方案id时，需要填写initialName，或者填入{label: xxx, value: xxx}
 * @param {string} props.initialName 设置初始方案名称，一般用于编辑表单，可单独传入，会自动触发一次搜索
 * @param {function} props.onChange 选择方案触发的回掉
 * @param {number} props.wait 设置等待时间 单位ms 默认600ms
 * @param {boolean} props.enabledOnly 设置是否只显示上线方案
 *
 * @param {number} width 设置宽度，默认100%
 * @param {object|number} value 方案的相关值，只给方案id时，需要填写initialName，或者填入{label: xxx, value: xxx}
 * @param {string} initialName 设置初始方案名称，一般用于编辑表单，可单独传入，会自动触发一次搜索
 * @param {function} onChange 选择方案触发的回掉
 * @param {number} wait 设置等待时间 单位ms 默认600ms
 * @param {boolean} enabledOnly 设置是否只显示上线方案
 */
export default function SearchPlan({
  placeholder = '输入方案名称搜索',
  width,
  value,
  onChange = () => {},
  wait = 600,
  disabled = false,
  enabledOnly,
}) {
  const [options, setOptions] = useState([]);
  const [keywords, setKeywords] = useState();

  const fetchData = async params => {
    const data = (await searchPlanList({ ...params, enabled: enabledOnly || undefined })) || [];
    setOptions(data.data);
  };

  useEffect(() => {
    if (typeof value === 'number' && options.every(item => item.id !== value)) {
      fetchData({ ids: [value] });
    }
  }, [value]);

  useEffect(() => {
    if (keywords) {
      fetchData({ keywords });
    }
  }, [keywords]);

  const onChangeSchool = currentValue => {
    onChange(currentValue);
  };

  return (
    <Select
      style={{ width: width || '100%' }}
      allowClear
      showSearch
      filterOption={false}
      onSearch={debounce(setKeywords, wait)}
      placeholder={placeholder}
      value={value}
      onChange={onChangeSchool}
      disabled={disabled}
    >
      {options?.map(item => (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
}
