import axios from '../common/axios';

// 搜索方案列表
export const searchPlanList = ({ keywords, ids = [], enabled, page = 1, pageSize = 100 } = {}) =>
  axios.get('/thirdparty/service/plan/search/select', {
    keywords,
    ids: JSON.stringify(ids),
    enabled,
    page,
    pageSize,
  });

// 搜索学校
export const searchSchool = ({ keyword = '', ids = [] } = {}) =>
  axios.get('/select/schools', { keyword, ids: JSON.stringify(ids) });

// 搜索专业
export const getCipXList = () => axios.get('/cipx/lv3');
