import axios from '../common/axios';

// 获取自己的照片列表
export const getPhotos = () => axios.get('/thirdparty/consultant/profile/photos');

// 上传照片
export const uploadPhoto = image => axios.post('/thirdparty/consultant/photo', { image }, { isMultipart: true });

// 设置封面照片
export const setCover = id => axios.put('/thirdparty/consultant/photo/cover', { id });

// 删除照片
export const deletePhoto = id => axios.delete(`/thirdparty/consultant/photo?id=${id}`);
