import React from 'react';
import { HashRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import Container from './container/index';
import { LoginContextProvider } from './context/loginContext';

export default function App() {
  return (
    <LoginContextProvider>
      <HashRouter>
        <ConfigProvider locale={zhCN} getTargetContainer={() => document.querySelector('.ant-layout .ant-layout')}>
          <Container />
        </ConfigProvider>
      </HashRouter>
    </LoginContextProvider>
  );
}
