import React from 'react';
import { Form, Button, Card, Alert } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

/**
 *
 * @param {sting} name 必填，同Form.List name
 * @param {sting} label 同Form.Item label
 * @param {number} height 显示添加按钮的高度，默认为120
 * @param {boolean} required 必填，同Form.List name
 * @param {number} columns 需要生成几列 默认2
 * @param {sting} text 按钮显示文案 添加{text}
 * @param {number} min 当有最小限制时
 * @param {number} max 当有最大限制时
 * @param {boolean} disabled 禁用添加按钮和删除按钮
 */
export default function FormListCard({
  name,
  label,
  text = '',
  height = 120,
  required = false,
  columns = 2,
  min = 0,
  max,
  children,
  disabled = false,
  ...props
}) {
  const gridTemplateColumns = Array.from({ length: columns }, () => 'minmax(0, 1fr)').join(' ');
  return (
    <Form.Item label={label} required={required} validateStatus="" help="" {...props}>
      <Form.List name={name}>
        {(fields, ops) => {
          return (
            <div style={{ display: 'grid', gridTemplateColumns, gap: 16 }}>
              {fields?.map((field, idx) => (
                <Card key={field.key} bodyStyle={{ position: 'relative', paddingRight: 40 }}>
                  {!(min > 0) || fields?.length > min ? (
                    <Button
                      type="link"
                      style={{ position: 'absolute', top: 0, right: 0, zIndex: 999 }}
                      danger
                      onClick={() => ops.remove(field.name)}
                    >
                      <DeleteOutlined />
                    </Button>
                  ) : null}
                  {children(field)}
                </Card>
              ))}
              {min > 0 && fields?.length < min ? (
                <Alert type="error" showIcon message={`至少需要${min}个${label}`} />
              ) : null}
              {!(max > 0) || fields?.length < max ? (
                <Button
                  block
                  disabled={disabled}
                  type="dashed"
                  style={{ height, minHeight: '100%' }}
                  onClick={() => ops.add({})}
                >
                  <PlusOutlined />
                  {`添加${text || label}`}
                </Button>
              ) : null}
            </div>
          );
        }}
      </Form.List>
      <Form.Item noStyle name={name} rules={[{ type: 'array', required, min, max }]} />
    </Form.Item>
  );
}
