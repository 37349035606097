import React from 'react';
import { Empty, Modal, Table, Timeline, Tooltip } from 'antd';
import Qrcode from 'qrcode';
import { getRanks } from '../../services/subscribe-rank';

import './subscribe-rank.less';

export default class SubscribeRank extends React.Component {
  state = { loading: false, ranks: [], qrcode: '' };

  async componentWillMount() {
    await this.fetchRanks();
  }

  // 获取排行数据
  fetchRanks = async () => {
    const res = await getRanks();
    if (res) {
      const { ranks, qrcode } = res;
      this.setState({ ranks, qrcode });
    }
  };
  // 展示二维码弹窗
  showQrcode = async record => {
    const qrcode = await Qrcode.toDataURL(record.link || '', {
      errorCorrectionLevel: 'H',
    });
    Modal.info({
      centered: true,
      maskClosable: true,
      okText: '关闭',
      okType: 'default',
      title: record.realName,
      className: 'rank-qrcode-modal-info',
      content: <img src={qrcode} alt="二维码" />,
    });
  };

  render() {
    const { loading, ranks, qrcode } = this.state;
    const columnAlign = 'center';
    const columns = [
      {
        title: '预约量排行',
        align: columnAlign,
        dataIndex: 'ranking',
        key: 'ranking',
      },
      {
        title: '老师名字',
        align: columnAlign,
        dataIndex: 'realName',
        key: 'realName',
        render: (realName, record) => {
          return record.ranking <= 3 ? (
            <Tooltip title="点击查看该老师的个人主页">
              <a onClick={this.showQrcode.bind(this, record)}>{realName}</a>
            </Tooltip>
          ) : (
            realName
          );
        },
      },
      {
        title: '预约量',
        align: columnAlign,
        dataIndex: 'appointmentCount',
        key: 'appointmentCount',
        className: 'important-bg',
      },
      {
        title: (
          <div>
            <div>曝光量</div>
            <div className="table-title-tip">即个人主页浏览量</div>
          </div>
        ),
        align: columnAlign,
        dataIndex: 'visitCount',
        key: 'visitCount',
        className: 'important-bg',
      },
      {
        title: '个人照片数量',
        align: columnAlign,
        dataIndex: 'photoCount',
        key: 'photoCount',
      },
      {
        title: 'offer数量',
        align: columnAlign,
        dataIndex: 'offerCount',
        key: 'offerCount',
      },
      {
        title: '案例故事数量',
        align: columnAlign,
        dataIndex: 'storyCount',
        key: 'storyCount',
      },
      {
        title: '学生评价数量',
        align: columnAlign,
        dataIndex: 'reviewCount',
        key: 'reviewCount',
      },
    ];
    return (
      <div className="subscribe-rank-container">
        <div className="tip-box">
          <div className="timeline">
            <h1>预约量提升攻略</h1>
            <Timeline>
              <Timeline.Item>
                <h2>第一步：完善个人资料，让学生更了解你；</h2>
              </Timeline.Item>
              <Timeline.Item>
                <h2>第二步：持续上传优质的个人形象照片、offer案例、案例故事；</h2>
                <div className="tip">
                  <div>
                    个人形象照片、offer案例、案例故事的数量，决定了你的个人主页在学生端的曝光量，曝光量越高，即被越多的学生看到；
                  </div>
                  <div>
                    个人形象照片、offer案例、案例故事的质量，决定了你的个人主页对学生的吸引力，即内容越优质，越容易吸引学生预约你进行咨询，也为后续的转化和签约打下基础；
                  </div>
                </div>
              </Timeline.Item>
              <Timeline.Item>
                <h2>第三步：首次电话沟通后，积极邀请学生对你的服务进行评价；</h2>
                <div className="tip">
                  学生对你的评价，将影响其他学生对你的印象，评价越多、越高，越容易吸引后来的学生预约你、信任你；
                </div>
              </Timeline.Item>
              <Timeline.Item>
                <h2>
                  第四步：关注预约量排行，经常点击排行榜前三位老师，扫描二维码打开他们在学生端的个人主页，观察和学习如何经营一个高曝光量、吸引学生预约的个人主页。
                </h2>
              </Timeline.Item>
            </Timeline>
          </div>
          <div className="qrcode-box">
            <div
              className="qrcode"
              style={{
                backgroundImage: `url(${qrcode})`,
              }}
            />
            <div className="qrcode-desc">扫描二维码查看我在选校帝学生端上的个人主页</div>
          </div>
        </div>
        <Table
          bordered
          rowKey="id"
          loading={loading}
          columns={columns}
          dataSource={ranks || []}
          locale={{
            emptyText: <Empty description="暂无排名" />,
          }}
          rowClassName={record => (record.isYourself ? 'yourself' : '')}
          pagination={false}
        />
      </div>
    );
  }
}
