import React from 'react';
import { Link } from 'react-router-dom';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import url from 'url';
import _ from 'lodash';
import { message } from 'antd';
import { Base64 } from 'js-base64';
import axios from './axios';
import { appConfig } from './config';

export const getStorage = key => {
  try {
    const result = localStorage.getItem(key);
    if (!result) {
      return result;
    }
    return JSON.parse(result);
  } catch (error) {
    console.error(error); // eslint-disable-line
    return undefined;
  }
};

export const setStorage = (key, value) => {
  try {
    if (value !== undefined) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
  }
};

export const removeStorage = key => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(error); // eslint-disable-line
  }
};

export const Storage = {
  setItem: setStorage,
  getItem: getStorage,
  removeItem: removeStorage,
};

// 获取图片 File 的 base64
export const getImgBase64 = (img, callback = _ => _) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      callback(reader.result);
      resolve(reader.result);
    });
    reader.readAsDataURL(img);
  });
};

/** 面包屑渲染 */
export const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={route.path}>{route.icon ? <LegacyIcon type={route.icon} /> : <span>{route.breadcrumbName}</span>}</Link>
  );
};

export const formatUserBackgroundLanguage = userBackground => {
  const result = [
    ['托福', userBackground.scoreToefl],
    ['雅思', userBackground.scoreIelts],
    ['小托福', userBackground.scoreToeflJunior],
  ]
    .filter(([key, score]) => score)
    .map(([key, score]) => `${key}-${score}`)
    .join(' ');

  return result || '暂无成绩';
};

export const formatUserBackgroundAcademic = userBackground => {
  const result = [
    ['GRE', userBackground.scoreGre],
    ['GMAT', userBackground.scoreGmat],
    ['SAT', userBackground.scoreSat],
    ['ACT', userBackground.scoreAct],
    ['SSAT', userBackground.scoreSsat],
  ]
    .filter(([key, score]) => score)
    .map(([key, score]) => `${key}-${score}`)
    .join(' ');

  return result || '暂无成绩';
};

export const fileToUrl = (file, cb = () => {}) => {
  if (file instanceof File) {
    window.loadImage(
      file,
      img => {
        const dataUrl = img.toDataURL('image/jpeg');
        cb(dataUrl);
      },
      { orientation: true, canvas: true } // more options: https://github.com/blueimp/JavaScript-Load-Image#options
    );
  }
};

/** 截流器 */
export function throttle(fn, time) {
  const ticket = Date.now();
  throttle.ticket = ticket;
  clearTimeout(throttle.interval);
  throttle.interval = setTimeout(() => {
    if (ticket === throttle.ticket) {
      fn();
    }
  }, time || 500);
}

/** 设备名映射 */
export const formatPlatform = p => {
  if (!p) {
    return '未知';
  }
  if (/^weapp-/.test(p)) {
    return '微信小程序';
  }
  return (
    {
      'web-mobile': '移动端网页（WAP）',
      'web-pc': 'PC端网页',
      ios: 'iOS应用',
      android: '安卓应用',
    }[p] || '未知'
  );
};

// 判断是否是 Chrome 浏览器
export const isChrome = () => {
  return (
    window.navigator.vendor.indexOf('Google') > -1 &&
    window.navigator.userAgent.indexOf('Chrome') > -1 &&
    window.chrome &&
    window.navigator.userAgent.indexOf('BIDUBrowser') === -1
  );
};

const cdnHost = new URL(appConfig.cdnUpload).host;

/**
 * 阿里云图片优化
 *
 *  指定缩略的模式type：
 *    lfit：等比缩放，限制在设定在指定w与h的矩形内的最大图片。相当于contain
 *    mfit：等比缩放，延伸出指定w与h的矩形框外的最小图片。相当于cover
 *    fill：固定宽高，将延伸出指定w与h的矩形框外的最小图片进行居中裁剪。
 *    pad：固定宽高，缩略填充
 *    fixed：固定宽高，强制缩略
 *  注意事项
 *
 *  当只指定宽度或者高度时，在等比缩放的情况下，都会默认进行单边的缩放。在固定宽高的模式下，会默认宽高一样的情况下进行缩略。
 *  对缩略后的图片的大小有限制，目标缩略图的宽与高的乘积不能超过4096 * 4096， 而且单边的长度不能超过4096 * 4。
 *  如果只指定宽度或者高度，原图按原图格式返回。如果想保存成其他格式，详细可以查看质量变换 及格式转换。
 *  调用resize,默认是不允许放大。即如果请求的图片对原图大，那么返回的仍然是原图。如果想取到放大的图片，即增加参数调用limit,0
 *  （如：https://image-demo.oss-cn-hangzhou.aliyuncs.com/example.jpg?x-oss-process=image/resize,w_500,limit_0）
 * */
export const optimizeImage = (
  /* string */ imageUrl,
  /* number */ width,
  /* number */ height,
  /* string */ type = 'fill',
  /* string */ format = 'webp' /* 由于是强制使用 chrome 默认支持 webp 格式图片 */
) => {
  // 判断是 cdnUpload 的域名之下的链接才进行处理
  if (imageUrl && imageUrl.indexOf(cdnHost) > 0 && !url.parse(imageUrl).search) {
    if (['fill', 'lfit', 'mfit', 'pad', 'fixed'].indexOf(type) === -1) {
      console.error(`unsupported optimize type ${type}, should be ['fill', 'lfit', 'mfit', 'pad', 'fixed']`); // eslint-disable-line
      type = 'fill';
    }

    if (!height && !width) {
      return `${imageUrl}?x-oss-process=image/interlace,1/quality,q_75${format ? `/format,${format}` : ''}`;
    }

    if (!height && width) {
      return `${imageUrl}?x-oss-process=image/resize,m_${type},w_${width}/interlace,1/quality,q_75${
        format ? `/format,${format}` : ''
      }`;
    }

    if (!width && height) {
      return `${imageUrl}?x-oss-process=image/resize,m_${type},h_${height}/interlace,1/quality,q_75${
        format ? `/format,${format}` : ''
      }`;
    }

    return `${imageUrl}?x-oss-process=image/resize,m_${type},h_${height},w_${width}/interlace,1/quality,q_75${
      format ? `/format,${format}` : ''
    }`;
  }
  return imageUrl;
};
optimizeImage.FILL = 'fill';
optimizeImage.LFIT = 'lfit';
optimizeImage.MFIT = 'mfit';
optimizeImage.PAD = 'pad';
optimizeImage.FIXED = 'fixed';

/**
 * 获取图片宽高
 * @param {string} imageUrl
 */
export const getImageSize = async imageUrl => {
  const image = await getLoadedImage(imageUrl);
  return {
    width: image.width,
    height: image.height,
  };
};

export const getLoadedImage = async url => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = function onload() {
      resolve(image);
    };
    image.onerror = () => {
      reject(new Error(`Cannot load image: ${url}`));
    };
    image.src = url;
  });
};

// 获取视频宽高和时长
export const getVideoSize = videoUrl => {
  const video = document.createElement('video');
  return new Promise((resolve, reject) => {
    video.crossOrigin = 'anonymous'; // 处理跨域
    video.src = videoUrl;
    video.autoplay = true; // 避免出现第一帧是黑色

    video.addEventListener('loadedmetadata', () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration,
      });
    });

    video.onerror = () => reject(new Error('视频尺寸读取失败'));
  }).finally(() => {
    video.pause();
    video.src = '';
    video.load();
  });
};

// 获取视频第一帧
export const getVideoThumbnailUrl = (videoUrl, time = 0) => {
  const video = document.createElement('video');
  return new Promise((resolve, reject) => {
    let dataURL = '';
    video.crossOrigin = 'anonymous'; // 处理跨域
    video.src = videoUrl;
    video.autoplay = true; // 避免出现第一帧是黑色
    video.currentTime = time;

    video.addEventListener('loadeddata', () => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth; // canvas的尺寸和图片一样
      canvas.height = video.videoHeight;
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height); // 绘制canvas
      video.pause();
      dataURL = canvas.toDataURL('image/jpeg'); // 转换为base64
      resolve(dataURL);
    });

    video.onerror = () => reject(new Error('视频截帧失败'));
  }).finally(() => {
    video.pause();
    video.src = '';
    video.load();
  });
};

export const createFileFromDataURL = async (dataURL, filename = 'temp.jpeg') => {
  const mime = /data:([^;]+);/.exec(dataURL)[1];
  const data = Uint8Array.from(
    Base64.atob(dataURL.split(/[;,]/).pop())
      .split('')
      .map(char => char.charCodeAt(0))
  );
  if (!mime) {
    throw new Error('文件类型提取错误');
  }
  return new File([data], filename, { type: mime });
};
// 上传文件
export const asyncUploadFile = (file, preset) => axios.post(`/upload/ufile?preset=${preset}`, { file });
/**
 * 检查\[值\]是否符合检查条件
 * @param {object} values
 * @param {ObjectCondition} condition
 */
export function check(values, condition) {
  try {
    const ok = __check(values, condition);
    return ok;
  } catch {
    return false;
  }
}

/**
 *
 * @param {object} values
 * @param {ObjectCondition} condition
 */
function __check(values, condition) {
  if (!_.isPlainObject(condition)) {
    throw new TypeError('condition should be plain object');
  }
  return _.entries(condition).every(([key, rule]) => {
    if (key[0] === '$') {
      switch (key) {
        case '$and': {
          if (!(Array.isArray(rule) && rule.length > 0)) {
            throw new TypeError('rules of $and should be array with length >= 1');
          }
          return rule.every(r => __check(values, r));
        }
        case '$or': {
          if (!(Array.isArray(rule) && rule.length > 0)) {
            throw new TypeError('rules of $or should be array with length >= 1');
          }
          return rule.some(r => __check(values, r));
        }
        case '$in': {
          if (!(Array.isArray(rule) && rule.length > 0)) {
            throw new TypeError('rules of $in should be array with length >= 1');
          }
          return rule.some(r => _.isEqual(values, r));
        }
        case '$eq': {
          return _.isEqual(values, rule);
        }
        case '$lt': {
          return values < rule;
        }
        case '$lte': {
          return values <= rule;
        }
        case '$gt': {
          return values >= rule;
        }
        case '$gte': {
          return values > rule;
        }
        case '$has': {
          return Array.isArray(values) && values.includes(rule);
        }
        default:
          throw new TypeError(`unknown operator ${key}`);
      }
    } else if (_.isPlainObject(rule)) {
      return __check(values[key], rule);
    } else {
      return _.isEqual(values[key], rule);
    }
  });
}

export const sleep = duration => new Promise(resolve => setTimeout(resolve, duration));

/**
 * @template TFunction
 * @param {TFunction} func
 * @param {Parameters<TFunction>} args
 * @return {ReturnType<TFunction>}
 */
function callWithoutThrow(func, ...args) {
  try {
    if (typeof func === 'function') {
      return func(...args);
    }
  } catch (e) {
    console.error(`[callWithoutThrow] ${e.message}`);
  }
}

export const showError = err => {
  message.error(err?.message ?? String(err));
};

/**
 * @param {() => any} asyncFunction 如果返回Promise，会等到Promise完成时解锁
 * @param {object} options
 * @param {() => any} [options.onLock] 当进行锁定时可以触发的动作
 * @param {() => any} [options.onUnlock] 当进行解锁时可以触发的动作
 * @param {() => any} [options.onDuplicate] 当尝试执行并已经被锁定时触发的动作
 * @param {(err: any) => any} [options.onError] 当尝试执行出错时可以触发的动作（之后还会正常触发解锁）
 * @param {number} [options.sleep] 成功或结束后延迟一段时间才解锁
 */
export const asyncLock = (asyncFunction, options) => {
  let locked = false;
  const lock = () => {
    locked = true;
    callWithoutThrow(options?.onLock);
  };
  const unlock = () => {
    locked = false;
    callWithoutThrow(options?.onUnlock);
  };

  return (...args) => {
    if (locked) {
      callWithoutThrow(options?.onDuplicate);
      return;
    }
    lock();
    const p = Promise.resolve().then(() => asyncFunction(...args));

    if (typeof (options?.onError ?? showError) === 'function') {
      p.catch(options?.onError ?? showError);
    }

    p.catch(_.noop)
      .then(() => sleep(options?.sleep ?? 1000))
      .then(unlock);

    return p;
  };
};

/**
 * 构造模糊查询正则，例如：'南大' => /南.*大/
 * @param {string} keyword
 * @return {RegExp}
 */
export const makeFuzzySearchRegexp = keyword => {
  if (typeof keyword !== 'string') {
    return null;
  }
  const matches = keyword.match(/\w+|[\u4e00-\u9fa5]|[^\w\s\u4e00-\u9fa5]+/g);
  if (!matches) {
    return null;
  }
  return new RegExp(`${matches.map(text => _.escapeRegExp(text)).join('.*')}`);
};
