import React from 'react';
import { Form, Row, Input, Col, Select, Card, Button, Alert, Space } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FormImage from '../../../component/upload-image';

const RULES_STRING_REQUIRED = [{ type: 'string', required: true, whitespace: true }];
const STYLE_CARD_BODY = { position: 'relative' };
const STYLE_CARD_DEL = { position: 'absolute', top: 0, right: 0, zIndex: 999 };

// 判断文本字符长度
function getStringLength(text) {
  const sum = [...text].reduce((pre, next) => pre + (next.charCodeAt(0) < 0xff ? 1 : 2), 0);
  return sum;
}
async function validateStringLength(rule, value) {
  if (!value) {
    return;
  }
  const len = getStringLength(value);
  if (rule.max > 0 && len > rule.max) {
    throw new Error(`字数超过限制 ${len} / ${rule.max}`);
  }
}

const RULE_STRING_LIMIT = max => ({ type: 'string', max, validator: validateStringLength });

function TextArea(props) {
  return <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} {...props} />;
}

const FormItem = Form.Item;
const Option = Select.Option;

function FormList({ name, label, required = false, min = 0, max, children, disabled }) {
  return (
    <FormItem label={label} required={required} validateStatus="" help="">
      <Form.List name={name}>
        {(fields, ops) => {
          return (
            <Space style={{ width: '100%' }} direction="vertical">
              {fields.map(field => (
                <Card key={field.key} bodyStyle={STYLE_CARD_BODY}>
                  {!(min > 0) || fields.length > min ? (
                    <Button
                      disabled={disabled}
                      type="link"
                      danger
                      style={STYLE_CARD_DEL}
                      onClick={() => ops.remove(field.name)}
                    >
                      <DeleteOutlined />
                    </Button>
                  ) : null}
                  {children(field)}
                </Card>
              ))}
              {min > 0 && fields.length < min ? (
                <Alert type="error" showIcon message={`至少需要${min}个${label}`} />
              ) : null}
              {!(max > 0) || fields.length < max ? (
                <Button disabled={disabled} block type="dashed" onClick={() => ops.add({})}>
                  <PlusOutlined />
                  {`添加${label}`}
                </Button>
              ) : null}
            </Space>
          );
        }}
      </Form.List>
      <FormItem noStyle name={name} rules={[{ type: 'array', required, min, max }]} />
    </FormItem>
  );
}

export default function CommonForm(disabled) {
  return (
    <Row gutter={16}>
      <Col span={11}>
        <FormItem name={['adminData', 'theme']} label="模版风格" rules={RULES_STRING_REQUIRED}>
          <Select disabled={disabled}>
            <Option value="first-type">风格一</Option>
            <Option value="second-type">风格二</Option>
          </Select>
        </FormItem>
        <FormList disabled={disabled} label="内容板块" name={['adminData', 'blocks']} required min={1} max={10}>
          {field => (
            <>
              <FormItem
                name={[field.name, 'title']}
                label="板块标题"
                rules={RULES_STRING_REQUIRED.concat(RULE_STRING_LIMIT(34))}
              >
                <Input disabled={disabled} />
              </FormItem>
              <FormList disabled={disabled} label="内容要点" name={[field.name, 'points']} required min={1} max={10}>
                {field => (
                  <>
                    <FormItem
                      name={[field.name, 'title']}
                      label="要点标题"
                      rules={RULES_STRING_REQUIRED.concat(RULE_STRING_LIMIT(38))}
                    >
                      <Input disabled={disabled} />
                    </FormItem>
                    <FormItem name={[field.name, 'description']} label="要点描述" rules={[RULE_STRING_LIMIT(400)]}>
                      <TextArea disabled={disabled} />
                    </FormItem>
                    <FormItem name={[field.name, 'images']} label="要点插图">
                      <FormImage disabled={disabled} preset="common-image" max={3} />
                    </FormItem>
                  </>
                )}
              </FormList>
            </>
          )}
        </FormList>
      </Col>
      <Col span={11} offset={2}>
        <FormItem
          name={['adminData', 'description']}
          label="方案卖点"
          rules={RULES_STRING_REQUIRED.concat(RULE_STRING_LIMIT(400))}
        >
          <TextArea disabled={disabled} />
        </FormItem>
        <FormList disabled={disabled} label="服务流程" name={['adminData', 'steps']} required min={1} max={10}>
          {field => (
            <>
              <FormItem
                name={[field.name, 'title']}
                label="流程标题"
                rules={RULES_STRING_REQUIRED.concat(RULE_STRING_LIMIT(34))}
              >
                <Input disabled={disabled} />
              </FormItem>
              <FormItem
                name={[field.name, 'description']}
                label="流程描述"
                rules={RULES_STRING_REQUIRED.concat(RULE_STRING_LIMIT(400))}
              >
                <TextArea disabled={disabled} />
              </FormItem>
            </>
          )}
        </FormList>
        <FormList disabled={disabled} label="费用说明" name={['adminData', 'fees']} required min={1} max={10}>
          {field => (
            <>
              <FormItem
                name={[field.name, 'title']}
                label="费用说明标题"
                rules={RULES_STRING_REQUIRED.concat(RULE_STRING_LIMIT(34))}
              >
                <Input disabled={disabled} />
              </FormItem>
              <FormItem
                name={[field.name, 'description']}
                label="详细说明"
                rules={RULES_STRING_REQUIRED.concat(RULE_STRING_LIMIT(400))}
              >
                <TextArea disabled={disabled} />
              </FormItem>
            </>
          )}
        </FormList>
      </Col>
    </Row>
  );
}
