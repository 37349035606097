import axios from '../common/axios';

// 获取自己的照片列表
export const getOffers = () => axios.get('/thirdparty/consultant/profile/offers');

// 上传 offer
export const uploadOffer = (image, schoolId) =>
  axios.post('/thirdparty/consultant/offer', { image, schoolId }, { isMultipart: true });
// 编辑 offer
export const editOffer = (image, schoolId, id) =>
  axios.put('/thirdparty/consultant/offer', { image, schoolId, id }, { isMultipart: true });

// 删除 offer
export const deleteOffer = id => axios.delete(`/thirdparty/consultant/offer?id=${id}`);
