import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'antd';
import { getCipXList } from '../services/util';

const { TreeNode } = TreeSelect;

/**
 * @param {object} props
 * @param {number} props.width 设置宽度，默认100%
 * @param {object|number} props.value majorId
 * @param {function} props.onChange 选择专业触发的回掉
 *
 * @param {number} width 设置宽度，默认100%
 * @param {object|number} value majorId
 * @param {function} onChange 选择专业触发的回掉
 */

export default function SearchCip({ width, value, onChange = () => {}, placeholder = '选择专业', disabled = false }) {
  const [cipList, setCipList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const result = (await getCipXList()) || [];
      setCipList(result);
    };
    fetchData();
  }, [1]);

  const onChangeCip = currentValue => {
    onChange(currentValue);
  };

  return (
    <TreeSelect
      style={{ width: width || '100%' }}
      disabled={disabled}
      allowClear
      placeholder={placeholder}
      value={value}
      treeDefaultExpandedKeys={getCipIds(cipList, value)}
      filterTreeNode
      onChange={onChangeCip}
    >
      {cipOption(cipList)}
    </TreeSelect>
  );
}

// get treeSelect item
function cipOption(list) {
  return list.map(cip => {
    return (
      <TreeNode key={cip.id} selectable={!cip.children} title={cip.name} value={cip.id}>
        {cip.children ? cipOption(cip.children) : null}
      </TreeNode>
    );
  });
}

// get selected cip
function getCipIds(cip, majorId) {
  for (const c of cip) {
    for (const s of c.children) {
      for (const m of s.children) {
        if (m.id === majorId) {
          return [c.id, s.id, majorId];
        }
      }
    }
  }
  return [];
}
