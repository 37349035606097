import React from 'react';
import qrcodeApp from '../../assets/images/qrcode_app.png';
import './profile.less';

export default function Profile() {
  return (
    <div className="container-profile">
      <div className="box">
        <img src={qrcodeApp} alt="二维码" />
        <p className="text">
          请下载选校帝APP
          <br />
          登陆【老师版】账号维护您的个人资料
        </p>
      </div>
    </div>
  );
}
