import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, message } from 'antd';
import { LoginContext } from '../../context/loginContext';
import { sendCaptcha, getCaptchaImage } from '../../services/user';
import './login.less';
import { appConfig } from '../../common/config';

const { Item } = Form;

function Login({ form: { getFieldDecorator, validateFieldsAndScroll, getFieldValue } }) {
  const CAPTCHA_TOTAL_COUNT = 30; // 验证码倒计时时间, 秒
  const { dispatch } = React.useContext(LoginContext);
  let [captchaImage, setCaptchaImage] = React.useState({});
  let [count, setCount] = React.useState(CAPTCHA_TOTAL_COUNT);
  let timer = null;

  // 倒计时
  function countdown() {
    let currentCount = CAPTCHA_TOTAL_COUNT;
    clearTimer();
    timer = setInterval(() => {
      currentCount--;
      if (currentCount <= 0) {
        clearTimer();
        setCount(CAPTCHA_TOTAL_COUNT);
      } else {
        setCount(currentCount);
      }
    }, 1000);
  }
  function clearTimer() {
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
  }

  // 获取图形验证码
  const getCaptchaImageAsync = React.useCallback(async () => {
    setCaptchaImage(await getCaptchaImage());
  });
  React.useEffect(() => {
    getCaptchaImageAsync();
  }, []);

  // 更新图形验证码
  function updateCaptchaImage() {
    getCaptchaImageAsync();
  }

  // 发送短信验证码
  function sendSmsCaptcha() {
    if (count !== CAPTCHA_TOTAL_COUNT) {
      return;
    }
    validateFieldsAndScroll(['imageCaptcha', 'phone'], async (err, { phone, imageCaptcha }) => {
      if (!err) {
        try {
          await sendCaptcha({
            phone,
            captcha: imageCaptcha,
            nonce: captchaImage.nonce,
          });
          countdown();
          message.success('验证码发送成功，请留意手机短信');
        } catch (error) {
          console.log(error); // eslint-disable-line
          message.error(error.message || '验证码发送失败');
        }
      }
    });
  }

  // 提交登录表单
  function submit(e) {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        dispatch({
          type: 'login',
          payload: {
            phone: values.phone,
            captcha: values.smsCaptcha,
            nonce: captchaImage.nonce,
          },
        });
      }
    });
  }
  return (
    <div
      className="login-container"
      style={{
        backgroundImage: `url(${appConfig.cdnUpload}/upload/ufile/a5/db/a5db762376d3180692434aaf0822fd7d16e90a.jpg)`,
      }}
    >
      <div className="login-box">
        <div className="top" />
        <Form onSubmit={submit} className="form">
          <h1>选校帝导师管理平台</h1>
          <div className="form-item-container">
            <Item>
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message: '请输入手机号码!',
                  },
                  {
                    pattern: /^1\d{10}$/,
                    message: '手机号码格式错误!',
                  },
                ],
              })(<Input placeholder="填写11位的手机号码" />)}
            </Item>
            <Item>
              <div className="item">
                {getFieldDecorator('imageCaptcha', {
                  rules: [
                    {
                      required: true,
                      message: '请输入右侧的图形验证码!',
                    },
                  ],
                })(<Input placeholder="图形验证码" />)}
                <div
                  className="captcha-box"
                  dangerouslySetInnerHTML={{ __html: captchaImage.image }}
                  onClick={updateCaptchaImage}
                />
              </div>
            </Item>
            <Item>
              <div className="item">
                {getFieldDecorator('smsCaptcha', {
                  rules: [
                    {
                      required: true,
                      message: '请输入短信验证码!',
                    },
                  ],
                })(<Input placeholder="短信验证码" />)}
                <div className="send-btn" onClick={() => sendSmsCaptcha()}>
                  {count === CAPTCHA_TOTAL_COUNT ? '发送验证码' : `${count}秒`}
                </div>
              </div>
            </Item>
            <Item>
              <Button className="submit-btn" type="primary" htmlType="submit">
                登录
              </Button>
            </Item>
          </div>
        </Form>
        <div className="bottom" />
      </div>
    </div>
  );
}

export default Form.create()(Login);
