import React from 'react';
import { MinusSquareOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Modal, Spin, Radio, Row, Col } from 'antd';
import get from 'lodash/get';
import RichText from '../../component/richtext-editorV2/rich_text';
import RichtextPreview from '../../component/richtext-preview/richtext-preview';
import SearchSchool from '../../component/search-school/search-school';
import FormUpload from '../../component/form-upload/form-upload';
import { createStory, getStory, updateStory, getOptions } from '../../services/stories';
import { asyncUploadFile } from '../../common/utils';
import constant from '../../common/constant';

import './story-edit.less';
import { appConfig } from '../../common/config';

const { Item } = Form;

class StoryEdit extends React.Component {
  state = { loading: false, story: null, showPreviewModal: false, typeIdOptions: [] };
  content = dom => {
    this.editDom = dom;
  };

  async componentDidMount() {
    await this.getStoryList();
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  // 获取故事详情
  async getStoryList() {
    const {
      match: {
        params: { id },
      },
      form: { setFieldsValue },
    } = this.props;
    getOptions().then(res => {
      !this.isUnmounted && this.setState({ typeIdOptions: get(res, 'typeId') || [] });
    });
    if (id) {
      const story = await getStory(id);
      if (!this.isUnmounted) {
        setFieldsValue({
          title: story.title,
          content: story.content,
          covers: [story.coverUrl],
          schools: story.schools,
          typeId: story.typeId,
        });
        this.setState({ story });
      }
    }
  }

  // 打开模拟手机预览
  mobilePreview() {
    this.setState({ showPreviewModal: true });
  }

  // 更新|创建故事
  handleSubmit(submitForReview, e) {
    e.preventDefault();
    const {
      form: { validateFieldsAndScroll },
    } = this.props;
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true }, async () => {
          const { story } = this.state;
          try {
            const data = {
              submitForReview,
              title: values.title,
              content: values.content,
              typeId: values.typeId,
              schoolIds: (values.schools || []).map(s => s.id),
            };

            const cover = values.covers[0];
            if (cover instanceof File) {
              const coverImg = await asyncUploadFile(cover, 'story-cover');
              data.coverUrl = coverImg.uri;
            } else {
              data.coverUrl = cover;
            }
            if (story) {
              await updateStory({
                id: story.id,
                ...data,
              });
              message.success('更新成功');
              this.props.history.replace(`/stories/${story.id}`);
            } else {
              const newStory = await createStory(data);
              message.success('创建成功');
              this.props.history.replace(`/stories/${newStory.id}`);
            }
          } catch (error) {
            this.setState({ loading: false });
            message.error(`保存失败，${error.message}`);
          }
        });
      }
    });
  }

  onContentChange = value => {
    console.log('value: ', value);
  };

  render() {
    const {
      form: { getFieldDecorator, getFieldValue },
    } = this.props;
    const { loading, story, showPreviewModal, typeIdOptions = [] } = this.state;
    const routes = [
      {
        path: '/',
        icon: 'home',
      },
      {
        path: '/stories',
        breadcrumbName: '案例故事',
      },
    ];
    if (story) {
      routes.push({
        path: `/stories/${story.id}`,
        breadcrumbName: story.title,
      });
    }
    routes.push({
      path: '/stories/edit',
      breadcrumbName: story ? '编辑故事' : '新增故事',
    });

    const titleMaxLength = 20; // 标题最大长度限制
    const schoolMaxLength = 5; // 相关院校最大限制
    return (
      <div className="story-edit-container">
        <Modal
          className="story-edit-modal"
          visible={showPreviewModal}
          footer={null}
          closable={false}
          centered={true}
          onCancel={() => this.setState({ showPreviewModal: false })}
        >
          <div
            className="preview-box"
            style={{
              backgroundImage: `url(${require('../../assets/images/iphone6s_white.png')})`,
            }}
          >
            <RichtextPreview content={getFieldValue('content')} />
          </div>
        </Modal>
        <Spin spinning={loading}>
          <Form onSubmit={this.handleSubmit.bind(this, true)}>
            <Item label="类型" className="title-item">
              {getFieldDecorator('typeId', {
                rules: [{ required: true, message: '请选择类型!' }],
              })(
                <Radio.Group buttonStyle="solid">
                  {typeIdOptions.map(item => (
                    <Radio.Button key={item.id} value={item.id}>
                      {item.name}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              )}
            </Item>
            <Item label="封面" className="title-item">
              {getFieldDecorator('covers', {
                rules: [{ required: true, message: '请上传封面!' }],
              })(<FormUpload accept={constant.imgAccept} maxCount={1} />)}
            </Item>
            <Item label="相关院校（选填）" className="title-item">
              {getFieldDecorator('schools', {
                rules: [
                  {
                    validator: (rule, value = [], cb) => {
                      value.length > schoolMaxLength ? cb(`最多只能添加 ${schoolMaxLength} 所相关院校！`) : cb();
                    },
                  },
                ],
              })(<MultiSchool />)}
            </Item>
            <Item label="标题" className="title-item">
              {getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    message: '请输入标题!',
                  },
                  {
                    validator: (rule, value = '', cb) => {
                      value.length > titleMaxLength
                        ? cb(`标题长度已超过 ${titleMaxLength} 字，当前有 ${value.length} 个字`)
                        : cb();
                    },
                  },
                ],
              })(<Input placeholder={`不超过${titleMaxLength}字，建议突出重点，具有吸引力`} />)}
            </Item>
            <Item
              colon={false}
              label={
                <span>
                  正文：
                  <span style={{ fontSize: 12, color: 'darkorange' }}>
                    （案例故事不限字数，必须附加图片，因案例故事是重点的品牌展现方式，有助于提升预约率，请重视内容的质量。）
                  </span>
                </span>
              }
              style={{ display: 'block', minHeight: '350px' }}
            >
              {/* <RichText onChange={this.onContentChange} value={content} ref="content" enableLink enableVideo /> */}
              <Row style={{ minHeight: '251px', width: '100%' }}>
                <Col span={24}>
                  {getFieldDecorator('content', {
                    rules: [
                      { required: true, message: '请输入正文!' },
                      {
                        validator: (rule, value = '', cb) => {
                          if (!/<img/.test(value)) {
                            cb('案例故事是重点的品牌展现方式，正文中请至少添加一张图片，有助于提升预约率');
                          } else {
                            cb();
                          }
                        },
                      },
                    ],
                  })(<RichText enableLink enableVideo />)}
                </Col>
              </Row>
            </Item>
            <Item style={{ textAlign: 'right', marginTop: '48px' }}>
              <Button type="default" style={{ marginRight: 24 }} onClick={this.mobilePreview.bind(this)}>
                手机预览
              </Button>
              <Button type="default" style={{ marginRight: 24 }} onClick={this.handleSubmit.bind(this, false)}>
                保存到草稿
              </Button>
              <Button type="primary" htmlType="submit">
                提交审核
              </Button>
            </Item>
          </Form>
        </Spin>
      </div>
    );
  }
}

/**
 * @props {function} onChange
 * @props {object[]} value
 * @props {object} value[i]
 * @props {string} value[i].chineseName
 * @props {number} value[i].id
 */
class MultiSchool extends React.Component {
  constructor() {
    super();

    this.state = { schoolList: [] };
  }

  static getDerivedStateFromProps(props, state) {
    return { ...state, schoolList: [...(props.value || [])] };
  }

  // 添加院校
  handleSelectSchool = school => {
    this.setState(state => {
      const schoolList = [...state.schoolList];
      if (schoolList.some(s => s.id === school.id)) {
        return state;
      }

      schoolList.push(school);
      const { onChange = _ => _ } = this.props; // for form field
      onChange(schoolList);
      return { schoolList };
    });
  };

  // 删除
  minus = school => {
    this.setState(state => {
      const schoolList = [...state.schoolList].filter(s => s.id !== school.id);
      const { onChange = _ => _ } = this.props; // for form field
      onChange(schoolList);
      return { schoolList: schoolList.filter(s => s.id !== school.id) };
    });
  };

  render() {
    const { schoolList } = this.state;
    return (
      <div>
        <SearchSchool onChange={this.handleSelectSchool} />
        <ul>
          {schoolList.map(school => (
            <li key={school.id}>
              <a
                href={`${appConfig.xxdSite}/school/${school.id}/introduction.html`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {school.chineseName}
              </a>
              <MinusSquareOutlined
                // style={{ color: 'red', marginLeft: 20 }}
                onClick={this.minus.bind(this, school)}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Form.create()(StoryEdit);
