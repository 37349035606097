import CommonForm from './common';
import ResearchForm from './research';

const types = {
  授权直申: 1,
  留学: 2,
  艺术留学: 3,
  移民: 5,
  背景提升: 6,
  科研项目: 7,
};

export function getPlanContentForm(typeId) {
  if (!typeId) {
    return null;
  }
  if (typeId === types.科研项目) {
    return ResearchForm();
  }
  return CommonForm();
}
