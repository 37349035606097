/* eslint-disable max-classes-per-file */
import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import BasisClassic from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import UploadAdapterPlugin from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import IndentPlugin from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlockPlugin from '@ckeditor/ckeditor5-indent/src/indentblock';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import TablePlugin from '@ckeditor/ckeditor5-table/src/table';
import TableToolbarPlugin from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TablePropertiesPlugin from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellPropertiesPlugin from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import PasteFromOfficePlugin from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import FontPlugin from '@ckeditor/ckeditor5-font/src/font';
import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment';
import { message } from 'antd';

import './rich_text.less';
import { appConfig } from '../../common/config';

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
    this.xhr = new XMLHttpRequest();
  }

  upload() {
    return this.loader.file
      .then(
        file =>
          new Promise((resolve, reject) => {
            this.xhr.onload = () => {
              // 请求完成回调
              try {
                const res = JSON.parse(this.xhr.responseText);
                if (res.code === 0) {
                  resolve({ default: res.data.uri });
                } else {
                  reject(new Error(res.msg));
                }
              } catch (err) {
                reject(err);
              }
            };
            this.xhr.onerror = err => {
              reject(err);
            };
            this.xhr.onabort = () => {
              reject(new Error('Aborted.'));
            };
            this.xhr.upload.onprogress = event => {
              this.loader.uploadTotal = event.total;
              this.loader.uploaded = event.loaded;
            };

            let preset = '';
            if (file.type === 'video/mp4') {
              preset = 'common-video-mp4';
            } else if (/^image\//.test(file.type)) {
              preset = 'common-image';
            } else {
              reject(new Error('Unsupported file type.'));
              return;
            }
            this.xhr.open('post', `${appConfig.api}/upload/ufile?preset=${preset}`);
            const form = new FormData(); // 准备请求数据
            form.append('file', file);
            this.xhr.send(form); // 发送请求
          })
      )
      .then(
        res => {
          // eslint-disable-next-line no-console
          console.log('上传完成', res);
          return res;
        },
        err => {
          console.error('上传失败');
          throw err;
        }
      );
  }

  abort() {
    this.xhr.abort();
  }
}

function createConfig({ enableVideo, enableLink, placeholder } = {}) {
  const plugins = [
    EssentialsPlugin,
    UploadAdapterPlugin,
    AutoformatPlugin,
    BoldPlugin,
    ItalicPlugin,
    UnderlinePlugin,
    BlockQuotePlugin,
    EasyImagePlugin,
    HeadingPlugin,
    ImagePlugin,
    ImageCaptionPlugin,
    ImageStylePlugin,
    ImageToolbarPlugin,
    ImageUploadPlugin,
    ListPlugin,
    ParagraphPlugin,
    IndentPlugin,
    IndentBlockPlugin,
    TablePlugin,
    TableToolbarPlugin,
    TablePropertiesPlugin,
    TableCellPropertiesPlugin,
    PasteFromOfficePlugin,
    FontPlugin,
    AlignmentPlugin,
  ];
  const heading = ['heading'];
  const block = ['bulletedList', 'numberedList', 'blockQuote'];
  const embed = ['imageUpload', 'insertTable', 'xxdObject'];
  const indent = ['outdent', 'indent', 'alignment'];
  const font = ['fontSize', 'bold', 'italic', 'underline'];
  const color = ['fontColor', 'fontBackgroundColor'];
  const toolbarItems = [heading, indent, font, block, embed, color];
  if (enableLink) {
    plugins.push(LinkPlugin);
    block.push('link');
  }
  if (enableVideo) {
    // TODO: 加入视频支持
  }
  return {
    plugins,
    toolbar: {
      items: toolbarItems.reduce((items, group) => (group.length > 0 ? items.concat('|', group) : items), []),
    },
    image: {
      toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
    },
    mediaEmbed: {
      providers: [
        {
          name: 'mp4',
          url: /^https?.+\.mp4$/,
          html: match => `<video src="${match}" />`,
        },
      ],
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
    },
    indentBlock: {
      classes: ['text-indent-1', 'text-indent-2', 'text-indent-3'],
    },
    placeholder: placeholder || '',
    language: 'zh-cn',
  };
}

export default class RichTextEditor extends React.Component {
  value = '';

  constructor(props) {
    super(props);
    this.config = createConfig(props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.value) {
      this.setData(nextProps.value);
    }
  }

  setEditor = () => {};

  getEditor = new Promise(resolve => {
    this.setEditor = editor => {
      resolve(editor);
    };
  });

  initEditor = editor => {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new MyUploadAdapter(loader);
    };
  };

  setData = value => {
    this.getEditor.then(editor => {
      editor.setData(value ?? '');
    });
  };

  onInit = editor => {
    this.setEditor(editor);
    this.initEditor(editor);
    if (this.props.value !== this.value) {
      this.setData(this.props.value ?? '');
    }
  };

  onChange = (event, editor) => {
    this.getEditor.then(editor => {
      const value = editor.getData();
      if (value !== this.value) {
        this.value = value;
        if (typeof this.props.onChange === 'function') {
          this.props.onChange(value);
        }
      }
    });
  };

  onError = error => {
    message.error(`Editor crashed: ${error.message}`);
  };

  render() {
    const { disabled } = this.props;
    return (
      <CKEditor
        disabled={disabled}
        editor={BasisClassic}
        onInit={this.onInit}
        onChange={this.onChange}
        onError={this.onError}
        config={this.config}
      />
    );
  }
}

RichTextEditor.Preview = function RichTextPreview({ value }) {
  return <div className="rich-text-preview-container" dangerouslySetInnerHTML={{ __html: value }} />;
};

const isEmptyContainer = document.createElement('div');
RichTextEditor.isEmpty = html => {
  if (html == null || html === '') {
    return true;
  }
  if (typeof html !== 'string') {
    return false;
  }
  isEmptyContainer.innerHTML = html;
  const text = (isEmptyContainer.innerText || '').trim();
  return text.length === 0;
};
