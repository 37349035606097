import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { PageHeader, Table, Space, Popconfirm, message, Tooltip } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getCaseList, deleteOffer } from '../../services/case';
import './case.less';
import Time from '../../component/time';

class Case extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: {
        page: 1,
        pageSize: 20,
      },
    };
  }
  componentDidMount() {
    this.loadData({ page: 1 });
  }

  loadData = filterParams => {
    const { pagination } = this.state;
    const params = {
      ...pagination,
      ...filterParams,
    };
    getCaseList(params).then(res => {
      this.setState({
        data: res.data,
        pagination: res.pagination,
      });
    });
  };

  onDelete = id => () => {
    deleteOffer({ id })
      .then(() => {
        message.success('删除成功');
        this.loadData({ page: 1 });
      })
      .catch(error => message.error(`删除失败:${error.message}`));
  };

  render() {
    const { data, pagination } = this.state;
    return (
      <div className="container-case-list">
        <PageHeader
          title="我的案例"
          extra={[
            <Link key="1" to="/case/create">
              添加案例
            </Link>,
          ]}
        />
        <Table
          style={{ marginTop: 20 }}
          columns={[
            {
              title: '案例标题',
              key: 'title',
              dataIndex: 'title',
              align: 'center',
            },
            {
              title: '案例类型',
              key: 'typeName',
              dataIndex: 'typeName',
              align: 'center',
            },
            {
              title: '申请国家',
              key: 'targetRegions',
              dataIndex: ['profile', 'targetRegions'],
              align: 'center',
            },
            {
              title: '申请阶段',
              key: 'targetDegree',
              dataIndex: ['profile', 'targetDegree'],
              align: 'center',
            },
            {
              title: '申请专业',
              key: 'targetMajor',
              dataIndex: ['profile', 'targetMajorCategory'],
              align: 'center',
            },
            {
              title: '上线时间',
              key: 'enableTime',
              dataIndex: 'enableTime',
              align: 'center',
              render: time => <Time time={time} />,
            },
            {
              title: '上线状态',
              key: 'enabled',
              dataIndex: 'enabled',
              align: 'center',
              render: enabled => (
                <Tooltip title={enabled ? '当前上线' : '当前下线'}>
                  <PoweroffOutlined className={enabled ? 'color-green6' : 'color-red6'} />
                </Tooltip>
              ),
            },
            {
              title: '审核状态',
              key: 'reviewStatusName',
              dataIndex: 'reviewStatusName',
              align: 'center',
              render: (statusName, row) => (
                <>
                  {row.reviewStatus === REVIEW_STATUS_NUMBER.REJECTED ? (
                    <div>
                      {statusName}，建议： {row.reviewRejectReason}
                    </div>
                  ) : (
                    <div>{statusName}</div>
                  )}
                </>
              ),
            },
            {
              title: '操作',
              align: 'center',
              render: row => (
                <Space>
                  {row.reviewStatus === REVIEW_STATUS_NUMBER.ACCEPTED && (
                    <Link to={`/case/${row.id}?type=disabled`}>详情</Link>
                  )}
                  {row.reviewStatus !== REVIEW_STATUS_NUMBER.PENDING && <Link to={`/case/${row.id}`}>编辑</Link>}
                  {!row.enabled && (
                    <Popconfirm title="确定删除吗" className="color-red6" onConfirm={this.onDelete(row.id)}>
                      <a>删除</a>
                    </Popconfirm>
                  )}
                </Space>
              ),
            },
          ]}
          rowKey="id"
          dataSource={data}
          pagination={{
            current: pagination.page,
            pageSize: pagination.pageSize,
            total: pagination.count,
            onChange: page => {
              this.loadData({ page });
            },
          }}
        />
      </div>
    );
  }
}
export default Case;

// 审核状态
const REVIEW_STATUS_NUMBER = {
  /** 未提交 */ CREATED: 0,
  /** 已通过 */ ACCEPTED: 1,
  /** 已拒绝 */ REJECTED: 2,
  /** 待审核 */ PENDING: 3,
};
