import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Modal,
  message,
  Button,
  Select,
  Table,
  Row,
  Col,
  DatePicker,
  Tooltip,
  Space,
  Popconfirm,
  PageHeader,
} from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getNewsList, revokeNews, deleteNews, getNewsDetail } from '../../services/news';

const { RangePicker } = DatePicker;
const { Option } = Select;
const NewsList = props => {
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({});
  const [newsList, setNewsList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [content, setContent] = useState(); //html格式字符串转化成的文本格式
  const [statuses, setStatuses] = useState();
  const [types, setTypes] = useState();

  const columns = [
    {
      title: '编号',
      dataIndex: 'id',
      width: '8%',
      align: 'center',
      key: 'id',
    },
    {
      title: '文章标题',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
      render: (title, item) => {
        return (
          <Space>
            <EyeOutlined style={{ marginRight: 8 }} onClick={() => fetchNewsDetail(item.id)} />
            <span>{title}</span>
          </Space>
        );
      },
    },
    {
      title: '类别',
      dataIndex: 'typeName',
      width: '8%',
      align: 'center',
      render: (text, item) => {
        return text || '-';
      },
    },
    {
      title: '发布时间',
      dataIndex: 'publishTime',
      width: '12%',
      align: 'center',
      key: 'publishTime',
      render: (text, item) => {
        return text ? <Tooltip title={text}>{moment(new Date(text)).format('YYYY-MM-DD HH:mm:ss')}</Tooltip> : '-';
      },
    },
    {
      title: '发布状态',
      dataIndex: 'status',
      width: '10%',
      align: 'center',
      key: 'status',
      render: (status, item) => (
        <span style={{ color: item.status === -2 && 'red' }}>
          {item.statusText} {item.status === -2 && `:${item.rejectReason}`}
        </span>
      ),
    },

    {
      title: '操作',
      dataIndex: 'op',
      width: '20%',
      key: 'content',
      align: 'center',
      render: (text, item) => renderOpera(item),
    },
  ];

  useEffect(() => {
    fetchNewsList({ page: 1, pageSize: 20 });
    // fetchOptions();
  }, []);

  const fetchNewsList = async paramsObj => {
    setLoading(true);
    try {
      const res = await getNewsList({ ...paramsObj });
      setNewsList(res.data);
      setTypes(res.meta?.types);
      setStatuses(res.meta?.statuses);
      setPagination(res.pagination);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // const fetchOptions = async () => {
  //   try {
  //     const res = await getOptions();
  //     setTypes(res.types);
  //     setStatuses(res.statuses);
  //   } catch (error) {
  //     message.error(error.message);
  //   }
  // };
  const fetchNewsDetail = async id => {
    try {
      const res = await getNewsDetail(id);
      // 将html格式的字符串格式转化为文本格式
      const html = (
        <div style={{ border: 'none', overflowY: 'auto', height: '70vh' }}>
          <h1>{res.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: res.content }} />
        </div>
      );
      setContent(html);
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchDeletedNews = async id => {
    try {
      await deleteNews(id);
      fetchNewsList({ page: 1, pageSize: 20 }).then(() => {
        message.success('设置成功');
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchRevokeNews = async id => {
    try {
      await revokeNews(id);
      fetchNewsList({ page: 1, pageSize: 20 }).then(() => {
        message.success('设置成功');
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const searchParams = (key, val) => {
    setParams({ ...params, [key]: val });
    fetchNewsList({ ...params, [key]: val });
  };

  const filterList = () => {
    return (
      <div style={{ marginBottom: 15 }}>
        <Row gutter={[24, 24]}>
          <Col span={6}>
            <Select
              placeholder="类型"
              style={{ width: '100%' }}
              onChange={typeId => searchParams('typeId', typeId)}
              allowClear
            >
              {types?.map(item => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <Select
              placeholder="发布状态"
              style={{ width: '100%' }}
              allowClear
              onChange={status => searchParams('status', status)}
            >
              {statuses?.map(item => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <RangePicker
              allowClear
              getPopupContainer={trigger => trigger.parentNode}
              placeholder={['开始时间', '结束时间']}
              onChange={publishTimeRange => searchParams('publishTimeRange', publishTimeRange)}
            />
          </Col>
        </Row>
      </div>
    );
  };

  // 渲染操作按钮
  const renderOpera = item => {
    // 草稿
    if (item.status === 0) {
      return (
        <Space>
          <Button type="link" onClick={() => props.history.push(`/news/edit-news/${item.id}`)}>
            编辑
          </Button>
          <Popconfirm title="是否确定删除该条资讯?" onConfirm={() => fetchDeletedNews(item.id)}>
            <Button type="link" danger>
              删除
            </Button>
          </Popconfirm>
        </Space>
      );
    }
    //待审核 或 已发布
    if (item.status === 1 || item.status === 3) {
      return (
        <Space>
          <Button type="link" onClick={() => props.history.push(`/news/edit-news/${item.id}`)}>
            查看
          </Button>
          <Popconfirm title="是否确定撤回该条资讯?,撤回后将立即下架该条资讯" onConfirm={() => fetchRevokeNews(item.id)}>
            <Button type="link" danger>
              撤回
            </Button>
          </Popconfirm>
        </Space>
      );
    }
    //审核不通过
    if (item.status === -2) {
      return (
        <Space>
          <Button type="link" onClick={() => props.history.push(`/news/edit-news/${item.id}`)}>
            编辑
          </Button>
          <Popconfirm title="是否确定删除该条资讯?" onConfirm={() => fetchDeletedNews(item.id)}>
            <Button type="link" danger>
              删除
            </Button>
          </Popconfirm>
        </Space>
      );
    }
  };

  return (
    <div style={{ marginLeft: 20 }}>
      <PageHeader
        title="资讯列表"
        extra={
          <Link key="create" target="_blank" to="/news/create">
            发布新资讯
          </Link>
        }
      />
      {filterList()}
      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={newsList}
        pagination={{
          pageSize: pagination?.pageSize,
          total: pagination?.count,
          current: pagination?.page,
          onChange: (page, pageSize) => fetchNewsList({ page, pageSize }),
        }}
      />
      <Modal visible={!!content} footer={false} onCancel={() => setContent(null)} width={375}>
        {content}
      </Modal>
    </div>
  );
};

export default NewsList;
