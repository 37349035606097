import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import debounce from 'lodash/debounce';
import { isArray } from 'lodash';
import { searchSchool } from '../services/util';

/**
 * @param {object} props
 * @param {number} props.width 设置宽度，默认100%
 * @param {object|number} props.value 学校的相关值，只给学校id时，需要填写initialName，或者填入{label: xxx, value: xxx}
 * @param {string} props.initialName 设置初始学校名称，一般用于编辑表单，可单独传入，会自动触发一次搜索
 * @param {function} props.onChange 选择学校触发的回掉
 * @param {number} props.wait 设置等待时间 单位ms 默认600ms
 *
 * @param {number} width 设置宽度，默认100%
 * @param {object|number} value 学校的相关值，只给学校id时，需要填写initialName，或者填入{label: xxx, value: xxx}
 * @param {string} initialName 设置初始学校名称，一般用于编辑表单，可单独传入，会自动触发一次搜索
 * @param {function} onChange 选择学校触发的回掉
 * @param {number} wait 设置等待时间 单位ms 默认600ms
 */
export default function SearchSchool({
  placeholder = '输入学校名称搜索',
  width,
  value,
  multiple,
  maxLength,
  onChange = () => {},
  labelInValue = false,
  wait = 600,
  disabled = false,
}) {
  const [options, setOptions] = useState([]);
  const [keyword, setKeyword] = useState();

  const fetchData = async params => {
    const data = (await searchSchool(params)) || [];
    setOptions(data);
  };

  useEffect(() => {
    if (isArray(value) && options.every(item => value.indexOf(item.id) === -1)) {
      fetchData({ ids: value });
    } else if (typeof value === 'number' && options.every(item => item.id !== value)) {
      fetchData({ ids: isArray(value) ? value : [value] });
    }
  }, [value]);

  useEffect(() => {
    if (keyword) {
      fetchData({ keyword });
    }
  }, [keyword]);

  const onChangeSchool = currentValue => {
    if (isArray(currentValue) && currentValue.length > maxLength) {
      return;
    }
    onChange(currentValue);
  };
  return (
    <Select
      style={{ width: width || '100%' }}
      allowClear
      labelInValue={labelInValue}
      showSearch
      filterOption={false}
      mode={multiple && 'multiple'}
      onSearch={debounce(setKeyword, wait)}
      placeholder={placeholder}
      value={value}
      onChange={onChangeSchool}
      disabled={disabled}
    >
      {options?.map(item => (
        <Select.Option key={item.id} value={item.id}>
          {item.chineseName}
        </Select.Option>
      ))}
    </Select>
  );
}
