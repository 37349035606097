import React from 'react';
import ReactDOM from 'react-dom';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import XxdObjectContent from './xxd-object-content';

export default class XxdObjectEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this._defineSchema();
    this._defineConverters();
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    schema.register('xxdObject', {
      isObject: true,
      allowWhere: '$block',
      allowAttributes: ['id', 'type', 'data'],
    });
  }

  _defineConverters() {
    const editor = this.editor;
    const conversion = editor.conversion;

    // <xxdObject> converters ((data) view → model)
    // <article data-xxd-object data-type="school" data-id="4" data-data="{}"></article>

    // 从HTML数据创建模型
    conversion.for('upcast').elementToElement({
      view: 'article',
      model: (viewElement, modelWriter) => {
        const isXxdObject = viewElement.getAttribute('data-xxd-object');
        if (!isXxdObject) {
          return false;
        }
        return modelWriter.createElement('xxdObject', {
          id: viewElement.getAttribute('data-id'),
          type: viewElement.getAttribute('data-type'),
          data: viewElement.getAttribute('data-data'),
        });
      },
    });

    // <xxdObject> converters (model → data view)
    // 从模型创建HTML数据
    conversion.for('dataDowncast').elementToElement({
      model: 'xxdObject',
      view: (modelElement, viewWriter) => {
        return viewWriter.createEmptyElement('article', {
          'data-xxd-object': true,
          'data-id': modelElement.getAttribute('id'),
          'data-type': modelElement.getAttribute('type'),
          'data-data': modelElement.getAttribute('data'),
        });
      },
    });

    // <xxdObject> converters (model → editing view)
    // 从模型创建编辑时的DOM
    conversion.for('editingDowncast').elementToElement({
      model: 'xxdObject',
      view: (modelElement, viewWriter) => {
        const props = {
          id: modelElement.getAttribute('id'),
          type: modelElement.getAttribute('type'),
          data: modelElement.getAttribute('data'),
        };
        const article = viewWriter.createContainerElement('article', {
          'data-xxd-object': true,
          'data-id': props.id,
          'data-type': props.type,
          'data-data': props.data,
          class: 'xxd-object',
        });

        const reactWrapper = viewWriter.createUIElement(
          'div',
          {
            class: 'product__react-wrapper',
          },
          function callback(domDocument) {
            const domElement = this.toDomElement(domDocument);

            ReactDOM.render(<XxdObjectContent {...props} />, domElement);

            return domElement;
          }
        );

        viewWriter.insert(viewWriter.createPositionAt(article, 0), reactWrapper);

        return toWidget(article, viewWriter, { label: 'product preview widget' });
      },
    });
  }
}
