import Qrcode from 'qrcode';
import axios from '../common/axios';

/** 获取预约量排行 */
export const getRanks = async () => {
  const ranks = await axios.get('/thirdparty/consultant/rankings');
  if (ranks) {
    const myUrl = (ranks.filter(r => r.isYourself)[0] || {}).link;
    const qrcode = myUrl ? await Qrcode.toDataURL(myUrl, { errorCorrectionLevel: 'H' }) : '';
    return {
      qrcode,
      ranks,
    };
  }
  return null;
};
