import React from 'react';
import { Input, Select, Row, Col, Button, message, Modal, Form, PageHeader, BackTop, TreeSelect } from 'antd';
import File from '../../component/upload-file';
import FromImage from '../../component/upload-image';
import { getPlanTypeData, getPlanDetail, getPlanPreview, editPlan, addPlan } from '../../services/plans';
import { getPlanContentForm } from './forms';
import {
  getVideoThumbnailUrl,
  getImageSize,
  createFileFromDataURL,
  asyncUploadFile,
  makeFuzzySearchRegexp,
} from '../../common/funcs';
import { getMajors } from '../../services/plans';
import './plans-detail.less';

const FormItem = Form.Item;
const Option = Select.Option;

const RULES_STRING_REQUIRED = [{ type: 'string', required: true, whitespace: true }];
const RULES_NUMBER_REQUIRED = [{ type: 'number', required: true }];

// 判断文本字符长度
function getStringLength(text) {
  const sum = [...text].reduce((pre, next) => pre + (next.charCodeAt(0) < 0xff ? 1 : 2), 0);
  return sum;
}
async function validateStringLength(rule, value) {
  if (!value) {
    return;
  }
  const len = getStringLength(value);
  if (rule.max > 0 && len > rule.max) {
    throw new Error(`字数超过限制 ${len} / ${rule.max}`);
  }
}

const RULE_STRING_LIMIT = max => ({
  type: 'string',
  max,
  validator: validateStringLength,
});

const wrapError = message => err => {
  console.error(err);
  throw new Error(message);
};

class PlanSetDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      consultants: [],
      submitting: false,
      preview: null,
      visible: false,
      accept: false,
    };
    this.modalForm = React.createRef();
  }

  async componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    await getPlanTypeData().then(res => {
      this.setState({ types: res });
    });

    if (id) {
      await getPlanDetail({ id }).then(res => {
        res.consultantId = res.consultantId || res.consultant?.id;
        const formDefault = { ...res };
        if (res.majorIds === null) {
          formDefault.majorIds = undefined;
        }
        this.form.setFieldsValue(formDefault);
      });
    }
    getMajors().then(res => {
      const cipOptions = this.getCipOption(res?.categories);
      this.setState({ cipOptions });
    });
  }

  goBack = () => {
    window.history.back();
  };

  preview = async () => {
    try {
      const { typeId, name, adminData } = await this.form.validateFields().catch(err => {
        throw new Error(err?.errorFields ? `表单存在${err.errorFields.length}处错误` : err?.message || '表单验证失败');
      });
      const preview = await getPlanPreview({ typeId, adminData: { ...adminData, name } });
      this.setState({ preview });
    } catch (err) {
      message.error(err.message || err);
    }
  };

  closePreview = () => {
    this.setState({ preview: null });
  };

  onCancel = () => {
    this.setState({
      visible: false,
    });
  };

  // 方案类型变化时，验证子分类数据，移除无效数据
  onChangeTypeId = typeId => {
    const type = this.state.types.find(t => t.id === typeId);
    const inputs = type?.subtypes || [];
    const prev = this.form.getFieldValue('subtypeIds');
    if (prev) {
      const next = [...prev];
      next.length = inputs.length;
      for (let i = 0; i < inputs.length; i += 1) {
        const input = inputs[i];
        const value = next[i];
        const option = input?.options?.find?.(o => o.id === value);
        if (!option) {
          next[i] = undefined;
        }
      }
      this.form.setFieldsValue({ subtypeIds: next });
    }
  };

  // 提取数据并提交
  submit = async () => {
    if (this.submitting) {
      return;
    }
    this.submitting = true;
    this.setState({
      submitting: true,
    });

    try {
      const data = await this.form.validateFields().catch(err => {
        throw new Error(err?.errorFields ? `表单存在${err.errorFields.length}处错误` : err?.message || '表单验证失败');
      });

      // 处理额外参数
      const extra = {};

      // 处理视频相关参数
      if (data.videoUrl) {
        const videoThumbnailDataUrl = await getVideoThumbnailUrl(data.videoUrl);
        const { width, height } = await getImageSize(videoThumbnailDataUrl);
        const videoThumbnailFile = await createFileFromDataURL(videoThumbnailDataUrl, 'coverUrl.jpeg');
        extra.videoThumbnailUrl = await asyncUploadFile(videoThumbnailFile, 'common-image')
          .then(res => res.uri)
          .catch(wrapError('封面上传失败'));
        extra.videoWidth = width;
        extra.videoHeight = height;
      }

      const saveData = { ...data, ...extra };

      if (saveData.id) {
        await editPlan(saveData);
      } else {
        await addPlan(saveData);
      }

      message.success('保存成功,请等待审核');
      this.setState({ submitting: false }, this.goBack);
      this.submitting = false;
    } catch (err) {
      message.error(`保存失败：${err.message || err}`);
      this.setState({ submitting: false });
      this.submitting = false;
    }
  };

  getCipOption = (cip = []) => {
    return cip.map(item => {
      if (item?.subjects || item?.majors || item?.children) {
        return {
          title: item.name,
          value: item.id,
          children: this.getCipOption(item?.subjects || item?.majors || item?.children),
          selectable: false,
        };
      } else {
        return { title: item.name, value: item.id };
      }
    });
  };

  render() {
    const { types, submitting, preview, cipOptions } = this.state;
    return (
      <div className="container-plan-set-detail">
        <PageHeader
          title="方案详情"
          style={{ border: '1px solid #d9d9d9', marginBottom: '32px' }}
          extra={[
            <Button key="preview" onClick={this.preview}>
              预览
            </Button>,
            <Button key="submit" type="primary" onClick={this.submit} loading={submitting}>
              保存
            </Button>,
          ]}
        />
        <Form
          ref={ref => {
            this.form = ref;
          }}
          layout="vertical"
        >
          <FormItem name="id" noStyle />

          <Row gutter={16}>
            <Col flex="1 1 auto">
              <Row gutter={16}>
                <Col span={8}>
                  <FormItem name="name" label="方案名称" rules={RULES_STRING_REQUIRED.concat(RULE_STRING_LIMIT(100))}>
                    <Input />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem name="englishName" label="方案英文名称" rules={[RULE_STRING_LIMIT(100)]}>
                    <Input />
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <FormItem name="typeId" label="方案类型" rules={RULES_NUMBER_REQUIRED}>
                    <Select onChange={this.onChangeTypeId}>
                      {types.map(type => (
                        <Option key={type.id} value={type.id}>
                          {type.name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>

                <Col span={16}>
                  <FormItem noStyle shouldUpdate={(prev, next) => prev.typeId !== next.typeId}>
                    {({ getFieldValue }) => {
                      const typeId = getFieldValue('typeId');
                      const type = types.find(t => t.id === typeId);
                      if (!type) {
                        return (
                          <FormItem label="方案详细类型">
                            <Input value="请选择方案类型" disabled />
                          </FormItem>
                        );
                      }
                      const cols = type.subtypes.map((st, idx) => (
                        <Col key={idx} flex="1 0 0">
                          <FormItem name={['subtypeIds', idx]} label={st.name} rules={RULES_NUMBER_REQUIRED}>
                            <Select>
                              {st.options.map(op => (
                                <Option key={op.id} value={op.id}>
                                  {op.name}
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </Col>
                      ));
                      return <Row gutter={16}>{cols}</Row>;
                    }}
                  </FormItem>
                </Col>
              </Row>
            </Col>
            <Col flex="0 0 auto">
              <FormItem name="coverUrl" label="封面图片" rules={RULES_STRING_REQUIRED}>
                <FromImage preset="org-photo" max={1} />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex="1 1 auto">
              <Row gutter={16}>
                <Col span={16}>
                  <FormItem name="marketTags" label="营销标签" rules={[{ type: 'array', required: true }]}>
                    <Select mode="tags" dropdownStyle={{ display: 'none' }} tokenSeparators={[';', '；', ',', '，']} />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem name="videoUrl" label="封面视频">
                    <File block accept="video/mp4" preset="common-video-mp4" max={1} />
                  </FormItem>
                </Col>
              </Row>
            </Col>
            <Col flex="0 0 auto">
              <FormItem name="coverTag" label="封面标签" rules={RULES_STRING_REQUIRED}>
                <Input style={{ width: 112 }} />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <FormItem noStyle shouldUpdate={(prev, next) => prev.typeId !== next.typeId}>
              {({ getFieldValue }) => {
                const typeId = getFieldValue('typeId');
                const type = types.find(t => t.id === typeId);
                if (type?.data?.withMajors) {
                  return (
                    <Col span={16}>
                      <FormItem
                        name="majorIds"
                        label="关联专业"
                        rules={[{ required: type?.data?.withMajors, type: 'array', max: 10 }]}
                      >
                        <TreeSelect
                          getPopupContainer={trigger => trigger.parentNode}
                          showSearch
                          allowClear
                          multiple
                          treeData={cipOptions}
                          treeDefaultExpandAll
                          filterTreeNode={(inputValue, treeNode) => {
                            const reg = makeFuzzySearchRegexp(inputValue);
                            return reg.test(treeNode.title);
                          }}
                          placeholder="请选择专业"
                        />
                      </FormItem>
                    </Col>
                  );
                }
              }}
            </FormItem>
          </Row>

          <FormItem noStyle shouldUpdate={(prev, next) => prev.typeId !== next.typeId}>
            {({ getFieldValue }) => {
              const typeId = getFieldValue('typeId');
              const ContentForm = getPlanContentForm(typeId);
              if (typeof ContentForm === 'function') {
                return <ContentForm />;
              }
              return ContentForm;
            }}
          </FormItem>
        </Form>
        <Modal
          visible={!!preview}
          title="预览方案详情"
          destroyOnClose
          width={375}
          centered
          onCancel={this.closePreview}
          onOk={this.closePreview}
          bodyStyle={{ padding: 0 }}
        >
          <iframe srcDoc={preview?.html || ''} title="preview" style={{ width: '100%', height: 640, border: 0 }} />
        </Modal>
        <BackTop />
      </div>
    );
  }
}

export default PlanSetDetail;
