// 引用
import React, { Component } from 'react';
import { Link } from 'react-router';
import { Space, PageHeader } from 'antd';

// 容器
export default class Title extends Component {
  // 渲染
  render() {
    const operations = [];
    const { text, handle, children } = this.props;

    (this.props.operations || []).forEach((item, index) => {
      const key = index;
      operations.push(
        <Link key={key} to={item.link} onClick={item.onClick || (() => {})}>
          {item.title}
        </Link>
      );
    });

    const subtitle = this.props.subtitle ? <span className="subtitle">{this.props.subtitle}</span> : null;

    return (
      <PageHeader title={text} subTitle={subtitle} extra={<Space>{[...operations, handle]}</Space>}>
        {children}
      </PageHeader>
    );
  }
}
