import Command from '@ckeditor/ckeditor5-core/src/command';

export default class XxdObjectCommand extends Command {
  execute(props) {
    this.editor.model.change(writer => {
      this.editor.model.insertContent(writer.createElement('xxdObject', props));
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const element = selection.getSelectedElement();
    this.isEnabled = element ? !element.is('xxdObject') : true;
  }
}
