import React from 'react';
import {
  PageHeader,
  Form,
  Row,
  Col,
  Card,
  Select,
  Input,
  Button,
  Space,
  Checkbox,
  message,
  InputNumber,
  Alert,
  Affix,
  TreeSelect,
  Spin,
} from 'antd';
import _ from 'lodash';
import UploadImage from '../../component/upload-image';
import FileUpload from '../../component/upload-file';
import FormListCard from '../../component/form_list_card';
import SearchPlan from '../../component/search_plan';
import SearchSchool from '../../component/search_school';
import SearchCip from '../../component/search_cip';
import RichText from '../../component/rich-text';
import { getOfferItemOptions, getCaseItem, createOffer, editOffer, getMajorListOptions } from '../../services/case';
import {
  getImageSize,
  getVideoSize,
  check,
  asyncLock,
  asyncUploadFile,
  createFileFromDataURL,
  getVideoThumbnailUrl,
  makeFuzzySearchRegexp,
} from '../../common/utils';
import './info.less';
import querystring from 'querystring';
import { getCipXList } from '../../services/util';

const optionTranslate = (option = [], valueKey = 'id', labelKey = 'name') => {
  return option.map(o => ({ label: o[labelKey], value: o[valueKey], ...o }));
};

// 根据选择条件改变options
const optionDisabled = ({ path = '', options = [], getFieldValue }) => {
  let optionsTranslated = optionTranslate(options);
  const currentValue = getFieldValue(path) || [];
  const currentOptions = optionsTranslated?.filter(item => currentValue.includes(item.value)) || [];
  let excludes = [];
  let includes = [];
  let only = false;
  currentOptions.forEach(o => {
    if (o.logic?.exclude) {
      excludes = _.union([...excludes, ...o.logic?.exclude]);
    }
    if (o.logic?.include) {
      includes = _.union([...includes, ...o.logic?.include]);
    }
    if (o.logic?.only) {
      only = true;
    }
  });

  optionsTranslated = optionsTranslated.map(o => {
    if (only) {
      if (!o.logic?.only) {
        return {
          ...o,
          disabled: true,
        };
      }
    }
    if (excludes.length > 0) {
      if (excludes.indexOf(o.value) !== -1) {
        return {
          ...o,
          disabled: true,
        };
      }
    }
    if (includes.length > 0) {
      if (includes.indexOf(o.value) === -1) {
        return {
          ...o,
          disabled: true,
        };
      }
    }
    return o;
  });

  return optionsTranslated;
};

const makeMajorsRules = path => ({ getFieldValue }) => ({
  validator() {
    const validateTrue = !!getFieldValue([...path, 'programId']) && !!getFieldValue([...path, 'programName']);
    const validateFalse = !getFieldValue([...path, 'programId']) && !getFieldValue([...path, 'programName']);
    if ((validateTrue || validateFalse) && getFieldValue(['profile', 'targetDegree']) > 400) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('关联项目和填写项目有且只能有一个');
    }
    return Promise.resolve();
  },
});

export default class CaseInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resultOptions: [],
      typeOptions: [],
      formList: {},
      otherFromList: {},
      majorOptions: {},
      schoolIds: [],
      cipOptions: [],
      spinning: false,
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    getOfferItemOptions().then(options => {
      const formList = getFormListOption(options.profile);
      this.setState({
        resultOptions: options.result,
        typeOptions: options.type,
        formList,
        otherFromList: _.pick(options.profile, [
          'targetRegions',
          'yearOfEntry',
          'yearOfApplication',
          'targetDegree',
          'targetMajorCategory',
        ]),
      });
    });
    const { match: { params: { id } } = {} } = this.props;
    if (id !== 'create') {
      this.setState({
        spinning: true,
      });
      getCaseItem({ id }).then(async res => {
        const schoolIds = res.results.map(item => item.schoolId);
        const majorOptions = {};
        for (const schoolId of schoolIds) {
          if (schoolId) {
            const majorOption = await getMajorListOptions({ schoolId });
            majorOptions[schoolId] = majorOption;
          }
        }
        this.setState(
          {
            majorOptions,
            spinning: false,
          },
          () => {
            this.form.current.setFieldsValue(res);
          }
        );
      });
    }
    // eslint-disable-next-line no-unused-expressions
    this.form.current?.setFieldsValue({
      results: [{}],
    });

    getCipXList().then(res => {
      const cipOptions = this.getCipOption(res);
      this.setState({
        cipOptions,
      });
    });
  }

  getCipOption = (cip = []) => {
    return cip.map(item => {
      if (item.children) {
        return {
          title: item.name,
          value: item.id,
          children: this.getCipOption(item.children),
          selectable: false,
        };
      } else {
        return { title: item.name, value: item.id };
      }
    });
  };

  deleteEmptyImage = () => {
    let images = this.form.current.getFieldValue('images') || [];
    images = images.filter(image => !!image.url);
    this.form.current.setFieldsValue({
      images,
    });
  };

  getResultTitle = async () => {
    const { otherFromList } = this.state;
    const form = this.form.current;
    try {
      const targetDegreeObj = await form.validateFields([['profile', 'targetDegree']]);
      const targetDegree = targetDegreeObj.profile.targetDegree;
      const rules = [['profile', 'targetRegions']];
      if (targetDegree > 400) {
        rules.push(['profile', 'targetMajorCategory']);
      }
      const formData = await form.validateFields([...rules]);

      const targetDegreeName = otherFromList.targetDegree?.options?.find(o => targetDegree === o.id)?.name;

      const targetRegionsName = otherFromList.targetRegions?.options
        ?.filter(o => formData.profile?.targetRegions.indexOf(o.id) > -1)
        .slice(0, 3)
        .map(n => n.name)
        .join('/');

      const targetMajorCategoryName = otherFromList.targetMajorCategory?.options?.find(
        o => formData.profile?.targetMajorCategory === o.id
      )?.name;
      if (targetDegree > 400 && !targetMajorCategoryName) {
        message.error('要填写申请专业方向，才能生成标题');
        return;
      }
      const title = `${targetRegionsName} ${targetDegreeName} ${targetMajorCategoryName || ''}`;
      form.setFieldsValue({ title });
    } catch (error) {
      message.error(error?.errorFields?.map(err => err?.errors[0])?.join(' / ') || error?.message);
    }
  };

  goBack = () => {
    window.history.back();
  };

  onCancel = () => {
    this.goBack();
  };

  onSubmit = asyncLock(async (submit = false) => {
    try {
      const { match: { params: { id } } = {} } = this.props;
      const { ...result } = await this.form.current.validateFields();
      this.setState({
        spinning: true,
      });
      Object.keys(result).forEach(key => {
        result[key] ?? delete result[key];
        result[key]?.length === 0 && delete result[key];
      });
      Object.keys(result.profile).forEach(k => {
        result.profile[k] ?? delete result.profile[k];
        result.profile[k]?.length === 0 && delete result.profile[k];
      });

      result.submit = submit;

      if (result.images?.length > 0) {
        const images = [];
        for (const img of result.images) {
          if (img.url) {
            const { width, height } = await getImageSize(img.url);
            images.push({
              ...img,
              width,
              height,
            });
          }
        }
        result.images = images;
      }

      if (result.video?.url) {
        const { width, height, duration } = await getVideoSize(result.video.url);
        result.video = {
          ...result.video,
          width,
          height,
          duration: Math.round(duration),
        };
      } else {
        delete result.video;
      }

      if (result.results) {
        if (result.results.filter(item => item.accepted)?.length > 1) {
          throw new Error('所获结果 是否入读 只能选择一个入读');
        }
        const results = [];
        for (const item of result.results) {
          let offerImage = null;
          let obj = {};
          if (item.offerImage?.url) {
            const { width, height } = await getImageSize(item.offerImage?.url);
            offerImage = {
              url: item.offerImage?.url,
              width,
              height,
            };
          }
          if (result.profile.targetDegree > 400) {
            delete item.schoolName;
            obj = {
              ...item,
              offerImage,
              schoolId: item.schoolId,
            };
          } else {
            delete item.schoolId;
            delete item.majorId;
            delete item.programName;
            delete item.programId;
            obj = {
              ...item,
              offerImage,
            };
          }
          !obj.offerImage && delete obj.offerImage;
          results.push(obj);
        }
        result.results = results;
      }

      if (id !== 'create') {
        await editOffer(result);
        message.success('修改成功');
      } else {
        await createOffer(result);
        message.success('保存成功');
      }
      this.goBack();
    } catch (error) {
      this.setState({
        spinning: false,
      });
      const messageArray = error?.errorFields?.slice(0, 3) || [];
      const remainArray = error?.errorFields?.slice(3) || [];
      const text =
        messageArray.map(err => err?.errors?.[0])?.join(' / ') +
        (remainArray.length > 1 ? ` - 还有${remainArray.length}个字段必填` : '');
      message.error(text || error?.message, 3);
    }
  });

  getResultsProgramIds = results => {
    const programIds = results.map(item => item.programId);
    return programIds.length > 0 ? _.flattenDeep(results.map(item => item.programId)) : [];
  };

  // 根据majorOptions{schoolId1: [],schoolId2: []} 查找
  getMajorOptions = majorOptions => {
    let majorIdArray = [];
    Object.keys(majorOptions).forEach(key => {
      majorIdArray = [...majorIdArray, ...majorOptions[key]];
    });
    return majorIdArray;
  };

  getTargetMajors = (programIds, majorIdArray) => {
    let targetMajors = [];
    programIds.forEach(id => {
      const majorObj = majorIdArray?.find(item => item.id === id);
      if (majorObj) {
        targetMajors = [...targetMajors, ...majorObj.majorIds];
      }
    });
    return targetMajors;
  };

  setMajorIds = () => {
    const { majorOptions } = this.state;
    const results = this.form.current.getFieldValue('results');
    const programIds = this.getResultsProgramIds(results);
    const majorIdArray = this.getMajorOptions(majorOptions);
    const targetMajorsAuto = Array.from(new Set(this.getTargetMajors(programIds, majorIdArray)));
    this.form.current.setFieldsValue({
      targetMajorsAuto,
      targetMajorsManual: [],
    });
  };

  onFormChange = async changeFiled => {
    // 根据视频获取视频封面
    if (changeFiled[0]?.name?.[0] === 'video' && changeFiled[0]?.name?.[1] === 'url') {
      if (changeFiled[0]?.value) {
        const coverUrlData = await getVideoThumbnailUrl(changeFiled[0].value);
        const coverImageFile = await createFileFromDataURL(coverUrlData, 'coverUrl.jpeg');
        const coverUrl = await asyncUploadFile(coverImageFile, 'common-image')
          .then(res => res.uri)
          .catch(err => message.error(err || '封面上传'));
        this.form.current.setFieldsValue({
          video: {
            coverUrl,
          },
        });
      } else {
        this.form.current.setFieldsValue({
          video: {
            coverUrl: '',
          },
        });
      }
    }

    // 检查所获结果学校是否改变，如果改变且原来没有获取过，则获取对应学校major
    if (changeFiled[0]?.name?.[0] === 'results' && changeFiled[0]?.name?.[2] === 'schoolId') {
      if (this.state.schoolIds.indexOf(changeFiled[0].value) === -1) {
        const majorOption = await getMajorListOptions({ schoolId: changeFiled[0].value });
        this.setState({
          majorOptions: {
            ...this.state.majorOptions,
            [changeFiled[0].value]: majorOption,
          },
          schoolIds: Array.from(new Set([...this.state.schoolIds, changeFiled[0].value])),
        });
        const index = changeFiled[0]?.name?.[1];
        const results = this.form.current.getFieldValue('results');
        const values = results.map((item, i) => {
          if (index === i) {
            return {
              ...item,
              programId: null,
            };
          }
          return item;
        });
        this.form.current.setFieldsValue({
          results: values,
          targetMajorsAuto: [],
          targetMajorsManual: [],
        });
      }
    }

    // 关联项目改变，自动设置targetMajorAuto
    if (changeFiled[0]?.name?.[0] === 'results' && changeFiled[0]?.name?.[2] === 'programId') {
      this.setMajorIds();
    }
  };

  render() {
    const { match: { params: { id } } = {}, location = {} } = this.props;
    const query = querystring.parse(location.search?.substr(1));
    const { otherFromList, typeOptions, resultOptions, formList, majorOptions, cipOptions, spinning } = this.state;
    const disabled = query.type === 'disabled';
    return (
      <div className="container-case-info">
        <Spin spinning={spinning}>
          <Affix offsetTop={0}>
            <PageHeader
              className="header-box"
              title={`${id === 'create' ? '添加' : '编辑'}案例库`}
              extra={[
                <Space key="btn">
                  <Button disabled={disabled} onClick={this.onCancel}>
                    取消
                  </Button>
                  <Button disabled={disabled} onClick={() => this.onSubmit()}>
                    保存
                  </Button>
                  <Button disabled={disabled} onClick={() => this.onSubmit(true)}>
                    保存并提交审核
                  </Button>
                </Space>,
              ]}
            />
          </Affix>
          <div className="form-box">
            <Form disabled ref={this.form} layout="vertical" onFieldsChange={this.onFormChange}>
              <Form.Item noStyle name="id" />
              <Card title="基础信息" className="card-box">
                <Row gutter={12}>
                  <Col span={8}>
                    <Form.Item name="typeId" label="案例类型" rules={[{ required: true, message: '案例类型必填' }]}>
                      <Select disabled={disabled} options={optionTranslate(typeOptions)} placeholder="选择案例类型" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="planId" label="关联方案">
                      <SearchPlan disabled={disabled} placeholder="搜索关联方案" />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Card
                title={
                  <>
                    <h4>视频/图片</h4>
                    <h5 className="color-red6">
                      注：呈现在案例详情的顶部，至少添加视频或图片的其中一种，图片尽量是清晰大图，视频/图片质量高的案例，会被优先呈现
                    </h5>
                  </>
                }
                className="card-box"
              >
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item label="相关图片">
                      <Form.List name="images">
                        {(fields, { add }) => {
                          return (
                            <>
                              {fields.map(field => (
                                <Row justify="start" key={field.name}>
                                  <Col>
                                    <Form.Item name={[field.name, 'url']} fieldKey={[field.fieldKey, 'url']}>
                                      <UploadImage disabled={disabled} preset="common-image" max={1} />
                                    </Form.Item>
                                  </Col>
                                  <Col style={{ marginLeft: 10, flex: 1 }}>
                                    <Form.Item
                                      name={[field.name, 'description']}
                                      fieldKey={[field.fieldKey, 'description']}
                                    >
                                      <Input.TextArea
                                        allowClear
                                        disabled={disabled}
                                        style={{ height: 104 }}
                                        placeholder="请输入图片描述"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              ))}
                              <Space>
                                <Button
                                  type="dashed"
                                  disabled={disabled}
                                  onClick={() => add({})}
                                  style={{ placeSelf: 'stretch' }}
                                >
                                  添加图片
                                </Button>
                                <Button
                                  type="dashed"
                                  disabled={disabled}
                                  onClick={this.deleteEmptyImage}
                                  style={{ placeSelf: 'stretch' }}
                                >
                                  清除空图片
                                </Button>
                              </Space>
                            </>
                          );
                        }}
                      </Form.List>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item name={['video', 'url']} label="相关视频">
                          <FileUpload disabled={disabled} block accept="video/mp4" preset="common-video-mp4" max={1} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={['video', 'coverUrl']}
                          label="视频封面"
                          rules={[
                            ({ getFieldValue }) => ({
                              validator() {
                                if (getFieldValue(['video', 'url'])) {
                                  if (getFieldValue(['video', 'coverUrl'])) {
                                    return Promise.resolve();
                                  } else {
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise.reject('视频封面必填');
                                  }
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <UploadImage disabled={disabled} preset="common-image" max={1} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
              <Card title="申请意向" className="card-box">
                <Row gutter={12}>
                  <Col span={8}>
                    <Form.Item name={['profile', 'yearOfEntry']} label={otherFromList.yearOfEntry?.name}>
                      <Select
                        allowClear
                        placeholder="选择期望入学时间"
                        disabled={disabled}
                        options={optionTranslate(otherFromList.yearOfEntry?.options)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={['profile', 'yearOfApplication']}
                      label={otherFromList.yearOfApplication?.name}
                      rules={[{ required: true, message: '申请时间必填' }]}
                    >
                      <Select
                        allowClear
                        placeholder="选择申请时间"
                        disabled={disabled}
                        options={optionTranslate(otherFromList.yearOfApplication?.options)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) =>
                        prev.profile?.targetRegions?.length !== next.profile?.targetRegions?.length
                      }
                    >
                      {({ getFieldValue }) => {
                        const options = optionDisabled({
                          path: ['profile', 'targetRegions'],
                          getFieldValue,
                          options: otherFromList.targetRegions?.options,
                        });
                        return (
                          <Form.Item
                            name={['profile', 'targetRegions']}
                            label={otherFromList.targetRegions?.name}
                            rules={[{ required: true, message: '申请国家必填' }]}
                          >
                            <Checkbox.Group disabled={disabled} options={options} />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <CardItem data={formList.target} disabled={disabled} />
                </Row>
              </Card>
              <Card title="背景信息-语言考试成绩" className="card-box">
                <Row gutter={12}>
                  <CardItem data={formList.languageTest} disabled={disabled} />
                </Row>
              </Card>
              <Card title="背景信息-标准化考试成绩" className="card-box">
                <Row gutter={12}>
                  <CardItem data={formList.standardized} disabled={disabled} />
                </Row>
              </Card>
              <Card title="背景信息-综合成绩" className="card-box">
                <Row gutter={12}>
                  <CardItem data={formList.overallScore} disabled={disabled} />
                </Row>
              </Card>
              <Card title="背景信息-学历背景" className="card-box">
                <Row gutter={12}>
                  <CardItem data={formList.attendedBackground} disabled={disabled} />
                </Row>
              </Card>
              <Card title="背景信息-软实力背景" className="card-box">
                <Row gutter={12}>
                  <CardItem data={formList.powerBackground} disabled={disabled} />
                </Row>
              </Card>
              <Card title="所获结果（至少填写一个结果）" className="card-box">
                <FormListCard disabled={disabled} columns={1} name="results" text="结果">
                  {field => (
                    <>
                      <Row gutter={12}>
                        <Col span={8}>
                          <Form.Item
                            name={[field.name, 'result']}
                            fieldKey={[field.fieldKey, 'result']}
                            label="结果"
                            rules={[{ required: true, message: '结果必填' }]}
                          >
                            <Select
                              disabled={disabled}
                              options={optionTranslate(resultOptions)}
                              placeholder="选择结果"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="是否入读"
                            name={[field.name, 'accepted']}
                            fieldKey={[field.fieldKey, 'accepted']}
                          >
                            <Select
                              allowClear
                              options={[{ label: '是', value: true }, { label: '否', value: false }]}
                              placeholder="是否入读"
                              disabled={disabled}
                            />
                          </Form.Item>
                        </Col>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, next) => prev.profile?.targetDegree !== next.profile?.targetDegree}
                        >
                          {({ getFieldValue }) => {
                            const currentValue = getFieldValue(['profile', 'targetDegree']) || '';
                            return currentValue > 400 ? (
                              <Col span={8}>
                                <Form.Item
                                  name={[field.name, 'schoolId']}
                                  fieldKey={[field.fieldKey, 'schoolId']}
                                  label="学校"
                                  rules={[{ required: true, message: '学校必填' }]}
                                >
                                  <SearchSchool disabled={disabled} />
                                </Form.Item>
                              </Col>
                            ) : (
                              <Col span={8}>
                                <Form.Item
                                  name={[field.name, 'schoolName']}
                                  fieldKey={[field.fieldKey, 'schoolName']}
                                  label="学校"
                                  rules={[{ required: true, message: '学校必填' }]}
                                >
                                  <Input placeholder="填写学校名称" allowClear disabled={disabled} />
                                </Form.Item>
                              </Col>
                            );
                          }}
                        </Form.Item>
                      </Row>
                      <Row gutter={12}>
                        <Col span={8}>
                          <Form.Item
                            label="学位"
                            name={[field.name, 'degreeId']}
                            fieldKey={[field.fieldKey, 'degreeId']}
                            rules={[{ required: true, message: '学位必填' }]}
                          >
                            <Select
                              allowClear
                              options={optionTranslate(
                                otherFromList.targetDegree?.options?.filter(item => item.id !== 699)
                              )}
                              placeholder="选择学位"
                              disabled={disabled}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, next) =>
                              prev.results[field.name].schoolId !== next.results[field.name].schoolId ||
                              prev.profile?.targetDegree !== next.profile?.targetDegree
                            }
                          >
                            {({ getFieldValue }) => {
                              const targetDegree = getFieldValue(['profile', 'targetDegree']);
                              const schoolId = getFieldValue(['results', field.name, 'schoolId']);
                              if (targetDegree > 400) {
                                return (
                                  <Form.Item
                                    label="关联项目"
                                    name={[field.name, 'programId']}
                                    fieldKey={[field.fieldKey, 'programId']}
                                    rules={[makeMajorsRules(['results', field.name])]}
                                    dependencies={['results', field.name, 'programName']}
                                  >
                                    <Select
                                      allowClear
                                      showSearch
                                      options={optionTranslate(majorOptions[schoolId], 'id', 'chineseName')}
                                      filterOption={(input, option) => {
                                        const reg = makeFuzzySearchRegexp(input);
                                        return reg.test(option.label);
                                      }}
                                      placeholder="请选择项目"
                                      disabled={disabled}
                                    />
                                  </Form.Item>
                                );
                              } else {
                                return null;
                              }
                            }}
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, next) => prev.profile?.targetDegree !== next.profile?.targetDegree}
                          >
                            {({ getFieldValue }) => {
                              const targetDegree = getFieldValue(['profile', 'targetDegree']);
                              if (targetDegree > 400) {
                                return (
                                  <Form.Item
                                    label="填写项目"
                                    name={[field.name, 'programName']}
                                    fieldKey={[field.fieldKey, 'programName']}
                                    rules={[makeMajorsRules(['results', field.name])]}
                                    dependencies={['results', field.name, 'programId']}
                                  >
                                    <Input
                                      disabled={disabled}
                                      placeholder="如未搜到关联项目，请在此处自行填写项目名称"
                                    />
                                  </Form.Item>
                                );
                              } else {
                                return null;
                              }
                            }}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        <Col span={8}>
                          <Form.Item
                            label="奖学金说明/录取条件"
                            name={[field.name, 'description']}
                            fieldKey={[field.fieldKey, 'description']}
                          >
                            <Input.TextArea allowClear disabled={disabled} style={{ height: 109 }} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="offer证明"
                            name={[field.name, 'offerImage', 'url']}
                            fieldKey={[field.fieldKey, 'offerImage', 'url']}
                            required
                          >
                            <UploadImage disabled={disabled} preset="common-image" max={1} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                </FormListCard>
              </Card>
              <Card title="学生故事" className="card-box">
                <Form.Item name="story" required>
                  <RichText disabled={disabled} />
                </Form.Item>
              </Card>
              <Card title="老师的深度分析" className="card-box">
                <Form.Item name="analysis" required>
                  <RichText disabled={disabled} />
                </Form.Item>
              </Card>
              <Card className="card-box">
                <Row gutter={12}>
                  <Col span={8}>
                    <Form.Item label="案例标题" name="title" rules={[{ required: true }]}>
                      <Input allowClear disabled={disabled} placeholder="输入案例标题" />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ marginTop: 30 }}>
                    想不出好标题，<a onClick={this.getResultTitle}>机器生成一个</a>
                  </Col>
                </Row>
              </Card>
              <Card className="card-box">
                <Row gutter={12}>
                  <Col span={8}>
                    <Form.Item label="关联专业" name="targetMajorsAuto">
                      <TreeSelect
                        showSearch
                        allowClear
                        multiple
                        treeData={cipOptions}
                        treeDefaultExpandAll
                        filterTreeNode={(inputValue, treeNode) => {
                          const reg = makeFuzzySearchRegexp(inputValue);
                          return reg.test(treeNode.title);
                        }}
                        placeholder="自动获取关联专业"
                        disabled={disabled}
                      />
                    </Form.Item>
                  </Col>
                  <Col style={{ marginTop: 30 }}>
                    根据所获结果中的项目，<a onClick={this.setMajorIds}>匹配</a>到的相关专业
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Form.Item label="选择专业" name="targetMajorsManual">
                      <TreeSelect
                        showSearch
                        allowClear
                        multiple
                        treeData={cipOptions}
                        treeDefaultExpandAll
                        filterTreeNode={(inputValue, treeNode) => {
                          const reg = makeFuzzySearchRegexp(inputValue);
                          return reg.test(treeNode.title);
                        }}
                        placeholder="如无法自动匹配到相关专业，请在此处自行选择该案例相关的专业"
                        disabled={disabled}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Form>
          </div>
        </Spin>
      </div>
    );
  }
}

function CardItem({ data, disabled }) {
  return Array.isArray(data)
    ? data.map(item => {
        if (!item.name && !item.label) return null;
        const { condition } = item;
        if (typeof condition === 'object') {
          return (
            <Form.Item
              key={item.key}
              noStyle
              shouldUpdate={(prev, next) =>
                check({ ...prev, ...prev.profile }, condition) !== check({ ...next, ...next.profile }, condition)
              }
            >
              {({ getFieldsValue }) => {
                let data = getFieldsValue();
                data = {
                  ...data,
                  ...data.profile,
                };
                delete data.profile;
                return check(data, condition) ? (
                  <>
                    <Col span={8}>
                      {(() => {
                        switch (item.type) {
                          case 'text':
                            return (
                              <Form.Item name={['profile', item.key]} label={item.name} rules={item.rules}>
                                <Input allowClear disabled={disabled} placeholder={`输入${item.placeholder}`} />
                              </Form.Item>
                            );
                          case 'number':
                            return (
                              <Form.Item name={['profile', item.key]} label={item.name} rules={item.rules}>
                                <InputNumber
                                  style={{ width: '100%' }}
                                  allowClear
                                  disabled={disabled}
                                  placeholder={`输入${item.placeholder}`}
                                />
                              </Form.Item>
                            );
                          case 'select':
                            return item.multiple && item.options?.some(o => o.logic) ? (
                              <Form.Item
                                noStyle
                                shouldUpdate={(prev, next) =>
                                  prev.profile?.[item.key]?.length !== next.profile?.[item.key]?.length
                                }
                              >
                                {({ getFieldValue }) => {
                                  const options = optionDisabled({
                                    path: ['profile', item.key],
                                    getFieldValue,
                                    options: item.options,
                                  });
                                  return (
                                    <Form.Item name={['profile', item.key]} label={item.name} rules={item.rules}>
                                      <Select
                                        allowClear
                                        disabled={disabled}
                                        mode="multiple"
                                        filterOption={(value, option) => option?.label?.includes(value)}
                                        placeholder={`选择${item.placeholder}可多选`}
                                        options={options}
                                      />
                                    </Form.Item>
                                  );
                                }}
                              </Form.Item>
                            ) : (
                              <Form.Item name={['profile', item.key]} label={item.name} rules={item.rules}>
                                <Select
                                  allowClear
                                  disabled={disabled}
                                  mode={item.multiple ? 'multiple' : ''}
                                  filterOption={(value, option) => option?.label?.includes(value)}
                                  placeholder={`选择${item.placeholder}${item.multiple ? '（可多选）' : ''}`}
                                  options={optionTranslate(item.options)}
                                />
                              </Form.Item>
                            );
                          case 'major':
                            return (
                              <Form.Item name={['profile', item.key]} label={item.name} rules={item.rules}>
                                <SearchCip disabled={disabled} />
                              </Form.Item>
                            );
                          default:
                            return (
                              <Form.Item name={['profile', item.key]} label={item.name} rules={item.rules}>
                                <Alert type="warning" message={`不支持的字段类型：${item.type}`} />
                              </Form.Item>
                            );
                        }
                      })()}
                    </Col>
                  </>
                ) : null;
              }}
            </Form.Item>
          );
        }
        return (
          <Col key={item.key} span={8}>
            {(() => {
              switch (item.type) {
                case 'text':
                  return (
                    <Form.Item name={['profile', item.key]} label={item.name} rules={item.rules}>
                      <Input allowClear disabled={disabled} placeholder={`输入${item.placeholder}`} />
                    </Form.Item>
                  );
                case 'number':
                  return (
                    <Form.Item name={['profile', item.key]} label={item.name} rules={item.rules}>
                      <InputNumber
                        style={{ width: '100%' }}
                        allowClear
                        disabled={disabled}
                        placeholder={`输入${item.placeholder}`}
                      />
                    </Form.Item>
                  );
                case 'select':
                  return item.multiple && item.options?.some(o => o.logic) ? (
                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) =>
                        prev.profile?.[item.key]?.length !== next.profile?.[item.key]?.length
                      }
                    >
                      {({ getFieldValue }) => {
                        const options = optionDisabled({
                          path: ['profile', item.key],
                          getFieldValue,
                          options: item.options,
                        });
                        return (
                          <Form.Item name={['profile', item.key]} label={item.name} rules={item.rules}>
                            <Select
                              allowClear
                              disabled={disabled}
                              mode="multiple"
                              filterOption={(value, option) => option?.label?.includes(value)}
                              placeholder={`选择${item.placeholder}可多选`}
                              options={options}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  ) : (
                    <Form.Item name={['profile', item.key]} label={item.name} rules={item.rules}>
                      <Select
                        allowClear
                        disabled={disabled}
                        mode={item.multiple ? 'multiple' : ''}
                        filterOption={(value, option) => option?.label?.includes(value)}
                        placeholder={`选择${item.placeholder}${item.multiple ? '（可多选）' : ''}`}
                        options={optionTranslate(item.options)}
                      />
                    </Form.Item>
                  );
                case 'major':
                  return (
                    <Form.Item name={['profile', item.key]} label={item.name} rules={item.rules}>
                      <SearchCip disabled={disabled} />
                    </Form.Item>
                  );
                default:
                  return (
                    <Form.Item name={['profile', item.key]} label={item.name} rules={item.rules}>
                      <Alert type="warning" message={`不支持的字段类型：${item.type}`} />
                    </Form.Item>
                  );
              }
            })()}
          </Col>
        );
      })
    : null;
}

function getFormListOption(file) {
  const formList = {
    // 申请意向
    target: [
      {
        // 申请阶段
        ...file.targetDegree,
        placeholder: file.targetDegree.name,
        rules: [
          {
            required: file.targetDegree.required,
            message: `${file.targetDegree.name}必填`,
          },
        ],
      },
      {
        // 申请专业方向
        ...file.targetMajorCategory,
        placeholder: file.targetMajorCategory.name,
        rules: [{ required: file.targetMajorCategory.required, message: `${file.targetMajorCategory.name}必填` }],
      },
      {
        // 期望学校范围
        ...file.targetSchoolRange,
        placeholder: file.targetSchoolRange.name,
        rules: [{ required: file.targetSchoolRange.required, message: `${file.targetSchoolRange.name}必填` }],
      },
    ],
    // 语言考试成绩
    languageTest: [
      {
        name: '',
        label: '',
      },
      {
        // 托福TOEFL
        ...file.toefl,
        placeholder: file.toefl.name,
        rules: [{ required: file.toefl.required, message: `${file.toefl.name}必填` }],
      },
      {
        // 雅思IELTS
        ...file.ielts,
        placeholder: file.ielts.name,
        rules: [{ required: file.ielts.required, message: `${file.ielts.name}必填` }],
      },
      {
        // 多邻国
        ...file.det,
        placeholder: file.det.name,
        rules: [{ required: file.det.required, message: `${file.det.name}必填` }],
      },
      {
        // 小托福
        ...file.toeflJunior,
        placeholder: file.toeflJunior.name,
        rules: [{ required: file.toeflJunior.required, message: `${file.toeflJunior.name}必填` }],
      },
      {
        // 剑桥KET
        ...file.ket,
        placeholder: file.ket.name,
        rules: [{ required: file.ket.required, message: `${file.ket.name}必填` }],
      },
      {
        // 剑桥PET
        ...file.pet,
        placeholder: file.pet.name,
        rules: [{ required: file.pet.required, message: `${file.pet.name}必填` }],
      },
      {
        // 日语JLPT
        ...file.jlpt,
        placeholder: file.jlpt.name,
        rules: [{ required: file.jlpt.required, message: `${file.jlpt.name}必填` }],
      },
      {
        // 韩语TOPIK
        ...file.topik,
        placeholder: file.topik.name,
        rules: [{ required: file.topik.required, message: `${file.topik.name}必填` }],
      },
      {
        // 德福TestDaF
        ...file.testdaf,
        placeholder: file.testdaf.name,
        rules: [{ required: file.testdaf.required, message: `${file.testdaf.name}必填` }],
      },
      {
        // 语言成绩豁免
        ...file.languageWaiver,
        placeholder: file.languageWaiver.name,
        rules: [{ required: file.languageWaiver.required, message: `${file.languageWaiver.name}必填` }],
      },
      {
        // 其他语言成绩
        ...file.languageOther,
        placeholder: file.languageOther.name,
        rules: [{ required: file.languageOther.required, message: `${file.languageOther.name}必填` }],
      },
    ],
    // 背景信息-标准化考试成绩
    standardized: [
      // {
      //   // 标准化考试成绩
      //   ...file.standardized,
      //   placeholder: file.standardized.name,
      //   rules: [{ required: file.standardized.required, message: `${file.standardized.name}必填` }],
      // },
      {
        // SSAT
        ...file.ssat,
        placeholder: file.ssat.name,
        rules: [{ required: file.ssat.required, message: `${file.ssat.name}必填` }],
      },
      {
        // SAT
        ...file.sat,
        placeholder: file.sat.name,
        rules: [{ required: file.sat.required, message: `${file.sat.name}必填` }],
      },
      {
        // SAT2
        ...file.sat2,
        placeholder: file.sat2.name,
        rules: [{ required: file.sat2.required, message: `${file.sat2.name}必填` }],
      },
      {
        // ACT
        ...file.act,
        placeholder: file.act.name,
        rules: [{ required: file.act.required, message: `${file.act.name}必填` }],
      },
      {
        // GRE
        ...file.gre,
        placeholder: file.gre.name,
        rules: [{ required: file.gre.required, message: `${file.gre.name}必填` }],
      },
      {
        // GRESUB
        ...file.gresub,
        placeholder: file.gresub.name,
        rules: [{ required: file.gresub.required, message: `${file.gresub.name}必填` }],
      },
      {
        // GMAT
        ...file.gmat,
        placeholder: file.gmat.name,
        rules: [{ required: file.gmat.required, message: `${file.gmat.name}必填` }],
      },
      {
        // 其他标化成绩
        ...file.standardizedOther,
        placeholder: file.standardizedOther.name,
        rules: [{ required: file.standardizedOther.required, message: `${file.standardizedOther.name}必填` }],
      },
    ],
    // 背景信息-综合成绩
    overallScore: [
      {
        // GPA
        ...file.gpa,
        placeholder: file.gpa.name,
        rules: [{ required: file.gpa.required, message: `${file.gpa.name}必填` }],
      },
      {
        // 百分制成绩
        ...file.score,
        placeholder: file.score.name,
        rules: [{ required: file.score.required, message: `${file.score.name}必填` }],
      },
      {
        // IB
        ...file.ib,
        placeholder: file.ib.name,
        rules: [{ required: file.ib.required, message: `${file.ib.name}必填` }],
      },
      {
        // 等级制成绩
        ...file.level,
        placeholder: file.level.name,
        rules: [{ required: file.level.required, message: `${file.level.name}必填` }],
      },
      {
        // AP
        ...file.ap,
        placeholder: file.ap.name,
        rules: [{ required: file.ap.required, message: `${file.ap.name}必填` }],
      },
      {
        // A-Level
        ...file.alevel,
        placeholder: file.alevel.name,
        rules: [{ required: file.alevel.required, message: `${file.alevel.name}必填` }],
      },
    ],
    // 背景信息-学历背景
    attendedBackground: [
      {
        // 当前状态
        ...file.status,
        placeholder: file.status.name,
        rules: [{ required: file.status.required, message: `${file.status.name}必填` }],
        label: '当前状态',
      },
      {
        name: '',
        label: '',
      },
      {
        // 高中学校类型
        ...file.attendedSchoolTypeSH,
        placeholder: file.attendedSchoolTypeSH.name,
        rules: [{ required: file.attendedSchoolTypeSH.required, message: `${file.attendedSchoolTypeSH.name}必填` }],
      },
      {
        // 高中学校名称
        ...file.attendedSchoolNameSH,
        placeholder: file.attendedSchoolNameSH.name,
        rules: [{ required: file.attendedSchoolNameSH.required, message: `${file.attendedSchoolNameSH.name}必填` }],
      },
      {
        // 本科学校类型
        ...file.attendedSchoolTypeUG,
        placeholder: file.attendedSchoolTypeUG.name,
        rules: [{ required: file.attendedSchoolTypeUG.required, message: `${file.attendedSchoolTypeUG.name}必填` }],
      },
      {
        // 本科学校名称
        ...file.attendedSchoolNameUG,
        placeholder: file.attendedSchoolNameUG.name,
        rules: [{ required: file.attendedSchoolNameUG.required, message: `${file.attendedSchoolNameUG.name}必填` }],
      },
      {
        // 本科毕业专业
        ...file.attendedMajorUG,
        placeholder: file.attendedMajorUG.name,
        rules: [{ required: file.attendedMajorUG.required, message: `${file.attendedMajorUG.name}必填` }],
      },
      {
        // 本科辅修专业
        ...file.attendedMinorUG,
        placeholder: file.attendedMinorUG.name,
        rules: [{ required: file.attendedMinorUG.required, message: `${file.attendedMinorUG.name}必填` }],
      },
      {
        // 硕士学校类型
        ...file.attendedSchoolTypePG,
        placeholder: file.attendedSchoolTypePG.name,
        rules: [{ required: file.attendedSchoolTypePG.required, message: `${file.attendedSchoolTypePG.name}必填` }],
      },
      {
        // 硕士学校名称
        ...file.attendedSchoolNamePG,
        placeholder: file.attendedSchoolNamePG.name,
        rules: [{ required: file.attendedSchoolNamePG.required, message: `${file.attendedSchoolNamePG.name}必填` }],
      },
      {
        // 硕士毕业专业
        ...file.attendedMajorPG,
        placeholder: file.attendedMajorPG.name,
        rules: [{ required: file.attendedMajorPG.required, message: `${file.attendedMajorPG.name}必填` }],
      },
      {
        // 当前就读年级
        ...file.attendedGrade,
        placeholder: file.attendedGrade.name,
        rules: [{ required: file.attendedGrade.required, message: `${file.attendedGrade.name}必填` }],
      },
      {
        name: '',
        label: '',
      },
      {
        // 其他学历
        ...file.attendedOther,
        placeholder: file.attendedOther.name,
        rules: [{ required: file.attendedOther.required, message: `${file.attendedOther.name}必填` }],
      },
    ],
    // 背景信息-软实力背景
    powerBackground: [
      {
        // 论文发表
        ...file.essays,
        placeholder: file.essays.name,
        rules: [{ required: file.essays.required, message: `${file.essays.name}必填` }],
      },
      {
        // 科研经历
        ...file.researches,
        placeholder: file.researches.name,
        rules: [{ required: file.researches.required, message: `${file.researches.name}必填` }],
      },
      {
        // 软件技能
        ...file.softwareSkills,
        placeholder: file.softwareSkills.name,
        rules: [{ required: file.softwareSkills.required, message: `${file.softwareSkills.name}必填` }],
      },
      {
        // 社会活动
        ...file.socialActivities,
        placeholder: file.socialActivities.name,
        rules: [{ required: file.socialActivities.required, message: `${file.socialActivities.name}必填` }],
      },
      {
        // 学校活动
        ...file.schoolActivities,
        placeholder: file.schoolActivities.name,
        rules: [{ required: file.schoolActivities.required, message: `${file.schoolActivities.name}必填` }],
      },
      {
        // 工作经历
        ...file.workExperience,
        placeholder: file.workExperience.name,
        rules: [{ required: file.workExperience.required, message: `${file.workExperience.name}必填` }],
      },
      {
        // 获得奖项
        ...file.awards,
        placeholder: file.awards.name,
        rules: [{ required: file.awards.required, message: `${file.awards.name}必填` }],
      },
      {
        // 实习经历
        ...file.internship,
        placeholder: file.internship.name,
        rules: [{ required: file.internship.required, message: `${file.internship.name}必填` }],
      },
      {
        // 国际交流
        ...file.intlExchanges,
        placeholder: file.intlExchanges.name,
        rules: [{ required: file.intlExchanges.required, message: `${file.intlExchanges.name}必填` }],
      },
      {
        // 艺术作品集
        ...file.artworks,
        placeholder: file.artworks.name,
        rules: [{ required: file.artworks.required, message: `${file.artworks.name}必填` }],
      },
      {
        // 推荐信
        ...file.recommendationLetters,
        placeholder: file.recommendationLetters.name,
        rules: [{ required: file.recommendationLetters.required, message: `${file.recommendationLetters.name}必填` }],
      },
    ],
  };
  return formList;
}
