import axios from '../common/axios';

/** 获取故事列表 */
export const getStories = page => axios.get('/thirdparty/consultant/profile/stories', { page });

/** 获取单个故事详情 */
export const getStory = id => axios.get('/thirdparty/consultant/profile/story', { id });

/** 创建一个新的故事 */
export const createStory = ({ submitForReview, coverUrl, title, content, typeId, schoolIds }) =>
  axios.post('/thirdparty/consultant/story', {
    submitForReview,
    coverUrl,
    title,
    content,
    typeId,
    schoolIds,
  });

/** 更新一个故事 */
export const updateStory = ({ id, submitForReview, coverUrl, title, content, typeId, schoolIds }) =>
  axios.put('/thirdparty/consultant/story', {
    id,
    submitForReview,
    coverUrl,
    title,
    content,
    typeId,
    schoolIds,
  });

/** 删除一个故事 */
export const deleteStory = id => axios.delete(`/thirdparty/consultant/index?id=${id}`);

/** 获取案例故事选项数据 */
export const getOptions = () => axios.get('/thirdparty/consultant/story/options');
