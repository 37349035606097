import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button, Tag, Row, Col } from 'antd';
import { getStory } from '../../services/stories';
import constant from '../../common/constant';
import IconText from '../../component/icon-text/icon-text';
import RichtextPreview from '../../component/richtext-preview/richtext-preview';
import './story.less';

export default class Story extends React.Component {
  state = { story: null };
  async componentWillMount() {
    await this.getStoryInfo();
  }
  async getStoryInfo() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState({ story: await getStory(id) });
  }
  render() {
    const { story: _story } = this.state;
    const story = _story || {};
    return (
      <div className="story-container">
        <article>
          <h1>
            <span>
              <Tag color="#3388ff">{story.typeName || ''}</Tag>
              {story.title}
            </span>
            {story.status === constant.status.CREATED ||
            story.status === constant.status.REJECTED ||
            story.status === constant.status.NORMAL ? (
              <Button type="primary">
                <Link replace to={`/stories/edit/${story.id}`}>
                  编辑文章
                </Link>
              </Button>
            ) : null}
          </h1>
          <div className={`status ${constant.statusString[story.status]}`}>
            {story.reviewTime ? `${dayjs(story.reviewTime).format('YYYY年MM月DD日 HH:mm:ss')} ` : ''}
            {constant.statusText[story.status]}
            {story.reviewRejectReason ? `，原因及修改建议：${story.reviewRejectReason}` : ''}
          </div>
          {story.schools && story.schools.length > 0 ? (
            <Row style={{ marginTop: 20 }}>
              <Col span={4}>相关院校：</Col>
              <Col span={20}>{story.schools.map(s => s.chineseName).join('、')}</Col>
            </Row>
          ) : (
            ''
          )}
          {story.coverUrl ? (
            <Row style={{ marginTop: 20 }}>
              <Col span={4}>封面：</Col>
              <Col span={20}>
                <img className="cover-img" src={story.coverUrl} alt="cover" />
              </Col>
            </Row>
          ) : (
            ''
          )}
          <div className="stat-box">
            <IconText type="eye-o" text={`浏览 ${story.visitCount}`} />
          </div>
          <RichtextPreview content={story.content} />
        </article>
      </div>
    );
  }
}
