import axios from './axios';
import { Base64 } from 'js-base64';
import _ from 'lodash';

// 获取视频宽高和时长
export const getVideoSize = videoUrl => {
  const video = document.createElement('video');
  return new Promise((resolve, reject) => {
    video.crossOrigin = 'anonymous'; // 处理跨域
    video.src = videoUrl;
    video.autoplay = true; // 避免出现第一帧是黑色

    video.addEventListener('loadedmetadata', () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration,
      });
    });

    video.onerror = () => reject(new Error('视频尺寸读取失败'));
  }).finally(() => {
    video.pause();
    video.src = '';
    video.load();
  });
};

/**
 * 获取图片宽高
 * @param {string} imageUrl
 */
export const getImageSize = async imageUrl => {
  const image = await getLoadedImage(imageUrl);
  return {
    width: image.width,
    height: image.height,
  };
};

export const getLoadedImage = async url => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = function onload() {
      resolve(image);
    };
    image.onerror = () => {
      reject(new Error(`Cannot load image: ${url}`));
    };
    image.src = url;
  });
};

export const createFileFromDataURL = async (dataURL, filename = 'temp.jpeg') => {
  const mime = /data:([^;]+);/.exec(dataURL)[1];
  const data = Uint8Array.from(
    Base64.atob(dataURL.split(/[;,]/).pop())
      .split('')
      .map(char => char.charCodeAt(0))
  );
  if (!mime) {
    throw new Error('文件类型提取错误');
  }
  return new File([data], filename, { type: mime });
};

/**
 * 上传文件（Promise风格）
 * @param {File} file
 * @param {string} preset
 */
export const asyncUploadFile = (file, preset) => {
  const fileType = file.type;
  if (!fileType) {
    return Promise.reject(new Error('文件对象不正确'));
  }
  return axios.post(`/upload/ufile`, { file, preset }, { file: true });
};

// 获取视频第一帧
export const getVideoThumbnailUrl = (videoUrl, time = 0) => {
  const video = document.createElement('video');
  return new Promise((resolve, reject) => {
    let dataURL = '';
    video.crossOrigin = 'anonymous'; // 处理跨域
    video.src = videoUrl;
    video.autoplay = true; // 避免出现第一帧是黑色
    video.currentTime = time;

    video.addEventListener('loadeddata', () => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth; // canvas的尺寸和图片一样
      canvas.height = video.videoHeight;
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height); // 绘制canvas
      video.pause();
      dataURL = canvas.toDataURL('image/jpeg'); // 转换为base64
      resolve(dataURL);
    });

    video.onerror = () => reject(new Error('视频截帧失败'));
  }).finally(() => {
    video.pause();
    video.src = '';
    video.load();
  });
};

/**
 * 构造模糊查询正则，例如：'南大' => /南.*大/
 * @param {string} keyword
 * @return {RegExp}
 */
export const makeFuzzySearchRegexp = keyword => {
  if (typeof keyword !== 'string') {
    return null;
  }
  const matches = keyword.match(/\w+|[\u4e00-\u9fa5]|[^\w\s\u4e00-\u9fa5]+/g);
  if (!matches) {
    return null;
  }
  return new RegExp(`${matches.map(text => _.escapeRegExp(text)).join('.*')}`);
};
