import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Divider,
  Empty,
  Input,
  message,
  Modal,
  Popconfirm,
  Radio,
  Rate,
  Row,
  Spin,
  Table,
  Tabs,
  Tooltip,
  Upload,
} from 'antd';
import moment from 'moment';
import { deleteContract, getSubscribe, updateNote, updateStatus, uploadContract } from '../../services/subscribe';
import { formatUserBackgroundAcademic, formatUserBackgroundLanguage, formatPlatform } from '../../common/utils';
import constant from '../../common/constant';

import './subscribe.less';

export default class Subscribe extends React.Component {
  state = {
    subscribe: {},
    loading: false,
    isShowStatusEditModal: false,
    status: null,
    statusNote: '',
    contactNote: '',
    hasChangedContactNote: false,
    isUpdatingContactNote: false,
    isUploadingContract: false,
  };

  async componentWillMount() {
    await this.getSubscribe();
  }

  // 获取预约详情
  async getSubscribe() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const subscribe = (await getSubscribe(id)) || {};
    this.setState({
      subscribe,
      status: subscribe.status,
      statusNote: subscribe.statusNote,
      contactNote: subscribe.contactNote,
    });
  }

  // 打开编辑跟进情况弹窗
  showStatusEditModal = () => {
    this.setState({ isShowStatusEditModal: true });
  };
  // 取消编辑跟进情况
  cancelStatusEditModal = () => {
    const { subscribe } = this.state;
    this.setState({
      isShowStatusEditModal: false,
      status: subscribe.status,
      statusNote: subscribe.statusNote,
    });
  };
  // 处理状态变更 Radio
  handleStatusChange = e => {
    this.setState({ status: e.target.value, statusNote: '' });
  };
  // 更新跟进情况
  editStatus = () => {
    const { status, statusNote } = this.state;
    if (
      (status === constant.appointmentStatus.CANNOT_CONTACT || status === constant.appointmentStatus.CANNOT_SIGN) &&
      !statusNote
    ) {
      message.error('请简要备注原因');
      return;
    }

    this.setState({ loading: true }, async () => {
      try {
        const { status, statusNote, subscribe } = this.state;
        await updateStatus({ id: subscribe.id, status, statusNote });
        this.cancelStatusEditModal();
        await this.getSubscribe();
      } catch (e) {
        message.error(e.message);
      }
      this.setState({ loading: false });
    });
  };
  // 处理编辑沟通备忘录
  handleChangeContactNote = e => {
    this.setState({ contactNote: e.target.value, hasChangedContactNote: true });
  };
  // 保存备忘录
  submitContactNote = () => {
    const { subscribe, contactNote } = this.state;
    this.setState({ isUpdatingContactNote: true }, async () => {
      try {
        await updateNote(subscribe.id, contactNote || '');
        await this.getSubscribe();
        this.setState({
          hasChangedContactNote: false,
        });
        message.success('已更新沟通备忘录');
      } catch (e) {
        message.error(e.message);
      } finally {
        this.setState({ isUpdatingContactNote: false });
      }
    });
  };

  // 处理上传进度
  handleChange(info) {
    if (info.file.status === 'uploading') {
      this.setState({ isUploadingContract: true });
      return;
    }
    if (info.file.status === 'done') {
      this.setState({ isUploadingContract: false });
    }
  }

  // 删除合同
  async deleteContract(contract) {
    try {
      await deleteContract(contract.id);
      await this.getSubscribe();
    } catch (e) {
      message.error(e.message);
    }
  }

  // 状态不是签约的情况下，提醒先去修改跟进状态
  showUploadContractTip = () => {
    const { subscribe } = this.state;
    const isSigned = subscribe.status === constant.appointmentStatus.SIGNED;
    if (!isSigned) {
      Modal.warning({
        title: '请先修改跟进情况',
        content: '若与该用户已成交，请先修改【跟进状态】为【已成交】后再上传合同。',
      });
    }
  };

  render() {
    const {
      subscribe,
      loading,
      isShowStatusEditModal,
      status,
      contactNote,
      hasChangedContactNote,
      isUpdatingContactNote,
      isUploadingContract,
    } = this.state;
    const { userBackground = {}, userData = {}, contracts } = subscribe;
    // 已签约
    const isSigned = subscribe.status === constant.appointmentStatus.SIGNED;
    // 状态选择列表
    const radioOptions = [
      {
        status: constant.appointmentStatus.WAITING,
        name: constant.appointmentStatusText[constant.appointmentStatus.WAITING],
      },
      {
        status: constant.appointmentStatus.CONTACTED,
        name: constant.appointmentStatusText[constant.appointmentStatus.CONTACTED],
      },
      {
        status: constant.appointmentStatus.VISITED,
        name: constant.appointmentStatusText[constant.appointmentStatus.VISITED],
      },
      {
        status: constant.appointmentStatus.SIGNED,
        name: constant.appointmentStatusText[constant.appointmentStatus.SIGNED],
      },
      {
        status: constant.appointmentStatus.CANNOT_CONTACT,
        name: constant.appointmentStatusText[constant.appointmentStatus.CANNOT_CONTACT],
      },
      {
        status: constant.appointmentStatus.CANNOT_SIGN,
        name: '无法成交',
      },
    ];
    // 上传合同配置
    const props = {
      accept: constant.contractAccept,
      name: 'file',
      multiple: true,
      showUploadList: false,
      onChange: this.handleChange.bind(this),
      beforeUpload: () => {
        // 已签约，才给上传合同
        return isSigned;
      },
      customRequest: options => {
        uploadContract({
          appointmentId: subscribe.id,
          file: options.file,
        })
          .then(
            async () => {
              message.success('上传成功');
              await this.getSubscribe();
            },
            error => {
              message.error(error.message);
            }
          )
          .finally(() => {
            this.setState({ isUploadingContract: false });
          });
      },
    };
    // 合同列表项配置
    const contractColumns = [
      {
        title: '文件名称',
        dataIndex: 'filename',
        key: 'filename',
        render: (t, r) => (
          <a href={r.url} download={encodeURIComponent(r.filename)} target="_blank" rel="noopener noreferrer">
            {t}
          </a>
        ),
      },
      {
        title: '上传时间',
        dataIndex: 'createTime',
        key: 'createTime',
        render: t => moment(t.createTime).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '审核状态',
        dataIndex: 'status',
        key: 'status',
        render: (t, r) => (
          <span className={`status-item ${constant.contractStatusString[t]}`}>
            {`${constant.contractStatusText[t]}${r.reviewRejectReason ? `，原因：${r.reviewRejectReason}` : ''}`}
          </span>
        ),
      },
      {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        render: (t, r) =>
          r.status !== constant.contractStatus.NORMAL ? (
            <Popconfirm title="确认删除吗？" onConfirm={this.deleteContract.bind(this, r)}>
              <span className="btn">删除</span>
            </Popconfirm>
          ) : (
            <span className="disabled">删除</span>
          ),
      },
    ];
    return (
      <div className="subscribe-container">
        <div className="info-box">
          <div className="top-pane">
            <span>
              跟进情况：
              {subscribe.statusNote ? (
                <Tooltip title={`原因：${subscribe.statusNote}`}>
                  {constant.appointmentStatusText[subscribe.status]}
                </Tooltip>
              ) : (
                <span>{constant.appointmentStatusText[subscribe.status]}</span>
              )}
            </span>
            <EditOutlined onClick={this.showStatusEditModal} />
            <Modal
              destroyOnClose
              className="subscribe-status-edit-modal"
              confirmLoading={loading}
              visible={isShowStatusEditModal}
              title="更新跟进情况"
              onCancel={this.cancelStatusEditModal}
              onOk={this.editStatus}
            >
              <Radio.Group onChange={this.handleStatusChange} value={status}>
                {radioOptions.map(({ status: s, name }) => (
                  <div key={`${s}_${name}`}>
                    <Radio className="block" value={s}>
                      {name}
                    </Radio>
                    {(s === constant.appointmentStatus.CANNOT_CONTACT ||
                      s === constant.appointmentStatus.CANNOT_SIGN) &&
                    status === s ? (
                      <div className="note-box">
                        <span className="required-star">*</span>
                        <Input
                          placeholder="请简要备注原因"
                          value={this.state.statusNote}
                          onChange={e =>
                            this.setState({
                              statusNote: e.target.value,
                            })
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                ))}
              </Radio.Group>
              <Alert
                showIcon
                type="info"
                message="注：若学生还未评价，则标为已联系、已上门或已签约时，系统将邀请学生评价服务的质量，学生的评价会显示在您在学生端的导师主页上。"
              />
            </Modal>
          </div>
          <Alert showIcon className="status-tip" message="跟进情况和沟通备忘录仅对自己可见，其他老师不可见" />
          <Row gutter={32}>
            <Col span={12}>
              <h3>预约信息</h3>
              <table>
                <tbody>
                  <tr>
                    <td>学生/家长姓名</td>
                    <td>{subscribe.contactName}</td>
                  </tr>
                  <tr>
                    <td>学生/家长电话</td>
                    <td className="important-info">{subscribe.contactPhone}</td>
                  </tr>
                  <tr>
                    <td>所在城市</td>
                    <td>{subscribe.location}</td>
                  </tr>
                  <tr>
                    <td>预约时间</td>
                    <td className="important-info">{subscribe.appointmentTimeText}</td>
                  </tr>
                  <tr>
                    <td>用户问题</td>
                    <td>{subscribe.question}</td>
                  </tr>
                  <tr>
                    <td>用户问题标签</td>
                    <td>{(subscribe.questionTags || []).join(' + ')}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col span={12}>
              <h3>用户信息</h3>
              <table>
                <tbody>
                  <tr>
                    <td>目标学习地区</td>
                    <td>{(userBackground.targetRegions || []).join('，')}</td>
                  </tr>
                  <tr>
                    <td>准备申请学位</td>
                    <td>{userBackground.targetDegreeName}</td>
                  </tr>
                  <tr>
                    <td>当前就读学校</td>
                    <td>{userBackground.currentSchool}</td>
                  </tr>
                  <tr>
                    <td>当前就读年级</td>
                    <td>{userBackground.currentGradeName}</td>
                  </tr>
                  <tr>
                    <td>语言成绩</td>
                    <td>{formatUserBackgroundLanguage(userBackground)}</td>
                  </tr>
                  <tr>
                    <td>学术成绩</td>
                    <td>{formatUserBackgroundAcademic(userBackground)}</td>
                  </tr>
                  <tr>
                    <td>学习成绩</td>
                    <td>{userBackground.scoreInSchool}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          {subscribe.review ? (
            <>
              <Divider />
              <div className="review-box">
                <h3>用户评价</h3>
                <Rate disabled defaultValue={subscribe.review.score || 0} />
                <div className="review-content">{subscribe.review.content}</div>
              </div>
            </>
          ) : null}
        </div>
        <Tabs className="tabs">
          <Tabs.TabPane tab="用户画像" key="1">
            <UserData userData={userData} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="沟通备忘录" key="2">
            <div className="contract-box">
              <Input.TextArea
                autosize={{ minRows: 4 }}
                value={contactNote}
                onChange={this.handleChangeContactNote}
                placeholder="好记性不如烂笔头，写个备忘录吧～"
              />
              <Button
                loading={isUpdatingContactNote}
                disabled={!hasChangedContactNote}
                onClick={this.submitContactNote}
                type="primary"
              >
                更新备忘录
              </Button>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="合同" key="3">
            <Alert
              showIcon
              type="info"
              message="学生成交后请上传合同，合同审核通过后，将关闭该学生预约其他老师的通道，从而防止客户跳单、撞单。"
            />
            <div className="upload-contract-box">
              {isSigned ? (
                <Spin spinning={isUploadingContract}>
                  <Upload {...props}>
                    <Button type="primary">点击上传</Button>
                  </Upload>
                </Spin>
              ) : (
                <Button type="primary" onClick={this.showUploadContractTip}>
                  点击上传
                </Button>
              )}
              <span className="upload-text">
                请上传 pdf/doc 格式的文档，或 jpg/png 格式的图片，若同一份合同下有多个文件，请进行统一命名。
              </span>
            </div>
            <Table
              rowKey="id"
              loading={loading}
              columns={contractColumns}
              dataSource={contracts || []}
              locale={{
                emptyText: <Empty description="暂无合同" />,
              }}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

// 用户画像
function UserData({ userData }) {
  const { selectedSchools = {}, questions, collections, tests } = userData;
  const { MS_MATCH_TYPE_MIDDLE, MS_MATCH_TYPE_TOP, MS_MATCH_TYPE_BOTTOM } = selectedSchools;

  function renderSchoolItem(schools) {
    return (schools || []).map(school => (
      <a key={school.id} className="link" href={school.link} target="_blank" rel="noopener noreferrer">
        {school.chineseName}
      </a>
    ));
  }

  return (
    <div className="user-data-box">
      <h3>注册信息</h3>
      <table>
        <tbody>
          <tr>
            <td>用户名：{userData.name}</td>
            <td>用户类型：{userData.customerRoleName}</td>
            <td>注册手机号：{userData.phone}</td>
          </tr>
          <tr>
            <td>来源：{userData.sourceAction}</td>
            <td>设备：{formatPlatform(userData.platform)}</td>
            <td>地理位置：{userData.location}</td>
          </tr>
        </tbody>
      </table>
      {(MS_MATCH_TYPE_TOP && MS_MATCH_TYPE_TOP.length > 0) ||
      (MS_MATCH_TYPE_MIDDLE && MS_MATCH_TYPE_MIDDLE.length > 0) ||
      (MS_MATCH_TYPE_BOTTOM && MS_MATCH_TYPE_BOTTOM.length > 0) ? (
        <>
          <Divider />
          <h3>标记的目标院校</h3>
        </>
      ) : null}
      {MS_MATCH_TYPE_TOP && MS_MATCH_TYPE_TOP.length > 0 ? (
        <div className="row">
          <div className="col">冲刺院校：</div>
          <div className="col">{renderSchoolItem(MS_MATCH_TYPE_TOP)}</div>
        </div>
      ) : null}
      {MS_MATCH_TYPE_MIDDLE && MS_MATCH_TYPE_MIDDLE.length > 0 ? (
        <div className="row">
          <div className="col">核心院校：</div>
          <div className="col">{renderSchoolItem(MS_MATCH_TYPE_MIDDLE)}</div>
        </div>
      ) : null}
      {MS_MATCH_TYPE_BOTTOM && MS_MATCH_TYPE_BOTTOM.length > 0 ? (
        <div className="row">
          <div className="col">保底院校：</div>
          <div className="col">{renderSchoolItem(MS_MATCH_TYPE_BOTTOM)}</div>
        </div>
      ) : null}
      {questions && questions.length > 0 ? (
        <>
          <Divider />
          <h3>
            提问记录
            <span className="tip">若链接不可点，则表明该问题还没有被回答</span>
          </h3>
          <div className="questions">
            {questions.map(q => [
              q.answerCount > 0 ? (
                <a className="link" key={q.id} href={q.link} target="_blank" rel="noopener noreferrer">
                  {q.content}
                </a>
              ) : (
                <span className="no-link" key={q.id}>
                  {q.content}
                </span>
              ),
              <div key={`${q.id}_2`} className="create-time">
                {moment(q.createTime).fromNow()}
              </div>,
            ])}
          </div>
        </>
      ) : null}
      {collections && collections.length > 0 ? (
        <>
          <Divider />
          <h3>
            收藏信息
            <span className="tip">其中不可点的是只在APP上的信息</span>
          </h3>
          <table className="collection">
            <tbody>
              {collections.map(c => (
                <tr key={c.type}>
                  <td>{c.title}：</td>
                  <td>
                    {(c.items || []).map(i =>
                      i.link ? (
                        <a key={i.id} href={i.link} className="link" target="_blank" rel="noopener noreferrer">
                          {i.chineseName || i.displayText || ''}
                        </a>
                      ) : (
                        <span className="no-link" key={i.id}>
                          {i.chineseName || i.displayText || ''}
                        </span>
                      )
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
      {tests && tests.length > 0 ? (
        <>
          <Divider />
          <h3>测评信息</h3>
          <table>
            <thead>
              <tr>
                <th>测评名称</th>
                <th>测评时间</th>
              </tr>
            </thead>
            <tbody>
              {tests.map(t => (
                <tr key={`${t.title}_${t.time}`}>
                  <td>{t.title}</td>
                  <td>{moment(t.time).fromNow()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </div>
  );
}
