/**
 * @typedef {object} AppConfig
 * @property {string} api
 * @property {string} cdnUpload
 * @property {string} xxdSite
 * @property {string} port
 */

/** @type {AppConfig} */
export const appConfig = window.config;
