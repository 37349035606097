import React from 'react';
import {
  UploadOutlined,
  CloseCircleTwoTone,
  CheckCircleTwoTone,
  DeleteOutlined,
  QuestionCircleTwoTone,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { Upload, Button } from 'antd';
import _ from 'lodash';
import './upload_file.less';
import { appConfig } from '../../common/config';

const toCompactArray = value => _.compact(_.castArray(value));

const colors = {
  green6: '#52c41a',
  blue6: '#1890ff',
  red6: '#f5222d',
};

function FileContent({ file }) {
  if (file.status === 'done') {
    return (
      <>
        <CheckCircleTwoTone twoToneColor={colors.green6} />
        <span>{file.name}</span>
      </>
    );
  }
  if (file.status === 'uploading') {
    return (
      <>
        <Loading3QuartersOutlined spin style={{ color: colors.blue6 }} />
        <span>{file.name}</span>
      </>
    );
  }
  if (file.status === 'error') {
    return (
      <>
        <CloseCircleTwoTone twoToneColor={colors.red6} />
        <span>{file.name}</span>
      </>
    );
  }
  if (file.status === 'removed') {
    return (
      <>
        <QuestionCircleTwoTone twoToneColor={colors.red6} />
        <span>{file.name}</span>
      </>
    );
  }
  return (
    <>
      <QuestionCircleTwoTone twoToneColor={colors.red6} />
      <span>{file.name}</span>
    </>
  );
}

export default class FileUpload extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      fileList: [],
    };
    this.setFileList = this.setFileList.bind(this);
  }

  componentDidMount() {
    this.setFileList(null, this.props.value);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.value, this.props.value)) {
      this.setFileList(this.props.value, nextProps.value);
    }
  }

  setFileList(prevValue, nextValue) {
    const { fileList } = this.state;
    const currentFileUrls = new Set(_.compact(_.map(fileList, 'url')));

    // 将value处理成非空值的数组
    prevValue = toCompactArray(prevValue);
    nextValue = toCompactArray(nextValue);

    // 新增的部分，如果当前fileList中没有，需要新增条目
    const addition = _.difference(nextValue, prevValue).filter(url => !currentFileUrls.has(url));
    // 删除的部分，如果当前fileList中有，需要删除
    const deletion = _.difference(prevValue, nextValue).filter(url => currentFileUrls.has(url));

    const fileListAfterDeletion = fileList.filter(file => !deletion.includes(file.url));

    const nextFileList = fileListAfterDeletion.concat(
      addition.map(url => ({
        uid: -1,
        name:
          String((url && url.url) || url || '')
            .split(/[?#]/)
            .shift()
            .split('/')
            .pop()
            .replace(/\b([0-9a-f]{8,})\b/gi, 'xxxx') || 'file.dat',
        status: 'done',
        response: 'Stub Response', // custom error message to show
        url,
      }))
    );
    this.setState({ fileList: nextFileList });
  }

  handleChange = newState => {
    for (const file of newState.fileList) {
      if (!file.url && file.status === 'done') {
        if (file.response.data?.uri) {
          file.url = file.response.data.uri;
        } else {
          // eslint-disable-next-line no-console
          console.log('文件上传失败', file.response);
          file.status = 'error';
        }
      }
    }
    this.setState({ fileList: [...newState.fileList] }, this.afterValueChanged);
  };

  handleDelete = file => {
    this.setState(({ fileList }) => ({ fileList: fileList.filter(f => f !== file) }), this.afterValueChanged);
  };

  afterValueChanged = () => {
    const { onChange, max } = this.props;
    if (onChange instanceof Function) {
      const { fileList } = this.state;
      const hasError = fileList.some(file => file.status === 'error');
      const uploading = fileList.some(file => file.status === 'uploading');
      if (!uploading && !hasError) {
        const values = fileList.filter(f => f.url).map(f => f.url);
        onChange(max === 1 ? values[0] : values);
      }
    }
  };

  render() {
    const { fileList } = this.state;

    const { max, preset, accept, block, className, disabled = false } = this.props;
    const baseClassName = block ? 'upload-file upload-file-block' : 'upload-file';
    return (
      <div className={`${baseClassName} ${className || ''}`}>
        <Upload
          accept={accept}
          action={`${appConfig.api}/upload/ufile`}
          fileList={fileList}
          showUploadList={false}
          data={{ preset }}
          onChange={this.handleChange}
          disabled={disabled}
        >
          {max && fileList.length >= max ? null : (
            <Button className="upload-file-upload">
              <UploadOutlined />
              <span>点击上传文件</span>
            </Button>
          )}
        </Upload>
        {fileList.map((file, idx) => (
          <div key={idx} className={`upload-file-file status-${file.status}`}>
            <FileContent file={file} />
            <DeleteOutlined
              className="upload-file-file-delete"
              style={{ color: colors.red6 }}
              onClick={() => this.handleDelete(file)}
            />
          </div>
        ))}
      </div>
    );
  }
}
