import axios from '../common/axios';

/** 获取学生预约主要数据 */
export const getOverall = opts => axios.get('/thirdparty/consultant/appointment/tome/overall', {}, opts);

/** 获取我的预约列表 */
export const getMineList = ({ page, pageSize = 20, startTime, endTime, status, keywords }, opts) =>
  axios.get(
    '/thirdparty/consultant/appointment/tome/list',
    {
      page,
      pageSize,
      startTime,
      endTime,
      status,
      keywords,
    },
    opts
  );

/** 获取预约详情 */
export const getSubscribe = id => axios.get('/thirdparty/consultant/appointment/tome/item', { id });

/** 更新状态 */
export const updateStatus = ({ id, status, statusNote }) =>
  axios.put('/thirdparty/consultant/appointment/item/status', {
    id,
    status,
    statusNote,
  });

/** 更新备忘录 */
export const updateNote = (appointmentId, content) =>
  axios.put('/thirdparty/consultant/appointment/contact_note', {
    appointmentId,
    content,
  });

/** 上传合同 */
export const uploadContract = ({ appointmentId, file }) =>
  axios.post('/thirdparty/consultant/appointment/contract', {
    appointmentId,
    file,
  });

/** 删除合同 */
export const deleteContract = id => axios.delete(`/thirdparty/consultant/appointment/contract?id=${id}`);
