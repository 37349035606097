import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DeleteOutlined, PictureOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Button, Empty, message, Card, Popconfirm, Modal, Alert } from 'antd';
import dayjs from 'dayjs';
import { getOffers, uploadOffer, editOffer, deleteOffer } from '../../services/offers';
import Preview from '../../component/preview/preview';
import SearchSchool from '../../component/search-school/search-school';
import FormUpload from '../../component/form-upload/form-upload';
import constant from '../../common/constant';
import { optimizeImage } from '../../common/utils';
import './offers.less';

const { Item } = Form;

class Offers extends React.Component {
  state = {
    loading: false,
    offers: [],
    previewId: null,
    isShowModal: false,
    modalType: 'add',
    currentEditOfferId: null,
  };
  async componentWillMount() {
    await this.getOffers();
  }
  // 服务器获取 offers
  async getOffers() {
    this.setState({ offers: await getOffers() });
  }
  // 新增弹窗
  showModal() {
    const {
      form: { resetFields },
    } = this.props;
    resetFields();
    this.setState({
      isShowModal: true,
      modalType: 'add',
      currentEditOfferId: null,
    });
  }
  // 隐藏弹窗
  cancelModal() {
    this.setState({ isShowModal: false });
  }
  // 编辑弹窗
  openEditModal(offer, isDisabled) {
    if (isDisabled) {
      message.warning('审核中的Offer不可编辑');
      return;
    }
    const {
      form: { setFieldsValue },
    } = this.props;
    setFieldsValue({
      school: offer.school,
      offerImages: [offer.imageUrl],
    });
    this.setState({
      isShowModal: true,
      modalType: 'edit',
      currentEditOfferId: offer.id,
    });
  }
  // 删除 offer
  async delete(offer) {
    await deleteOffer(offer.id);
    await this.getOffers();
  }
  // 预览 offer
  previewOfferImage(offer, e) {
    this.setState({ previewId: offer.id });
  }
  // 提交并保存 offer
  handleSubmit(e) {
    e.preventDefault();
    const {
      form: { validateFieldsAndScroll },
    } = this.props;
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          const { currentEditOfferId } = this.state;
          this.setState({ loading: true }, async () => {
            if (currentEditOfferId) {
              await editOffer(values.offerImages[0], values.school.id, currentEditOfferId);
              message.success('已成功编辑Offer');
            } else {
              await uploadOffer(values.offerImages[0], values.school.id);
              message.success('已成功新增Offer');
            }

            this.cancelModal();
            await this.getOffers();
            this.setState({ loading: false });
          });
        } catch (error) {
          this.setState({ loading: false });
          console.error(error); // eslint-disable-line
          message.error('操作失败');
        }
      }
    });
  }
  render() {
    const { loading, offers, previewId, isShowModal, modalType } = this.state;
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <div className="offer-page-containers">
        <section className="offer-box">
          <div className="add-box">
            <Row className="btns-box">
              <Col style={{ textAlign: 'right' }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={this.showModal.bind(this)}>
                  新增 Offer
                </Button>
              </Col>
            </Row>
            <Modal
              title={`${modalType === 'add' ? '新增' : '编辑'} Offer`}
              visible={isShowModal}
              onCancel={this.cancelModal.bind(this)}
              footer={null}
            >
              <Form onSubmit={this.handleSubmit.bind(this)}>
                <Item label="offer对应院校">
                  {getFieldDecorator('school', {
                    rules: [{ required: true, message: '请选择学校!' }],
                  })(<SearchSchool />)}
                </Item>
                <Item label="offer图片">
                  {getFieldDecorator('offerImages', {
                    rules: [{ required: true, message: '请上传Offer图片!' }],
                  })(<FormUpload accept={constant.imgAccept} maxCount={1} />)}
                </Item>
                <Alert showIcon type="info" message="offer图请不要打品牌水印，选校帝会在发出时统一完成水印认证" />
                <div style={{ textAlign: 'right', marginTop: 16 }}>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    保存并提交审核
                  </Button>
                </div>
              </Form>
            </Modal>
          </div>
          <div className="offer-container">
            {offers.map(offer => {
              const canEdit = offer.status === constant.status.REJECTED;
              const cantDelete = offer.status === constant.status.NORMAL;
              return (
                <Card
                  key={`2_${offer.id}`}
                  className="offer-item"
                  actions={[
                    <Button
                      key="1"
                      icon={<PictureOutlined />}
                      type="primary"
                      className="action-btn"
                      disabled={!canEdit}
                      onClick={this.openEditModal.bind(this, offer, !canEdit)}
                    >
                      编辑
                    </Button>,
                    cantDelete ? (
                      <Button key="2" disabled className="action-btn" icon={<DeleteOutlined />} type="danger">
                        删除
                      </Button>
                    ) : (
                      <Popconfirm
                        key="2"
                        title="你确定要删除这个 Offer 吗?"
                        onConfirm={this.delete.bind(this, offer)}
                        okText="删除"
                        cancelText="取消"
                      >
                        <Button className="action-btn" icon={<DeleteOutlined />} type="danger">
                          删除
                        </Button>
                      </Popconfirm>
                    ),
                  ]}
                >
                  <Preview
                    key={`1_${offer.id}`}
                    src={offer.imageUrl}
                    onHide={() => {
                      this.setState({ previewId: null });
                    }}
                    show={offer.id === previewId}
                  />
                  <div
                    className="image-cover"
                    style={{ backgroundImage: `url(${optimizeImage(offer.imageUrl, 200, 200)})` }}
                    onClick={this.previewOfferImage.bind(this, offer)}
                  >
                    {constant.statusText[offer.status] ? (
                      <div
                        className={`cover-mark ${constant.statusString[offer.status]}`}
                        onClick={e => e.stopPropagation()}
                      >
                        {offer.reviewTime ? `${dayjs(offer.reviewTime).format('YYYY-MM-DD')} ` : ''}
                        {constant.statusText[offer.status]}
                        {offer.reviewRejectReason ? `，拒绝原因：${offer.reviewRejectReason}` : ''}
                      </div>
                    ) : null}
                  </div>
                  <div className="school-name">{offer.school.chineseName}</div>
                </Card>
              );
            })}

            {!offers || offers.length === 0 ? (
              <Empty
                description={
                  <span>
                    暂无 Offer，
                    <a onClick={this.showModal.bind(this)}>点击新增</a>{' '}
                  </span>
                }
              />
            ) : (
              <Card className="offer-item plus" onClick={this.showModal.bind(this)}>
                <PlusCircleOutlined />
              </Card>
            )}
          </div>
        </section>
      </div>
    );
  }
}
export default Form.create()(Offers);
