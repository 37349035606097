import React from 'react';
import { Select } from 'antd';
import { searchSchools } from '../../services/search';
import './search-school.less';
const { Option } = Select;

export default class SearchSchool extends React.Component {
  state = { options: [], keyword: '' };
  componentDidMount() {
    this._isMounted = true;
  }
  static getDerivedStateFromProps(props, state) {
    if (!state.keyword) {
      return { ...state, keyword: (props.value || {}).chineseName };
    }
    if (!props.value) {
      return { options: [], keyword: '' };
    }
    return state;
  }
  componentWillUnmount() {
    this._isMounted = false;
    if (this.interval) {
      clearTimeout(this.interval);
    }
  }
  // 输入搜索
  handleSearch(value) {
    const ticket = Date.now();
    this.ticket = ticket;
    const keyword = value.trim() || '';
    if (keyword) {
      clearTimeout(this.interval);
      this.interval = setTimeout(() => {
        if (ticket === this.ticket) {
          searchSchools(keyword).then(schools => {
            if (schools && this._isMounted) {
              this.setState({
                options: schools.data.map(d => (
                  <Option key={d.id} school={d} value={d.chineseName}>
                    {d.chineseName}
                  </Option>
                )),
              });
            }
          });
        }
      }, 500);
    }
  }
  // 选择学校
  handleSelect(value, option) {
    const { onChange = _ => _ } = this.props; // for form field
    onChange(option.props.school);
  }
  render() {
    const { options, keyword } = this.state;
    return (
      <div className="search-school-component">
        <Select
          showSearch
          value={keyword || undefined}
          placeholder="搜索学校"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={this.handleSearch.bind(this)}
          onSelect={this.handleSelect.bind(this)}
          onChange={keyword => this.setState({ keyword })}
          notFoundContent="抱歉，暂无数据，换个关键词试试？"
        >
          {options}
        </Select>
      </div>
    );
  }
}
