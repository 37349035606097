import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import XxdObjectCommand from './xxd-object-command';
import XxdObjectEditing from './xxd-object-editing';
import XxdObjectUI from './xxd-object-ui';
import './theme/style.css';

export default class XxdObject extends Plugin {
  static get requires() {
    return [Widget, XxdObjectEditing, XxdObjectUI];
  }

  init() {
    this.editor.commands.add('insertXxdObject', new XxdObjectCommand(this.editor));
  }
}
