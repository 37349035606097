import React, { useState, useEffect } from 'react';
import { Form, Popconfirm, Select, Button, Space, Spin, message, Row, Col, Input, PageHeader } from 'antd';
import UploadImage from '../../component/upload-image';
import RichText from '../../component/rich-text';
import { createNews, getNewsDetail, getOptions, submitNews, editNews, revokeNews } from '../../services/news';
import SearchSchool from '../../component/search_school';

const { Option } = Select;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 20 },
};
const { Item } = Form;

const Editnews = props => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState([]); // 类型筛选列表
  const [currentStatus, setCurrentStatus] = useState(); // 修改详情获取的status

  useEffect(() => {
    fetchOptions();
    if (props?.match.params?.id) fetchNewsDetail();
  }, []);

  const fetchOptions = async () => {
    const res = await getOptions();
    setTypes(res.types);
  };
  const fetchNewsDetail = async () => {
    try {
      const res = await getNewsDetail(props?.match.params?.id);
      setCurrentStatus(res?.status);
      form.setFieldsValue(res);
    } catch (error) {
      message.error(error.message);
    }
  };
  const fetchCreateNews = async paramsObj => {
    setLoading(true);
    try {
      const res = await createNews(paramsObj);
      message.success('创建成功');
      setCurrentStatus(0);
      props.history.push(`/news/edit-news/${res?.id}`);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchUpdateNews = async (paramsObj, isPublish) => {
    setLoading(true);
    try {
      await editNews(paramsObj);
      if (!isPublish) message.success('修改成功');
      setCurrentStatus(0);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchSubmitNews = async id => {
    setLoading(true);
    try {
      await submitNews(id);
      message.success('提交成功');
      setCurrentStatus(1);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchRevokeNews = async () => {
    try {
      await revokeNews(props?.match.params?.id);
      message.success('撤回成功');
    } catch (error) {
      message.error(error.message);
    }
  };

  const onFinish = obj => {
    if (props?.match.params?.id) {
      return fetchUpdateNews({ ...obj, id: props?.match.params?.id });
    }
    fetchCreateNews({ ...obj });
  };
  const onPublish = () => {
    const obj = form.getFieldsValue();
    fetchUpdateNews({ ...obj, id: props?.match.params?.id }, true).then(() => {
      fetchSubmitNews(props?.match.params?.id);
    });
  };
  return (
    <Spin spinning={loading}>
      <PageHeader title={props?.match.params?.id ? '编辑资讯' : '新建资讯'} />
      <Form {...layout} onFinish={onFinish} form={form}>
        <Row>
          <Col span={15}>
            <Item
              label="标题"
              name="title"
              rules={[
                {
                  required: true,
                  message: '请输入标题',
                },
                {
                  max: 40,
                  message: '请输入标题不超过40个字',
                },
              ]}
            >
              <Input placeholder="请输入标题不超过40个汉字，建议突出重点，具有吸引力" />
            </Item>
            <Item label="学校名称" name="schoolIds">
              <SearchSchool maxLength={3} multiple placeholder="搜索学校，最多填写3个" />
            </Item>
            <Row>
              <Col span={12}>
                <Item
                  label="类型"
                  name="typeId"
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 16 }}
                  rules={[{ required: true, message: '类型必选' }]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.title?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                    placeholder="请选择"
                  >
                    {types.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Form.Item name="coverUrl" rules={[{ required: true, message: '请上传封面!' }]} extra="尺寸要求264*200">
              <UploadImage
                preset="common-image"
                btnText="设置封面"
                max={1}
                className="update-img"
                style={{
                  width: '100%',
                  height: 140,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15} offset={1}>
            <p>资讯正文:（不限字数，优质资讯有助于提升预约率，请重视内容的质量）</p>
            <Item name="content" rules={[{ required: true, message: '请输入资讯正文' }]}>
              <RichText enableLink enableVideo />
            </Item>
          </Col>
        </Row>

        <Row>
          <Col span={15} offset={11} style={{ marginBottom: 30 }}>
            <Space>
              <Button loading={loading} disabled={currentStatus === 1 || currentStatus === 3} htmlType="submit">
                保存草稿
              </Button>
              {currentStatus === 1 || currentStatus === 3 ? (
                <Popconfirm
                  title="确定撤回吗"
                  onConfirm={() => {
                    fetchRevokeNews();
                    setCurrentStatus(0);
                  }}
                >
                  <Button type="primary" danger>
                    撤回
                  </Button>
                </Popconfirm>
              ) : (
                <Button loading={loading} disabled={currentStatus !== 0} type="primary" onClick={() => onPublish()}>
                  提交审核
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default Editnews;
