import axios from '../common/axios';

// 获取案例列表
export const getPlanList = ({ typeId, subtypeIds, keyword, enabled, page, pageSize, majorKeyword, reviewStatus }) =>
  axios.get(
    '/thirdparty/service/plan/list/by/consultant',
    {
      typeId,
      subtypeIds: JSON.stringify(subtypeIds),
      keyword,
      enabled,
      page,
      pageSize,
      majorKeyword,
      reviewStatus,
    },
    { isMultipart: true }
  );

// 获取详情
export const getPlanDetail = ({ id, type }) => axios.get('/thirdparty/service/plan/item/by/consultant', { id, type });

// 预渲染方案内容
export const getPlanPreview = ({ typeId, adminData }) =>
  axios.post('/thirdparty/service/plan/item/preview', { typeId, adminData });

// 获取分类数据
export const getPlanTypeData = () => axios.get(`/thirdparty/service/plan/types`);

// 获取专业数据
export const getMajors = () => axios.get(`/common/major/index`);

// 提交方案数据
export const editPlan = params => axios.put(`/thirdparty/service/plan/item/by/consultant`, params);

// 创建新方案
export const addPlan = params => axios.post(`/thirdparty/service/plan/item/by/consultant`, params);
