import React, { Component } from 'react';
import { Base64 } from 'js-base64';
import { xxdObjectType } from './util';

export default class XxdObjectContent extends Component {
  render() {
    const { type, data } = this.props;
    const typeObject = xxdObjectType.find(item => item.model === type) || {};
    const itemData = JSON.parse(Base64.decode(data));
    return (
      <div className="xxd-object-content">
        {itemData.image && <img src={itemData.image} alt="" className="image" />}
        <div className="type">{typeObject.title}</div>
        <div className="title">{itemData.title}</div>
        {itemData.description && <div className="description">{itemData.description}</div>}
      </div>
    );
  }
}
