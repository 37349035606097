import React from 'react';

import {
  ArrowLeftOutlined,
  IdcardOutlined,
  LogoutOutlined,
  ReadOutlined,
  SolutionOutlined,
  ProfileOutlined,
  TrophyOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Layout, Menu, Popconfirm, Skeleton } from 'antd';
import Loadable from 'react-loadable';
import { Route, Switch, withRouter } from 'react-router-dom';
import event from 'events-manage';
import BrowserTip from '../component/browser-tip/browser-tip';
import ErrorBoundary from '../component/error-boundaries/error';
import { isChrome } from '../common/utils';
import { LoginContext } from '../context/loginContext';
import Login from './login/login';
import './index.less';
import 'antd/dist/antd.css';

const { Header, Content, Sider } = Layout;

// 加载组件 Loading
const Loading = () => (
  <div className="skeleton-box">
    <Skeleton active loading />
    <Skeleton active loading />
    <Skeleton active loading />
  </div>
);

// 异步加载组件 fn: () => import('path/to/component)
const getComponentDynamic = fn =>
  Loadable({
    loader: fn,
    loading: () => <Loading />,
  });

function ContainerIndex({ location, history }) {
  // 登录控制
  const {
    state: { userInfo = {}, isSigned },
    dispatch: loginDispatch,
  } = React.useContext(LoginContext);
  const [selectedMenuKeys, setSelectedMenuKeys] = React.useState(getSelectedMenuKeys());

  // 监听一次登出处理事件
  const logoutCount = event.getListenerCount('logout');
  if (!logoutCount) {
    event.on('logout', () => {
      loginDispatch({ type: 'logout' });
    });
  }

  React.useEffect(() => {
    setSelectedMenuKeys(getSelectedMenuKeys());
  }, [location]);

  // 未登录的情况
  if (!isSigned) {
    return <Login />;
  }

  // 未填写个人资料或个人资料未通过审核
  // const isNotPassProfile = status < 0;
  // if (isNotPassProfile && location.pathname !== '/profile') {
  //   return <Redirect to="/profile" />;
  // }

  // 处理点击菜单事件
  function handleClickMenu({ key, keyPath }) {
    // if (isNotPassProfile && key !== 'profile') {
    //   Modal.warning({
    //     title: '请先完善个人资料，并等待审核通过',
    //     content: '',
    //   });
    //   return;
    // }
    setSelectedMenuKeys(keyPath);
    history.push(`/${key}`);
  }

  // 根据路由获取菜单 key
  function getSelectedMenuKeys() {
    let keys = location.pathname.split('/').filter(Boolean);
    if (keys.length === 0) {
      keys = ['subscribe'];
    }
    return keys;
  }

  // 已登录，进入系统
  return (
    <Layout style={{ height: '100vh' }} className="container-index-layout">
      <Sider>
        {userInfo.avatar ? (
          <div className="profile-box">
            <div className="avatar-box">
              <div className="avatar" style={{ backgroundImage: `url(${userInfo.avatar})` }} />
            </div>
            <div className="username">{userInfo.realName}</div>
          </div>
        ) : null}
        <Menu theme="dark" mode="vertical" onClick={handleClickMenu} selectedKeys={selectedMenuKeys}>
          {/* <Menu.Item key="subscribe-rank">
            <Icon type="ordered-list" />
            <span>预约量排行</span>
          </Menu.Item> */}
          <Menu.Item key="subscribe">
            <SolutionOutlined />
            <span>学生预约</span>
          </Menu.Item>
          <Menu.Item key="photos">
            <UserOutlined />
            <span>个人形象照片</span>
          </Menu.Item>
          <Menu.Item key="offers">
            <TrophyOutlined />
            <span>我的offer</span>
          </Menu.Item>
          <Menu.Item key="case">
            <TrophyOutlined />
            <span>我的案例</span>
          </Menu.Item>
          {/* <Menu.Item key="stories">
            <ReadOutlined />
            <span>我的文章</span>
          </Menu.Item> */}
          <Menu.Item key="plans">
            <ProfileOutlined />
            <span>我的方案</span>
          </Menu.Item>
          <Menu.Item key="news">
            <ProfileOutlined />
            <span>我的资讯(原文章)</span>
          </Menu.Item>
          <Menu.Item key="profile">
            <IdcardOutlined />
            <span>个人资料</span>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header className="header">
          {location.pathname.split('/').filter(Boolean).length > 1 ? (
            <div className="back" onClick={() => history.goBack()}>
              <ArrowLeftOutlined />
              <span>返回</span>
            </div>
          ) : null}
          <div className="user">
            {userInfo.realName || userInfo.phone || ''}
            <Popconfirm
              title="确认退出登录吗？"
              okText="确认"
              cancelText="取消"
              placement="bottomRight"
              onConfirm={() => loginDispatch({ type: 'logout' })}
            >
              <span className="logout-btn">
                <span style={{ marginRight: 5, marginLeft: 16 }}>退出登录</span>
                <LogoutOutlined />
              </span>
            </Popconfirm>
          </div>
        </Header>
        <Content style={{ overflowY: 'auto' }}>
          <ErrorBoundary>
            <Switch>
              <Route path="/" exact component={getComponentDynamic(() => import('./subscribes/subscribes'))} />
              <Route path="/subscribe" exact component={getComponentDynamic(() => import('./subscribes/subscribes'))} />
              <Route
                path="/subscribe-rank"
                exact
                component={getComponentDynamic(() => import('./subscribe-rank/subscribe-rank'))}
              />
              <Route
                path="/subscribe/:id(\d+)"
                exact
                component={getComponentDynamic(() => import('./subscribe/subscribe'))}
              />
              <Route path="/photos" component={getComponentDynamic(() => import('./photos/photos'))} />
              <Route path="/offers" component={getComponentDynamic(() => import('./offers/offers'))} />

              <Route path="/case" exact component={getComponentDynamic(() => import('./case/case'))} />
              <Route path="/case/:id" exact component={getComponentDynamic(() => import('./case/info'))} />

              <Route path="/stories" exact component={getComponentDynamic(() => import('./stories/stories'))} />

              <Route path="/stories/:id(\d+)" exact component={getComponentDynamic(() => import('./story/story'))} />
              <Route
                path="/stories/edit/:id(\d+)?"
                exact
                component={getComponentDynamic(() => import('./story-edit/story-edit'))}
              />

              <Route path="/plans" exact component={getComponentDynamic(() => import('./plans/plans'))} />
              <Route path="/plan/detail" exact component={getComponentDynamic(() => import('./plans/plans-detail'))} />
              <Route
                path="/plan/detail/:id"
                exact
                component={getComponentDynamic(() => import('./plans/plans-detail'))}
              />
              <Route path="/news" exact component={getComponentDynamic(() => import('./news/newsList'))} />

              <Route path="/news/create" exact component={getComponentDynamic(() => import('./news/editNews'))} />
              <Route
                path="/news/edit-news/:id"
                exact
                component={getComponentDynamic(() => import('./news/editNews'))}
              />

              <Route
                path="/profile"
                component={require('./profile/profile').default} // 这里异步加载会引起Form表单的定义和设值的顺序错误，因而不异步加载
              />
              {/* todo: <Route component={NoMatch} /> */}
            </Switch>
          </ErrorBoundary>
          {!isChrome() ? <BrowserTip /> : null}
        </Content>
      </Layout>
    </Layout>
  );
}

export default withRouter(ContainerIndex);
