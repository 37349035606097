import axios from 'axios';
import { message } from 'antd';
import { isPlainObject } from 'lodash';
import event from 'events-manage';
import nprogress from 'nprogress';
import { Storage } from './utils';
import constant from './constant';
import { appConfig } from './config';

// 数据修正器
function dataCorrector(rsp) {
  if (rsp.status < 200 || rsp.status >= 300) {
    const msg = (rsp.data && rsp.data.msg) || String(rsp.data);
    console.error(rsp.status, rsp.data); // eslint-disable-line
    if (rsp.config.method === 'post' || rsp.config.method === 'put') {
      throw new Error(msg);
    } else {
      message.error(msg);
      return null;
    }
  }
  const { data } = rsp;
  if (data.code === 0) {
    return data.data;
  } else {
    // 账号被禁用或未登录或登录失效，做登出操作
    if (data.code === 'SCHOOL_2302' || data.code === 'SCHOOL_8') {
      event.emit('logout');
    }
    const error = Object.assign(new Error(data.msg), data);
    console.error(error); // eslint-disable-line
    if (rsp.config.throw === true || rsp.config.method === 'post' || rsp.config.method === 'put') {
      throw error;
    } else {
      message.error(data.msg);
      return null;
    }
  }
}

// 监听请求开始
function onRequestStart(method) {
  if (method === 'get' && !nprogress.isStarted()) {
    nprogress.start();
  }
}

// 请求结束后
function endRequest() {
  nprogress.done();
  nprogress.remove();
}

// 获取新的配置
function getCommonConfig() {
  return {
    headers: {
      Accept: 'application/json',
      // 'Content-Type': 'application/json',
      'X-xxd-consultant-ticket': Storage.getItem(constant.storageKey.TICKET),
    },
    baseURL: appConfig.api,
    withCredentials: true,
    timeout: 60000, // 60s
    validateStatus: null,
    onDownloadProgress: function(progressEvent) {
      // 顶部帅气进度条
      if (nprogress.isStarted()) {
        const percent = progressEvent.loaded / progressEvent.total;
        if (isNaN(percent)) {
          nprogress.inc();
        } else {
          nprogress.set(Math.min(Math.max(0.5, percent), 0.99));
        }
      }
    },
  };
}

function wrapperData(data) {
  const isMultipart = Object.values(data || {}).some(d => d instanceof File);
  if (!isMultipart) {
    return data;
  }
  const formData = new FormData();
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const element = data[key];
      if (Array.isArray(element) || isPlainObject(element)) {
        try {
          formData.append(key, JSON.stringify(element));
        } catch (error) {
          console.log(data); // eslint-disable-line
          message.error('数据错误，请联系管理员');
          throw error;
        }
      } else {
        formData.append(key, element);
      }
    }
  }
  return formData;
}

export default {
  get: (url, params, opts = {}) => {
    onRequestStart('get');
    return axios
      .get(url, { params, ...getCommonConfig(opts), ...opts })
      .then(rsp => {
        Object.assign(rsp.config, opts);
        return dataCorrector(rsp);
      })
      .finally(endRequest);
  },
  post: (url, data, opts = {}) => {
    onRequestStart('post');
    return axios
      .post(url, wrapperData(data), { ...getCommonConfig(opts), ...opts })
      .then(dataCorrector)
      .finally(endRequest);
  },
  put: (url, data, opts = {}) => {
    onRequestStart('put');
    return axios
      .put(url, wrapperData(data), { ...getCommonConfig(opts), ...opts })
      .then(dataCorrector)
      .finally(endRequest);
  },
  delete: (url, opts = {}) => {
    onRequestStart('delete');
    return axios
      .delete(url, { ...getCommonConfig(opts), ...opts })
      .then(dataCorrector)
      .finally(endRequest);
  },
};
