import React from 'react';
import { Form, Row, Input, Col, Card, Button, Alert, Space } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import UploadImage from '../../../component/upload-image';
import UploadFile from '../../../component/upload-file';

const RULES_STRING_REQUIRED = [{ type: 'string', required: true, whitespace: true }];
const STYLE_CARD_BODY = { position: 'relative' };
const STYLE_CARD_DEL = { position: 'absolute', top: 0, right: 0, zIndex: 999 };

// 判断文本字符长度
function getStringLength(text) {
  const sum = [...text].reduce((pre, next) => pre + (next.charCodeAt(0) < 0xff ? 1 : 2), 0);
  return sum;
}
async function validateStringLength(rule, value) {
  if (!value) {
    return;
  }
  const len = getStringLength(value);
  if (rule.max > 0 && len > rule.max) {
    throw new Error(`字数超过限制 ${len} / ${rule.max}`);
  }
}

const RULE_STRING_LIMIT = max => ({ type: 'string', max, validator: validateStringLength });

function TextArea(props) {
  return <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} {...props} />;
}

const FormItem = Form.Item;

function FormList({ name, label, required = false, min = 0, max, children }) {
  return (
    <FormItem label={label} required={required} validateStatus="" help="">
      <Form.List name={name}>
        {(fields, ops) => {
          return (
            <Space style={{ width: '100%' }} direction="vertical">
              {fields.map(field => (
                <Card key={field.key} bodyStyle={STYLE_CARD_BODY}>
                  {!(min > 0) || fields.length > min ? (
                    <Button type="link" danger style={STYLE_CARD_DEL} onClick={() => ops.remove(field.name)}>
                      <DeleteOutlined />
                    </Button>
                  ) : null}
                  {children(field)}
                </Card>
              ))}
              {min > 0 && fields.length < min ? (
                <Alert type="error" showIcon message={`至少需要${min}个${label}`} />
              ) : null}
              {!(max > 0) || fields.length < max ? (
                <Button block type="dashed" onClick={() => ops.add({})}>
                  <PlusOutlined />
                  {`添加${label}`}
                </Button>
              ) : null}
            </Space>
          );
        }}
      </Form.List>
      <FormItem noStyle name={name} rules={[{ type: 'array', required, min, max }]} />
    </FormItem>
  );
}

export default function ResearchForm() {
  return (
    <Row gutter={16}>
      <Col span={11}>
        <FormItem label="导师信息" required>
          <Card>
            <FormItem name={['adminData', 'professor', 'image']} label="导师照片" rules={RULES_STRING_REQUIRED}>
              <UploadImage preset="common-image" max={1} />
            </FormItem>
            <FormItem name={['adminData', 'professor', 'name']} label="导师姓名" rules={RULES_STRING_REQUIRED}>
              <Input />
            </FormItem>
            <FormItem name={['adminData', 'professor', 'title']} label="导师职称" rules={RULES_STRING_REQUIRED}>
              <Input />
            </FormItem>
            <FormItem name={['adminData', 'professor', 'major']} label="导师专业方向">
              <Input />
            </FormItem>
            <FormItem name={['adminData', 'professor', 'description']} label="导师介绍" rules={RULES_STRING_REQUIRED}>
              <TextArea />
            </FormItem>
            <FormItem name={['adminData', 'professor', 'resume']} label="导师简历">
              <UploadFile block accept="application/pdf" preset="common-file" max={1} />
            </FormItem>
          </Card>
        </FormItem>
        <FormList label="项目收获" name={['adminData', 'outputs']} required min={1} max={10}>
          {field => (
            <>
              <FormItem
                name={[field.name, 'title']}
                label="收获标题"
                rules={RULES_STRING_REQUIRED.concat(RULE_STRING_LIMIT(34))}
              >
                <Input />
              </FormItem>
              <FormItem name={[field.name, 'description']} label="收获说明">
                <TextArea />
              </FormItem>
              <FormItem name={[field.name, 'image']} label="收获插图">
                <UploadImage preset="common-image" max={1} />
              </FormItem>
            </>
          )}
        </FormList>
        <FormItem label="项目课题" required>
          <Card>
            <FormItem label="课题背景" name={['adminData', 'background']} rules={RULES_STRING_REQUIRED}>
              <TextArea />
            </FormItem>
            <FormItem label="课题内容" name={['adminData', 'courseContent', 'text']}>
              <TextArea />
            </FormItem>
            <FormItem label="课程大纲" name={['adminData', 'courseContent', 'outline']}>
              <UploadFile block accept="application/pdf" preset="common-file" max={1} />
            </FormItem>
            <FormItem label="学习计划" name={['adminData', 'learningPlan', 'text']}>
              <TextArea />
            </FormItem>
            <FormItem label="学习计划插图" name={['adminData', 'learningPlan', 'image']}>
              <UploadImage preset="common-image" max={1} />
            </FormItem>
          </Card>
        </FormItem>
        <FormList label="授课模式" name={['adminData', 'teachingModes']} required min={1} max={10}>
          {field => (
            <>
              <FormItem
                name={[field.name, 'title']}
                label="模式标题"
                rules={RULES_STRING_REQUIRED.concat(RULE_STRING_LIMIT(34))}
              >
                <Input />
              </FormItem>
              <FormItem name={[field.name, 'description']} label="模式说明">
                <TextArea />
              </FormItem>
              <FormItem name={[field.name, 'image']} label="模式插图">
                <UploadImage preset="common-image" max={1} />
              </FormItem>
            </>
          )}
        </FormList>
      </Col>
      <Col span={11} offset={2}>
        <FormItem label="适宜阶段" name={['adminData', 'info', 'adeptDegree']}>
          <Input />
        </FormItem>
        <FormItem label="适宜专业" name={['adminData', 'info', 'adeptMajor']}>
          <Input />
        </FormItem>
        <FormItem label="参加门槛" name={['adminData', 'info', 'test']}>
          <Input placeholder="参加项目有没有笔试或者面试？" />
        </FormItem>
        <FormItem label="所需基础" name={['adminData', 'info', 'requirements']}>
          <Input placeholder="对参加者的学术水平或者背景有没有什么要求" />
        </FormItem>
        <FormItem label="授课时间" name={['adminData', 'info', 'time']}>
          <Input />
        </FormItem>
        <FormItem label="项目周期" name={['adminData', 'info', 'period']}>
          <Input />
        </FormItem>
        <FormItem label="授课地点" name={['adminData', 'info', 'place']}>
          <Input />
        </FormItem>
        <FormItem label="更多信息" name={['adminData', 'info', 'more']}>
          <TextArea />
        </FormItem>
        <FormList label="更多文件" name={['adminData', 'files']} min={0} max={10}>
          {field => (
            <Row gutter={16}>
              <Col span={12}>
                <FormItem
                  name={[field.name, 'title']}
                  label="名称"
                  rules={RULES_STRING_REQUIRED.concat(RULE_STRING_LIMIT(34))}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem name={[field.name, 'file']} label="文件" rules={RULES_STRING_REQUIRED}>
                  <UploadFile block accept="application/pdf" preset="common-file" max={1} />
                </FormItem>
              </Col>
            </Row>
          )}
        </FormList>
        <FormList label="服务流程" name={['adminData', 'steps']} required min={1} max={10}>
          {field => (
            <>
              <FormItem
                name={[field.name, 'title']}
                label="流程标题"
                rules={RULES_STRING_REQUIRED.concat(RULE_STRING_LIMIT(34))}
              >
                <Input />
              </FormItem>
              <FormItem
                name={[field.name, 'description']}
                label="流程描述"
                rules={RULES_STRING_REQUIRED.concat(RULE_STRING_LIMIT(400))}
              >
                <TextArea />
              </FormItem>
            </>
          )}
        </FormList>
        <FormList label="费用说明" name={['adminData', 'fees']} required min={1} max={10}>
          {field => (
            <>
              <FormItem
                name={[field.name, 'title']}
                label="费用说明标题"
                rules={RULES_STRING_REQUIRED.concat(RULE_STRING_LIMIT(34))}
              >
                <Input />
              </FormItem>
              <FormItem
                name={[field.name, 'description']}
                label="详细说明"
                rules={RULES_STRING_REQUIRED.concat(RULE_STRING_LIMIT(400))}
              >
                <TextArea />
              </FormItem>
            </>
          )}
        </FormList>
      </Col>
    </Row>
  );
}
