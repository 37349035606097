// 引用
import React, { Component } from 'react';
import { Select, Input, DatePicker, Checkbox, Cascader } from 'antd';
import './filter.less';

const { Option } = Select;

// 容器
export default class Container extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.initState && prevProps.initState !== this.props.initState) {
      this.init();
    }
  }

  init = () => {
    const state = {};
    this.props.columns.forEach(item => {
      state[item.key] = '';
    });

    this.setState(state);
  };

  // 内容变化
  onChange(key, associatedKeys) {
    const self = this;

    return value => {
      if (associatedKeys?.length > 0) {
        associatedKeys.forEach(associatedKey => {
          this.setState({
            [associatedKey]: '',
          });
        });
      }
      this.setState({ [key]: value });
      self.props.onChange(key, value);
    };
  }

  // 多选框改变事件
  onCheckboxChange(key) {
    const self = this;

    return value => {
      this.setState({ [key]: value.target.checked });
      self.props.onChange(key, value.target.checked);
    };
  }

  // 级联选择
  onChangeCascader(key) {
    const self = this;
    return value => {
      value = Array.isArray(value) ? value[value.length - 1] : value; // pop() 会修改原数组，不建议使用
      this.setState({ [key]: value });
      self.props.onChange(key, value);
    };
  }

  // 点击回车
  onPressEnter(key) {
    const self = this;
    return value => {
      self.props.onChange(key, this.state[key]);
    };
  }

  // 渲染
  render() {
    const self = this;
    const { data } = self.props;
    const components = [];

    this.props.columns.forEach(column => {
      // 选择框
      if (column.type === 'select') {
        components.push(
          <Select
            style={{ width: this.props.width || column.width || 200 }}
            value={this.state[column.key] || column.value || column.defaultValue || ''}
            onChange={self.onChange(column.key, column.associatedKeys)}
            {...column.options}
          >
            <Option key="" value="">
              {column.title}
            </Option>

            {(data[column.dataIndex || column.key] || []).map((item, index) => {
              return (
                <Option key={index} value={`${item[column.dataKey || 'id']}`}>
                  {item[column.dataTitle || 'title']}
                </Option>
              );
            })}
          </Select>
        );
      }

      // 单行输入框
      if (column.type === 'input') {
        components.push(
          <Input
            ref={column.key}
            style={{ width: column.width || 200 }}
            placeholder={column.title}
            value={this.state[column.key] === undefined ? column.value : this.state[column.key]}
            onChange={e => this.setState({ [column.key]: e.target.value })}
            onPressEnter={self.onPressEnter(column.key)}
            {...column.options}
          />
        );
      }

      // 时间选择
      if (column.type === 'picker') {
        components.push(
          <DatePicker
            style={{ width: column.width || 150, height: column.height || 32 }}
            placeholder={column.title}
            defaultValue={column.defaultValue}
            value={this.state[column.key] === undefined ? column.value : this.state[column.key]}
            onChange={self.onChange(column.key)}
          />
        );
      }

      // 多选框
      if (column.type === 'Checkbox') {
        components.push(
          <Checkbox onChange={self.onCheckboxChange(column.key)} defaultValue={column.defaultValue} {...column.options}>
            {column.title}
          </Checkbox>
        );
      }

      // 级联选择
      if (column.type === 'cascader') {
        components.push(
          <Cascader
            style={{ width: column.width || 200 }}
            onChange={self.onChangeCascader(column.key)}
            options={data[column.dataIndex]}
            placeholder={column.title}
            {...column.options}
          />
        );
      }
    });

    return (
      <div className="component-filter" style={{ marginTop: this.props.marginTop || undefined }}>
        {components.map((item, index) => {
          return (
            <div key={index} className="item" style={this.props.width ? { width: this.props.width } : {}}>
              {item}
            </div>
          );
        })}
        {this.props.children}
      </div>
    );
  }
}
